// Adding all the comonly used string manipulation functions here
import { ExcludeParamKeys } from '../constants';

export const hasValue = (str: string) => (
  str !== null && str !== undefined && str.length > 0
);

export const buildSearchParams = (params: object): string => {
  let searchParams = '';

  Object.entries(params).forEach(([key, value]) => {
    if (!value || ExcludeParamKeys.includes(key)) {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach(el => {
        searchParams += `${key}=${encodeURIComponent(String(el))}&`;
      });
      return;
    }

    if (key) {
      searchParams += `${key}=${encodeURIComponent(String(value))}&`;
    }
  });

  return searchParams.replace(/.$/g, '');
};
