import { DualAxes } from '@ant-design/plots';
import SpinnerTabs from '../SpinnerTabs';

const LineGraph = ({ data }) => {
  const config = {
    data: [data[0], data[1]],
    xField: 'Date',
    yField: ['Rank', 'Rank'],
    height: 600,
    padding: [30, 30, 80, 30],
    xAxis: {
      label: {
        autoRotate: true,
        autoHide: false,
        autoEllipsis: false
      },
      tickCount: 8
    },
    slider: {},
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'Category',
        lineStyle: {
          lineWidth: 3,
          lineDash: [6, 6]
        },
        smooth: true,
        connectNulls: true
      },
      {
        geometry: 'line',
        seriesField: 'Category',
        lineStyle: {
          lineWidth: 1
        },
        smooth: true,
        connectNulls: true
      }
    ],
    Legend: {
      selected: {
        bill: false
      },
      position: 'top'
    }
  };

  return <DualAxes {...config} />;
};

const RankingsGraph = ({ data, isFetching, isLoading }) =>
  <div>
    {!isFetching ? (
      <div id='rank-graph'>
        <LineGraph data={data} />
      </div>
    ) : (
      <SpinnerTabs isLoading={isLoading || isFetching} />
    )}
  </div>;

export default RankingsGraph;