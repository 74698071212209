import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { UploadOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { useField } from 'formik';
import { useState } from 'react';
import { Spinner } from './Spinner';
import { notification } from 'antd';

const WrapperImage = styled.span`
  margin-right: 8px;
`;

const WrapperDropzone = styled.span`
  margin-right: 8px;
  padding: 13px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:  1.5px dashed #d9d9d9;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 0 2px #00b27b10 !important;
    border-color: #00b27b !important;
    cursor: pointer;
  }
`;


const WrapperFilesNameItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 12px;
`;

const FilesNameItem = styled.div`
  color: grey;
  width: 300px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DeleteBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const Warning = styled.div`
  font-size: 13px;
  color: #9e9e9e;
  margin-bottom: 8px;
`;

const FilesName = ({ files, handleDelete }) => (
  Boolean(files?.length) && files?.map((file, idx) => <WrapperFilesNameItem key={file.name + idx}>
    <DeleteBtn onClick={() => handleDelete(file.path)}>
      <CloseOutlined />
    </DeleteBtn>
    <FilesNameItem>{file.name}</FilesNameItem>
  </WrapperFilesNameItem>)
);

const UploadFile = ({ name = '' }) => {
  const [file, meta, tools] = useField({ name });
  const [loading, setLoading] = useState(false);

  const handleDrop = (acceptedFiles) => {
    const total = acceptedFiles.length;
    total && setLoading(true);
    let loaded = 0;

    acceptedFiles.forEach((d) => {
      const reader = new FileReader();

      reader.onload = () => {
        loaded += 1;

        if (loaded === total) {
          setLoading(false);
          tools.setValue([...file.value || [], ...acceptedFiles]);
        }
      };

      reader.onprogress = (a) => console.log(a);
      reader.onerror = () => {
        setLoading(false);
      };
      reader.onabort = () => {
        setLoading(false);
      };

      reader.readAsArrayBuffer(d);
    });
  };

  const handleDelete = (path) => {
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Delete User' });
    const filteredFiles = file.value?.filter(f => f.path !== path);
    tools.setValue(filteredFiles);
  };

  const handleErrors = (errors) => {
    errors.forEach((d) => notification?.error({
      description: d?.errors?.[0]?.message?.includes('is large')
        ? 'File is larger than 5 MB' : d?.errors?.[0]?.message,
      message: `Failed (${d?.file?.name})`
    }));
  };

  return (<>
    <Dropzone
      onDrop={handleDrop}
      onError={console.log}
      onDropRejected={handleErrors}
      accept={{ 'image/*': [], 'application/*': ['pdf'] }}
      maxSize={5000000}
    >
      {({ getRootProps, getInputProps }) => (
        <WrapperDropzone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps({ disabled: loading })} />
          <p>
            {loading ? <Spinner
              size='small'
              spinning={loading}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 34
                  }}
                  spin
                />
              }/> : <><WrapperImage>
              <UploadOutlined />
            </WrapperImage>  {'Drag & drop images, or click to select files'}</>}
          </p>
        </WrapperDropzone>
      )}
    </Dropzone>
    <Warning>{'*Max file size 5 MB'} </Warning>
    {meta.error && <p>{meta.error}</p>}

    <FilesName files={file.value} handleDelete={handleDelete} />
  </>
  );
};

export default UploadFile;