import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';
import { useActions } from '../../../../context/Actions.context';

export const useBrendaSearches = (action: string) => {
  const {
    book: { brendaSearches }
  } = useServices();

  const { source: sourcePath } = useActions();

  return useMutation(['brenda-searches'],
    (params: any) => brendaSearches(action, { ...params, source: sourcePath['source'] }));
};