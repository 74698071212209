import { Tabs as TabsComponent } from 'antd';
import React, { ReactElement } from 'react';

const { TabPane } = TabsComponent;

type TTab = {
  label: string;
   key: string;
    disabled?: boolean;
     content?: ReactElement;
     index?: number;
};

interface IProps {
  tabs: TTab[];
  onChange: (key: string) => void;
  activeKey: string;
  defaultActiveKey?: string;

}
const Tabs = ({ tabs = [], ...props }: IProps) => (
  <TabsComponent {...props}>
    {tabs.map(tab => (
      <TabPane tab={tab?.label} key={tab?.key} disabled={tab?.disabled}>
        {tab?.content}
      </TabPane>
    ))}
  </TabsComponent>
);

export default Tabs;
