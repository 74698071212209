import { Redirect, Route, Switch } from 'react-router';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import { Routes, TAB_NAME } from '../../core/constants';
import { DailyRankEbooks, DailyRankAudioBooks, DailyRankCatalogue } from '../Common/tabs';
import { useUserPermit } from '../../context/User.context';
import '../Common/styles.css';
import SavedSearchSection from '../SavedSearches/SavedSearch.section';
import { defaultFilter, useFilter } from '../../context/Filter.context';
import { defaultSort, useSort } from '../../context/Sort.context';

const tabs = [
  {
    key: Routes.dailyRankEbooks,
    label: TAB_NAME.dailyRankEbooks
  },
  {
    key: Routes.dailyRankAudiobooks,
    label: TAB_NAME.dailyRankAudioBooks
  },
  {
    key: Routes.dailyRankCatalogue,
    label: TAB_NAME.dailyRankCatalogue
  },
  {
    key: Routes.savedSearchesDailyRank,
    label: TAB_NAME.savedSearches
  }
];

const DailyrankSection: React.FC = ():JSX.Element => {
  const { view_tables } = useUserPermit();
  const { setFilter } = useFilter();
  const { setSort } = useSort();
  const history = useHistory();

  return view_tables ? (
    <>
      <Tabs
        defaultActiveKey={'/ebooks'}
        onChange={key => {
          history.push(key);

          if (!window.location.search) {
            setSort(defaultSort);
            setFilter(defaultFilter);
          }
        }}
        tabs={tabs}
        activeKey={window.location?.pathname}
      />

      <Switch>
        <Route path={Routes.dailyRankEbooks} render={() => <DailyRankEbooks />} />
        <Route path={Routes.dailyRankAudiobooks} render={() => <DailyRankAudioBooks />} />
        <Route path={Routes.dailyRankCatalogue} render={() => <DailyRankCatalogue />} />
        <Route path={Routes.savedSearchesDailyRank} render={() => <SavedSearchSection />} />
        <Redirect to='/daily-rank/ebooks' />
      </Switch>
    </>
  ) : (
    <></>
  );
};

export default DailyrankSection;