import React from 'react';
import { Pagination as PaginationComponent } from 'antd';
import styled from 'styled-components';

const CustomPagination = styled(PaginationComponent)`
  .ant-pagination-item-active a,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #00b27b;
  }
  .ant-pagination-item-active,
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #00b27b;
  }
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    box-shadow: none;
    border-radius: 3px;
  }
  display: flex;
  justify-content: flex-end;
  flex-shrink: 1;
  margin: 30px 0 10px;
`;

const Pagination = ({ className = '', ...props }) => (
  <CustomPagination
    {...props}
    className={className}
    showSizeChanger={false}
    size='small'
    showQuickJumper
  />
);

export default Pagination;
