import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';

type TBody = {
  amazonasin: string;
  predictions: boolean;
};

export const useManuallyAddBook = options => {
  const {
    book: { manuallyAddBook }
  } = useServices();

  return useMutation('manually-add-title', (book: TBody) =>
    manuallyAddBook(book),
  options
  );
};
