import { Modal, Spin, Input } from 'antd';
import { useUpdateTitle } from '../../../core/modules/book/hooks/useUpdateTitle.query';
import { SEND_TO_CRM } from '../../../core/constants';
import showNotification from '../../../section/Common/utils/showNotification';
import { useSendToCRMSystem } from '../../../core/modules/book/hooks/useSendToCRMSystem.query';
import { useQueryClient } from 'react-query';
import { Field, Formik, Form } from 'formik';
import FormItem from '../../../components/FormItem';
import { Option, Select } from '../../../components/Select';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { useUserCRM } from '../../../core/modules/user/hooks';
import { useActions } from '../../../context/Actions.context';
import { required } from '../../../utils/validation';
import { useFilter } from '../../../context/Filter.context';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 12px;
`;

const { TextArea } = Input;

const tier = [1, 2, 3, 4];
const priority = ['High', 'Medium', 'Low'];

const ToCRMTitlesForm = ({ values, errors, resetForm, book, id, queryClient,
  isActionLoading, isLoading, isLoadingCRM, isValid, setActionLoading,
  handleCloseModal, sentToCRMSystemAsync, mutateAsync, users }) => {
  const handleSendToCRM = async ({ data }) => {
    setActionLoading(true);
    window.gtag('event', 'click', {
      event_category: 'Interaction',
      event_label: 'Send To CRM'
    });
    try {
      const promiseCRMSystem = sentToCRMSystemAsync({
        ...data,
        identifier: book.ASIN ? book.ASIN : book['Fiction Id']
      });
      const res = await promiseCRMSystem;

      if (res?.status === 200) {
        const promise = mutateAsync(
          book.ASIN
            ? { ...data, amazonasin: book.ASIN }
            : { ...data, fiction_id: book['Fiction Id'] }
        );

        showNotification(
          promise,
          'The Title was successfully sent to CRM.',
          'The Title has not been sent.',
          book.Title
        );
        promise.then(() => queryClient.refetchQueries(`get-row-count${id}`));
        promise.then(() => queryClient.refetchQueries(`get-${id}`));
      }
    } catch (error) {
      // Do Nothing
    } finally {
      setActionLoading(false);
      handleCloseModal();
    }
  };

  return (
    <Form>
      <FormItem
        label='Select Assignee: '
        required
        error={errors?.assignee}
      >
        <Field name={'assignee'} validate={required}>
          {({ field: { value, ...rest }, form: { setFieldValue } }) =>

            (
              <Select
                placeholder='Select...'
                value={value === '' || value === null ? undefined : value}
                {...rest}
                onChange={v => {
                  setFieldValue('assignee', v);
                }}
              >
                {!users && !users?.length && (
                  <Option value='Loading' disabled={true}>
                  Loading
                  </Option>
                )}
                {users ? users.map((user) => user.hubspot_assignee ? (
                  <Option key={user.email || `${user.last_name} ${user.first_name}`}
                    value={user.email || `${user.last_name} ${user.first_name}`}>
                    {`${user.first_name} ${user.last_name}`}
                  </Option>
                ) : <></>)
                  : null }
              </Select>
            )
          }
        </Field>
      </FormItem>

      <FormItem label='Assign Tier:' required error={errors?.tier}>
        <Field name={'tier'} validate={required}>
          {({ field: { value, ...rest }, form: { setFieldValue } }) => (
            <Select
              placeholder='Select...'
              value={value === '' || value === null ? undefined : value}
              {...rest}
              onChange={v => {
                setFieldValue('tier', v);
              }}
            >
              {tier.map(el => (
                <Option key={el} value={el}>
                  {el}
                </Option>
              ))}
            </Select>
          )}
        </Field>
      </FormItem>

      <FormItem label='Priority:' required error={errors?.priority}>
        <Field name={'priority'} validate={required}>
          {({ field: { value, ...rest }, form: { setFieldValue } }) => (
            <Select
              placeholder='Select...'
              value={value === '' || value === null ? undefined : value}
              {...rest}
              onChange={v => {
                setFieldValue('priority', v);
              }}
            >
              {priority.map(el => (
                <Option key={el} value={el}>
                  {el}
                </Option>
              ))}
            </Select>
          )}
        </Field>
      </FormItem>

      <FormItem label='Notes: ' required={false} error={undefined}>
        <Field name={'Notes'}>
          {({ field: { value, onChange, ...rest } }: any) => (
            <TextArea
              autoSize={{ minRows: 2, maxRows: 4 }}
              value={value}
              placeholder='Notes'
              onChange={event => {
                onChange(event);
              }}
              {...rest}
            />
          )}
        </Field>
      </FormItem>

      {(isLoading || isLoadingCRM) && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}
      <Footer>
        <Button
          onClick={() => {
            window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Reset Form' });
            resetForm({ isValid: false });
            handleCloseModal();
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() =>
            handleSendToCRM({ data: values })}
          disabled={!isValid || Boolean(Object.values(errors)?.length) || isActionLoading}
          type='primary'
        >
          Send to CRM
        </Button>
      </Footer>
    </Form>
  );
};

const ToCRMTitles = ({ book, modal, toggleModal, id }) => {
  const queryClient = useQueryClient();
  const { isActionLoading, setActionLoading } = useActions();
  const { mutateAsync, isLoading } = useUpdateTitle(SEND_TO_CRM);
  const { mutateAsync: sentToCRMSystemAsync, isLoading: isLoadingCRM } = useSendToCRMSystem();
  const { setFilter } = useFilter();
  const { data: users } = useUserCRM();

  const handleCloseModal = () => {
    setFilter((prevFilter) => {
      const updatedFilter = { ...prevFilter };
      updatedFilter[id].createDeal = false;
      return updatedFilter;
    });
    toggleModal();
  };

  return (
    <Modal
      okText='Send to CRM'
      title='Send title to CRM'
      style={{ zIndex: 1001 }}
      visible={modal}
      footer={null}
      onCancel={() => {
        handleCloseModal();
      }}
    >
      <Formik
        initialValues={{
          priority: book.priority,
          assignee: book.assignee || '',
          tier: book.tier
        }}
        isInitialValid={false}
        enableReinitialize
        onSubmit={(e: any) => e.preventDefault()}
      >
        {({ values, errors, resetForm, isValid }) => (
          <ToCRMTitlesForm
            values={values}
            errors={errors}
            resetForm={resetForm}
            isValid={isValid}
            book={book}
            id={id}
            queryClient={queryClient}
            isActionLoading={isActionLoading}
            isLoading={isLoading}
            isLoadingCRM={isLoadingCRM}
            setActionLoading={setActionLoading}
            handleCloseModal={handleCloseModal}
            sentToCRMSystemAsync={sentToCRMSystemAsync}
            mutateAsync={mutateAsync}
            users={users}
          />
        )}
      </Formik>
    </Modal>
  );
};

export default ToCRMTitles;