import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';
import { IGetBookListResponseDto } from '../book.dto';

export const useCategoryRankings = (asin: string, days?: number | undefined) => {
  const {
    book: { getCategoryRankings }
  } = useServices();

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
    status
  } = useQuery<IGetBookListResponseDto, Error>(
    'get/category-rankings',
    () => getCategoryRankings(asin, days), { enabled: false, cacheTime: 0 }
  );

  return {
    data: response?.data,
    isLoading,
    isFetching,
    status,
    refetch
  };
};