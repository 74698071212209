import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

const useAllUser = (params = {}) => {
  const {
    user: { getAllUsers }
  } = useServices();

  const response = useQuery(
    ['get-all-users', params],
    () => getAllUsers(params),
    {
      refetchInterval: false
    }
  );

  return { data: response?.data?.data || [], isLoading: response?.isLoading };
};

export default useAllUser;
