import React, { useState } from 'react';
import { useServices } from '../../core/services/useServices';
import { LoadingOutlined } from '@ant-design/icons';
import Endpoints, { ALL_EXCLUDED, ALL_EXCLUDED_RR,
  PODIUM_TITLES, RETURN, SOURCE } from '../../core/constants/endpoints';
import { notification } from 'antd';
import { TAB_NAME } from '../../core/constants';
import { useQueryClient } from 'react-query';
import { Spinner } from '../../components/Spinner';
import styled from 'styled-components';
import { SpinnerWrapper } from '../../components/styles';
import Modal from '../../components/Modal';
import { useActions } from '../../context/Actions.context';

const WarningText = styled.p`
  font-size: 16px;
  margin-right: 12px;
`;

interface IProps {
  book_ids: [];
  onClose: Function;
  onReset: Function;
  open: boolean;
  id: string;
  name: string;
}

const SendAll: React.FC<IProps> = ({ open, id, book_ids, name, onClose, onReset }) => {
  const { book } = useServices();
  const { updateTitle, returnTitle } = book;
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const data: { [key: string]: any } = {};
  const { source: sourcePath, setActionLoading } = useActions();
  const sourceTab = window.location.pathname.split('/')[2];
  const isActionPodiumTitles = name === PODIUM_TITLES ? id !== ALL_EXCLUDED && id !== ALL_EXCLUDED_RR : false;

  if (sourcePath['source'] === SOURCE.bookstat) {
    data.amazonasin = book_ids;
  } else if (sourcePath['source'] === SOURCE['royal-road']) {
    data.fiction_id = book_ids;
  }

  const onSend = async () => {
    setActionLoading(true);
    setLoading(true);

    try {
      const response = await updateTitle({
        source: sourcePath['source'],
        endpoint: Endpoints[name],
        sourceTab,
        action: name
      }, data);

      if (isActionPodiumTitles) {
        await updateTitle({
          source: sourcePath['source'],
          endpoint: Endpoints[ALL_EXCLUDED],
          sourceTab,
          action: ALL_EXCLUDED
        }, data);
      }

      setLoading(false);
      onClose();
      onReset();

      if (response?.status === 200) {
        notification?.success({
          message: `Successfully sent title(s) to the ${TAB_NAME[name]} tab.`,
          description: ''
        });

        if (isActionPodiumTitles) {
          notification?.success({
            message: `Successfully sent title(s) to the ${TAB_NAME[ALL_EXCLUDED]} tab.`,
            description: ''
          });
        }
        queryClient.refetchQueries(`get-row-count${id}`);
        queryClient.refetchQueries(`get-${id}`);
        return;
      }

      notification?.error({
        message: 'The title(s) have not been sent.',
        description: ''
      });
    } catch (e) {
      setLoading(false);
      onClose();
      onReset();
      notification?.error({
        message: 'Failed to send title(s).',
        description: ''
      });
    } finally {
      setActionLoading(false);
    }
  };

  const onReturn = async () => {
    setActionLoading(true);
    setLoading(true);

    try {
      const response = await returnTitle(id, data, sourcePath['source']);

      setLoading(false);
      onClose();
      onReset();

      if (response?.status === 200) {
        notification?.success({
          message: 'Successfully returned title(s).',
          description: ''
        });
        queryClient.refetchQueries(`get-row-count${id}`);
        queryClient.refetchQueries(`get-${id}`);
        return;
      }

      notification?.error({
        message: 'The title(s) have not been returned.',
        description: ''
      });
    } catch (e) {
      setLoading(false);
      onClose();
      onReset();
      notification?.error({
        message: 'Failed to return title(s).',
        description: ''
      });
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <Modal
      okText='Yes'
      cancelText='No'
      visible={open}
      onOk={name === RETURN ? onReturn : onSend}
      onCancel={() => onClose()}
      confirmLoading={loading}
    >
      {name === RETURN ?
        <WarningText>
          You are about to return {book_ids?.length} title(s).
          Do you wish to continue?
        </WarningText> :
        <WarningText>
          You are about to send {book_ids?.length} title(s) to {TAB_NAME[name]}.
          Do you wish to continue?
        </WarningText>
      }

      {loading && (
        <SpinnerWrapper>
          <Spinner
            size='large'
            spinning={loading}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 34
                }}
                spin
              />
            }
          />
        </SpinnerWrapper>
      )}
    </Modal>
  );
};

export default SendAll;