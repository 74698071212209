import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IGetBook } from '../../../core/modules/book/book.model';
import { COLUMNSHEADING, FILETYPE, FILEEXTENSION, EXPORTTYPE } from '../constants';
import { getDate } from './getDate';

const getResult = (tags1, tags2) => {
  if (tags1 === '') {
    if (tags2 === '') {
      return '';
    } else {
      return tags2;
    }
  } else if (tags2 === '') {
    if (tags1 === '') {
      return '';
    } else {
      return tags1;
    }
  } else {
    return `${tags1}, ${tags2}`;
  }
};

const generateXLS = (
  data: IGetBook[],
  id,
  chekedColumns: CheckboxValueType[],
  type: string
  // eslint-disable-next-line max-params
) => {
  const headers = COLUMNSHEADING[id].filter(
    name => !chekedColumns.includes(name) && (name !== 'More')
  );

  const date_regex = /^\d{4}-\d{2}-\d{2}$/;

  if (window.location.pathname.includes('daily-rank')) {
    headers.unshift('Title');
    const date_head = data.length > 0 ? Object.keys(data[0])
      .filter(d => date_regex.exec(d))
      .reverse() : [];

    date_head.forEach((name) => headers.push(name));
  }

  const author: string[] = [];
  const books = data.map(book => {
    const obj = {};

    headers.forEach(key => {
      if (key === 'Tags') {
        const tags1 = book['Primary Tags']?.split(',').join(',\n') ?? '';
        const tags2 = book['Secondary Tags']?.split(',').join(',\n') ?? '';
        const result = getResult(tags1, tags2);
        obj[key] = result;
      } else if (key === 'ISBN') {
        obj[key] = book[key] ? String(book[key]) : '';
      } else if (key === 'Kindle Unlimited') {
        obj[key] = book[key] ? 'Yes' : 'No';
      } else if (key === 'First 12 months sales (units)') {
        const splitData = book['First Year Audiobooks Sold']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';
        obj[key] = value;
      } else if (key === 'Trailing 12 months sales (units)') {
        const splitData = book['Trailing Year Audiobooks Sold']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';
        obj[key] = value;
      } else if (key === 'Lifetime sales (units)') {
        const splitData = book['Lifetime Audiobooks Sold']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';
        obj[key] = value;
      } else if (key === 'Lifetime sales (dollars)') {
        const splitData = book['Lifetime Sales']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';
        obj[key] = value;
      } else if (key === 'Audible Product ID') {
        obj[key] = book['Podium Id'] || '';
      } else if (key.match(date_regex)) {
        obj[key] = book[key] || '-';
      } else {
        if (key === 'Author' && book['CRM Author Id']) {
          author.push(book[key]);
        }
        obj[key] = book[key] || 0;
      }
    });

    return obj;
  });

  const ws = XLSX.utils.json_to_sheet(books);
  let excelBuffer = '';

  if (type === 'csv') {
    excelBuffer = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
  } else {
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    excelBuffer = XLSX.write(wb, {
      bookType: EXPORTTYPE[type],
      type: 'array'
    });
  }

  const csvData = new Blob([excelBuffer], { type: FILETYPE[type] });
  const date = getDate();

  FileSaver.saveAs(csvData, `report_${date}${FILEEXTENSION[type]}`);
};

export default generateXLS;