import Button from '../../components/Button';
import { UserAddOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styled from 'styled-components';
import ModalCreateUser from './ModalCreateUser';

const WrapperAddNewBtn = styled.div`
  width: 100%;
  text-align: end;
  margin-bottom: 24px;
`;

const AddNewUser = ({ roles, isLoadingRoles }) => {
  const [modal, setModal] = useState({ open: false, user: {} });

  return (
    <>
      <ModalCreateUser
        user={{}}
        open={modal?.open}
        setOpen={setModal}
        roles={roles}
        isLoadingRoles={isLoadingRoles}
      />

      <WrapperAddNewBtn>
        <Button
          type='primary'
          onClick={() => {
            window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Open Add User' });
            setModal({ open: true, user: {} });
          }}
        >
          <UserAddOutlined /> Add user
        </Button>
      </WrapperAddNewBtn>
    </>
  );
};

export default AddNewUser;