import { useInfiniteQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import { useServices } from '../../../services/useServices';

// eslint-disable-next-line max-params
export const useFiltering = (endpoint: string, id, enabled, search) => {
  const {
    book: { getFilter }
  } = useServices();

  const { source: sourcePath } = useActions();

  return useInfiniteQuery(
    [endpoint + id, search],
    ({ pageParam = 1 }) => getFilter(endpoint, id, pageParam, search, sourcePath['source']),
    {
      enabled,
      getPreviousPageParam: firstPage => firstPage.previousId ?? false,
      getNextPageParam: lastPage => lastPage.nextId ?? false
    }
  );
};
