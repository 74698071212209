import { defaultSort } from '../../../context/Sort.context';

const getParsedData = (searchQuery = '', tabId: string): {filters: any, sort: any} => {
  const searchParams = new URLSearchParams(searchQuery);
  const filters = {};
  const sort = { ...defaultSort[tabId] };

  searchParams.forEach((value, key) => {
    try {
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);

      if (decodedKey.toLowerCase() === 'sortby') {
        sort['sortBy'] = decodedValue;
      } else if (decodedKey.toLowerCase() === 'sorttype') {
        sort['sortType'] = decodedValue;
      } else if (filters[decodedKey]) {
        filters[decodedKey] = Array.isArray(filters[decodedKey])
          ? [...filters[decodedKey], decodedValue]
          : [filters[decodedKey], decodedValue];
      } else {
        filters[decodedKey] = decodedValue;
      }
    }
    catch (error) {
      // Do nothing
    }
  });

  return {
    filters,
    sort
  };
};

export default getParsedData;