
import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import Tabs from '../../../components/Tabs';
import { useCustomObject } from '../../../core/modules/book/hooks/useCustomObject';
import EditKeyword from './EditKeyword';
import EditName from './EditName';
import EditParentGenre from './EditParentGenre';

const tabs = [
  { label: 'Edit Name',
    key: 'Edit Name',
    disabled: false,
    index: 0 },
  { label: 'Edit Keyword',
    key: 'Edit Keyword',
    disabled: false,
    index: 1 },
  { label: 'Edit Parent Genre',
    key: 'Edit Parent Genre',
    disabled: false,
    index: 2 }
];

const EditGenre = ({ onClose, open }) => {
  const [currentStep, setCurrentStep] = useState(tabs[0]);
  const { data, isLoading } = useCustomObject(
    'genres'
  );
  const steps = [
    <EditName key={'EditName'} onClose={onClose} data={data} isLoading={isLoading} />,
    <EditKeyword key={'EditKeyword'} onClose={onClose} data={data} isLoading={isLoading} />,
    <EditParentGenre key={'EditParentGenre'} onClose={onClose} data={data} isLoading={isLoading} />
  ];

  return (<Modal okText='Submit'
    title='Edit custom genre'
    visible={open}
    footer={false}
    className={'create-genre-modal'}
    width={'95%'}
    onCancel={onClose}
  >
    <Tabs
      tabs={tabs}
      onChange={key => {
        const activeTab = tabs?.find(tab => tab?.key === key) ?? tabs[0];
        setCurrentStep(activeTab);
      }}
      activeKey={currentStep.key}
    />
    {steps[currentStep?.index]}
  </Modal>

  );
};

export default EditGenre;
