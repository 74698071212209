import { useCallback, useEffect, useMemo, useState } from 'react';
import { DAILY_RANK_EBOOKS } from '../../../core/constants';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { BASEFONTSIZE, COLUMNSHEADING, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import ResizeTable from '../../../composed-components/Resize';
import { EFontSize, IBook, TColumns } from '../types/types';
import Pagination from '../../../components/Pagination';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import getAllColumns from '../columns/getAllColumns';
import renderDailyRanksColumnData from '../utils/renderColumnsHelper';
import styled from 'styled-components';
import { useActions } from '../../../context/Actions.context';

const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DailyRankEbooks = () => {
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const [deleteItems, setDeleteItems] = useState<string[]>([]);

  const { isRefreshed } = useActions();
  const { filter } = useFilter();
  const { sort } = useSort();

  const changePage = p => setPage(p);
  const deleteBooks = book => setDeleteItems(book);
  const changeFontSize = size => setFontSize(size);

  const date_regex = /^\d{4}-\d{2}-\d{2}$/;

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    DAILY_RANK_EBOOKS, {
      page,
      ...filter[DAILY_RANK_EBOOKS],
      ...sort[DAILY_RANK_EBOOKS]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(
    DAILY_RANK_EBOOKS, { ...filter[DAILY_RANK_EBOOKS] }
  );

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
    }, REFETCHDELAY);
  };

  const sortComponent = useCallback(
    (title: string) => (
      <Sorting id={DAILY_RANK_EBOOKS} title={title} changePage={changePage} />
    ),
    []
  );

  const thead = useMemo(() => {
    const allColumns = getAllColumns(sortComponent);
    const head = COLUMNSHEADING[DAILY_RANK_EBOOKS].map(title => allColumns[title]);

    return head;
  }, [sortComponent]);

  const dataSource: IBook[] | undefined = data?.map(book => ({
    ...book,
    key: book.ASIN
  }));

  const date_head = data.length > 0 ? Object.keys(data[0])
    .filter(date => date_regex.exec(date))
    .reverse() : [];

  thead.length = 0;
  COLUMNSHEADING[DAILY_RANK_EBOOKS].forEach(title => {
    const col = getAllColumns(sortComponent)[title];
    thead.push(col);
  });

  date_head.forEach((d) => thead.push(
    {
      title: () => sortComponent(`${d}`),
      dataIndex: `${d}`,
      key: `${d}`,
      className: 'show',
      width: 120,
      render: renderDailyRanksColumnData
    }
  ));

  useEffect(() => {
    if (!isRefreshed) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
  }, [page, filter[DAILY_RANK_EBOOKS], sort[DAILY_RANK_EBOOKS]]);

  const [columns, setColumns] = useState<TColumns[]>(thead);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm id={DAILY_RANK_EBOOKS} page={page} changePage={changePage} />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={DAILY_RANK_EBOOKS}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
          deleteBooks={deleteBooks}
          deleteItems={deleteItems}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            id={DAILY_RANK_EBOOKS}
            dataSource={dataSource}
            deleteItems={deleteItems}
            isFetching={isFetching}
            deleteBooks={deleteBooks}
            columns={columns}
            refetchTable={refetchTable}
          />
          <TableFooter>
            <Pagination
              total={count}
              current={page}
              onChange={setPage}
              pageSize={PAGESIZE}
            />
          </TableFooter>
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default DailyRankEbooks;