import Endpoints, {
  GET_CRM_USER,
  GETUSER,
  GET_ALL_USERS,
  EDIT_USER,
  DELETE_USER,
  CREATE_USER
} from '../../constants/endpoints';
import { AbstractApiService } from '../../services/abstract-service';
import { IGetUserCRMResponseDto } from './user.dto';
import { UserActivityPayloadType } from './user.model';

export interface IUserService {
  getUserCRM: () => Promise<any>;
  getUser: () => Promise<any>;
  getAllUsers: (params: any) => Promise<any>;
  getAllUserRoles: () => Promise<any>;
  deleteUser: (params: any) => Promise<any>;
  editUser: ({ id, ...body }: any) => Promise<any>;
  createUser: (params: any) => Promise<any>;
  feedback: (values: object, keyUrl: string) => Promise<any>;
  userActivity: (action: string, body: UserActivityPayloadType | undefined) => Promise<any>;
}

export class UserService extends AbstractApiService implements IUserService {
  public getUserCRM = async () => {
    const { data } = await this.api.get<IGetUserCRMResponseDto>(
      Endpoints[GET_CRM_USER]
    );

    return data.data;
  };

  public getUser = async () => {
    const { data } = await this.api.get<any>(`${Endpoints[GETUSER]}`);

    return data;
  };

  public getAllUsers = async (params: object) => {
    let searchParams = '';

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach(el => {
          searchParams += `${key}=${el}&`;
        });
        return;
      }

      // eslint-disable-next-line no-unused-expressions
      if (key) {
        searchParams += `${key}=${value}&`;
      }
    });

    searchParams = searchParams.replace(/.$/g, '');

    const { data } = await this.api.get<any>(
      `${Endpoints[GET_ALL_USERS]}/${searchParams}`
    );

    return data;
  };

  public getAllUserRoles = async () => {
    const { data } = await this.api.get<any>(
      `${Endpoints[GET_ALL_USERS]}/roles`
    );

    return data;
  };

  public deleteUser = async (params: object) => {
    let searchParams = '';

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      // eslint-disable-next-line no-unused-expressions
      if (key) {
        searchParams += `${key}=${value}&`;
      }
    });

    searchParams = searchParams.replace(/.$/g, '');

    const { data } = await this.api.get<any>(
      `${Endpoints[DELETE_USER]}/${searchParams}`
    );

    return data;
  };

  public editUser = async ({ id, key, ...body }) => {
    const { data } = await this.api.post<any>(
      `${Endpoints[EDIT_USER]}/id=${id}`,
      body
    );

    return data;
  };

  public createUser = async ({ key, ...body }) => {
    const { data } = await this.api.post<any>(
      `${Endpoints[CREATE_USER]}`,
      body
    );

    return data;
  };

  public feedback = async (values: object, keyUrl) => {
    const formData = new FormData();
    Object.entries(values)?.forEach(([key, value]) => {
      if (key === 'files') {
        values[key].forEach((s, i) => {
          const blob = new Blob([s], {});
          formData.append(`${key}-${i}`, blob, values[key][i].name);
        });
        return;
      }
      formData.append(key, value);
    });

    const { data } = await this.api.post<any>(
      `feedback/${keyUrl}`,
      formData
    );

    return data;
  };

  public userActivity = async (action: string, body: UserActivityPayloadType | undefined) => {
    const { data } = await this.api.request<any>(
      {
        url: `user-activity/${action}`,
        method: 'post',
        data: body,
        validateStatus: null
      }
    );

    return data;
  };
}
