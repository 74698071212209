import {
  ALL_EXCLUDED,
  ALL_NEW_TITLE,
  BOOKSTAT_DATA,
  COMPETITORS,
  PENDING_EXCLUSION,
  PODIUM_SALES,
  PODIUM_TITLES,
  SEND_TO_CRM,
  WATCHLIST,
  MANUALLY_ADDED,
  AUTHORS,
  PODIUMAUTHORS,
  RISING_STARS,
  TRENDING,
  POPULAR,
  WATCHLIST_RR,
  PENDING_EXCLUSION_RR,
  ALL_EXCLUDED_RR,
  PODIUM_TITLES_RR,
  ALL_TITLES_RR,
  DAILY_RANK_EBOOKS,
  DAILY_RANK_AUDIO_BOOKS,
  DAILY_RANK_CATALOGUE,
  SAVED_SEARCHES
} from './endpoints';

export const Routes = {
  admin: '/',
  allExcluded: '/bookstat/all-excluded',
  allNewTitle: '/bookstat/all-new-titles',
  bookstat: '/bookstat',
  bookstatData: '/bookstat/bookstat-data',
  competitors: '/bookstat/competitors',
  dashboard: '/dashboard',
  forgotPass: '/forgot-password',
  login: '/login',
  customLogin: '/custom-login',
  pendingExclusion: '/bookstat/pending-exclusion',
  podiumSales: '/bookstat/podium-sales',
  podiumTitles: '/bookstat/podium-titles',
  sentToCrm: '/bookstat/sent-to-crm',
  tabs: '/:tab',
  users: '/users',
  watchlist: '/bookstat/watchlist',
  manuallyAdded: '/bookstat/manually-added-titles',
  savedSearchesBookstat: '/bookstat/my-saved-searches',
  royalRoad: '/royal-road',
  pendingExclusionRR: '/royal-road/pending-exclusion',
  excludedRoyalRoad: '/royal-road/all-excluded',
  allTitlesRoyalRoad: '/royal-road/all-new-titles',
  sentToCrmRoyalRoad: '/royal-road/sent-to-crm',
  watchlistRoyalRoad: '/royal-road/watchlist',
  popular: '/royal-road/popular',
  risingStars: '/royal-road/rising-stars',
  trending: '/royal-road/trending',
  authors: '/royal-road/authors',
  podiumAuthors: '/royal-road/podium-authors',
  podiumTitlesRoyalRoad: '/royal-road/podium-titles',
  savedSearchesRoyalRoad: '/royal-road/my-saved-searches',
  dailyRank: '/daily-rank',
  dailyRankAudiobooks: '/daily-rank/audio-books',
  dailyRankEbooks: '/daily-rank/ebooks',
  dailyRankCatalogue: '/daily-rank/catalogue',
  savedSearchesDailyRank: '/daily-rank/my-saved-searches',
  usersAdminDashboard: '/users-admin-dashboard'
};

export const TAB_NAME = {
  [ALL_NEW_TITLE]: 'All New Titles',
  [ALL_EXCLUDED]: 'All Excluded',
  [BOOKSTAT_DATA]: 'Bookstat Data',
  [COMPETITORS]: 'Competitors',
  [PENDING_EXCLUSION]: 'Pending Exclusion',
  [PODIUM_SALES]: 'Podium Sales',
  [PODIUM_TITLES]: 'Podium Titles',
  [SEND_TO_CRM]: 'Sent to CRM',
  [WATCHLIST]: 'Watchlist',
  [MANUALLY_ADDED]: 'Manually Added',
  [ALL_TITLES_RR]: 'All Titles',
  [WATCHLIST_RR]: 'Watchlist',
  [PENDING_EXCLUSION_RR]: 'Pending Exclusion',
  [ALL_EXCLUDED_RR]: 'All Excluded',
  [POPULAR]: 'Popular',
  [RISING_STARS]: 'Rising Stars',
  [TRENDING]: 'Trending',
  [AUTHORS]: 'All Authors',
  [PODIUMAUTHORS]: 'Podium Authors',
  [PODIUM_TITLES_RR]: 'Podium Titles',
  [DAILY_RANK_EBOOKS]: 'E-Books',
  [DAILY_RANK_AUDIO_BOOKS]: 'Audiobooks',
  [DAILY_RANK_CATALOGUE]: 'Audiobooks & E-Books Catalogue',
  [SAVED_SEARCHES]: 'My Searches'
};