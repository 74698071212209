import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

const useAllUserRoles = () => {
  const {
    user: { getAllUserRoles }
  } = useServices();

  const response = useQuery(
    ['get-all-users-roles'],
    () => getAllUserRoles(),
    {
      refetchInterval: false
    }
  );

  return { data: response?.data?.data?.['user_roles'] || [], isLoading: response?.isLoading };
};

export default useAllUserRoles;