export const ALL_NEW_TITLE = 'allnewtitles';
export const ALL_EXCLUDED = 'allexcluded';
export const BOOKSTAT_DATA = 'bookstatdata';
export const COMPETITORS = 'competitors';
export const PENDING_EXCLUSION = 'pendingexclusion';
export const PODIUM_SALES = 'podiumsales';
export const PODIUM_TITLES = 'podiumtitles';
export const SEND_TO_CRM = 'sendtocrm';
export const WATCHLIST = 'watchlist';
export const DELETE = 'delete';
export const RETURN = 'return';
export const AUTHSIGNIN = 'authSingIn';
export const AUTHLOGIN = 'authLogIn';
export const REFRESHTOKEN = 'refreshToken';
export const GET_CRM_USER = 'getUserCRM';
export const GETUSER = 'getUser';
export const FILTER = 'filters';
export const TAGS = 'tags';
export const DELETE_CRM = 'deleteCRM';
export const SEND_CRM = 'sendCRM';
export const GET_ALL_USERS = 'getAllUsers';
export const DELETE_USER = 'deleteUser';
export const EDIT_USER = 'editUser';
export const CREATE_USER = 'createUser';
export const USERS = 'users';
export const SEARCH_BY_CONDITIONS = 'search';
export const MANUALLY_ADDED = 'manuallyadded';
export const POPULAR = 'popular';
export const RISING_STARS = 'risingStars';
export const TRENDING = 'trending';
export const AUTHORS = 'authors';
export const PODIUMAUTHORS = 'podiumAuthors';
export const ALL_TITLES_RR = 'alltitlesRR';
export const WATCHLIST_RR = 'watchlistRR';
export const PENDING_EXCLUSION_RR = 'pendingExclusionRR';
export const ALL_EXCLUDED_RR = 'excludedRR';
export const SENT_TO_CRM_RR = 'senttocrmRR';
export const ROYAL_ROAD_TITLE = 'royalroadtitle';
export const ROYAL_ROAD_AUTHOR = 'royalroadauthor';
export const PODIUM_TITLES_RR = 'podiumtitlesRR';
export const TWITTER = 'twitter';
export const AMAZON = 'amazon';
export const AUTHOR_TAGS = 'authortags';
export const DAILY_RANK_AUDIO_BOOKS = 'dailyRankAudioBooks';
export const DAILY_RANK_EBOOKS = 'dailyRankEbooks';
export const DAILY_RANK_CATALOGUE = 'dailyRankCatalogue';
export const SAVED_SEARCHES = 'savedSearches';

export const SOURCE = {
  'bookstat': '/bookstat',
  'royal-road': '/royal-road',
  'daily-rank': '/daily-rank'
};

export const BookstatEndpoints = {
  [ALL_EXCLUDED]: '/all-excluded',
  [ALL_NEW_TITLE]: '/all-new-titles',
  [WATCHLIST]: '/watchlist',
  [MANUALLY_ADDED]: '/manually-added-titles',
  [PENDING_EXCLUSION]: '/pending-exclusion',
  [PODIUM_SALES]: '/podium-sales',
  [PODIUM_TITLES]: '/podium-titles',
  [SEND_TO_CRM]: '/sent-to-crm',
  [BOOKSTAT_DATA]: '/bookstat-data',
  [COMPETITORS]: '/competitors'
};

export const RoyalRoadEndpoints = {
  [POPULAR]: '/popular',
  [RISING_STARS]: '/rising-stars',
  [TRENDING]: '/trending',
  [AUTHORS]: '/authors',
  [PODIUMAUTHORS]: '/podium-authors',
  [WATCHLIST_RR]: '/watchlist',
  [ALL_EXCLUDED_RR]: '/all-excluded',
  [PENDING_EXCLUSION_RR]: '/pending-exclusion',
  [ALL_TITLES_RR]: '/all-new-titles',
  [SENT_TO_CRM_RR]: '/sent-to-crm',
  [PODIUM_TITLES_RR]: '/podium-titles'
};

export const DailyRankEndpoints = {
  [DAILY_RANK_EBOOKS]: '/ebooks',
  [DAILY_RANK_AUDIO_BOOKS]: '/audio-books',
  [DAILY_RANK_CATALOGUE]: '/catalogue'
};

const Endpoints = {
  [AUTHSIGNIN]: '/auth/sign-in',
  [AUTHLOGIN]: '/auth/custom-login',
  [CREATE_USER]: '/create-users',
  [DELETE_CRM]: 'hubspot-delete',
  [DELETE_USER]: '/delete-users',
  [DELETE]: '/deleted',
  [EDIT_USER]: '/update-users',
  [FILTER]: '/filters',
  [GET_ALL_USERS]: '/get-all-users',
  [GET_CRM_USER]: '/get-all-users/enable_crm_rights=true',
  [GETUSER]: '/get-user-details',
  [REFRESHTOKEN]: '/auth/refresh-tokens',
  [RETURN]: '/return',
  [SEARCH_BY_CONDITIONS]: 'search',
  [SEND_CRM]: 'hubspot-send',
  [TAGS]: '/tags',
  [USERS]: '/users',
  [ROYAL_ROAD_TITLE]: 'https://www.royalroad.com/fiction/',
  [ROYAL_ROAD_AUTHOR]: 'https://www.royalroad.com/profile/',
  [TWITTER]: 'https://twitter.com/',
  [AMAZON]: 'https://www.amazon.com/dp/',
  [AUTHOR_TAGS]: '/author-tags',
  [SAVED_SEARCHES]: '/my-saved-searches',
  ...BookstatEndpoints,
  ...RoyalRoadEndpoints,
  ...DailyRankEndpoints
};

export default Endpoints;