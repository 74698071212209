import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { Auth } from '../core/constants/auth';
import AdminRouter from '../pages/Admin';
import AuthorizedRouter from './Authorized';

function Main() {
  const init = localStorage.getItem(Auth.ACCESS_TOKEN_KEY);

  const location = useLocation();
  const redirectPath = location.pathname + location.search;

  if (location.pathname !== '/login') {
    localStorage.setItem('redirectPath', redirectPath);
  }

  return init ? <AdminRouter /> : <AuthorizedRouter/>;
}

const AppRouter = () => (
  <Router>
    <Route path='/' component={Main} />
  </Router>
);

export default AppRouter;
