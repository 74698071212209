import { notification } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { SEND_TO_CRM, SENT_TO_CRM_RR, SOURCE } from '../../../constants';
import { useServices } from '../../../services/useServices';
import { useActions } from '../../../../context/Actions.context';

export const useReturn = ({ book_ids, tabId, source }) => {
  const { book: { returnTitle } } = useServices();
  const { setActionLoading } = useActions();
  const queryClient = useQueryClient();
  const params: { [key: string]: any } = {};

  if (source === SOURCE.bookstat) {
    params.amazonasin = book_ids;
  } else if (source === SOURCE['royal-road']) {
    params.fiction_id = book_ids;
  }

  return useMutation(
    'return-title',
    () =>
      returnTitle(tabId, params, source),
    {
      onSuccess: data => {
        if (data.status === 200) {
          queryClient.refetchQueries(`get-row-count${tabId}`);
          queryClient.refetchQueries(`get-${tabId}`);

          notification.success({
            description: 'Successfully returned title(s).',
            message: ''
          });

          if (tabId === SEND_TO_CRM || tabId === SENT_TO_CRM_RR) {
            notification.info({
              description: 'Please delete the data from Hubspot.',
              message: ''
            });
          }
        } else {
          notification.error({
            description: 'The title(s) have not been returned.',
            message: ''
          });
        }
      },
      onError: () => {
        notification.error({
          description: 'Please try again.',
          message: 'Failed to return title(s).'
        });
      },
      onSettled: () => {
        setActionLoading(false);
      }
    }
  );
};