import React, { useEffect, useMemo } from 'react';
import Table from '../../../components/Table';
import { columnsGenre } from './columns';

import { WrapperTable } from './styles';

const CreateTable = ({ data, isLoading, formik, y = '' }) => {
  const onSelectChange = async (newSelectedRowKeys) => {
    await formik.setFieldValue('amazonasin', newSelectedRowKeys);
  };


  useEffect(() => {
    async function addAsync() {
      const newData = data?.data.map(book =>
        book['ASIN']
      );
      await formik.setFieldValue('amazonasin', newData);
    }

    addAsync();
  }, [data]);

  const dataSource = useMemo(() => {
    if (formik.values.id !== null) {
      return data?.data.map(book => ({ ...book, key: book['ASIN'] }));
    } else {
      return [];
    }
  }, [data]);


  const rowSelection = {
    selectedRowKeys: formik?.values?.amazonasin,
    onChange: onSelectChange
  };


  return (
    <WrapperTable>
      <Table
        columns={columnsGenre}
        dataSource={dataSource}
        x={'100%'}
        y={y || `calc(80vh - ${data?.count > 1000 ? 150 : 110}px)`}
        sticky={true}
        loading={isLoading}
        rowSelection={rowSelection}
        pagination={false} />
    </WrapperTable>
  );
};

export default React.memo(CreateTable);
