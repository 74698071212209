
import styled from 'styled-components';

export const WrapperTable = styled.div`
  font-size: 12px;
  height: 100%;
`;

export const WarningInfo = styled.div`
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 12px;
  font-size: 13px;
  background: #ffe5e9;
  text-overflow: ellipsis;
`;

export const ContentBlock = styled.div`
  width: calc(100% - 370px);  
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const ResetBtn = styled.button`
   line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;

    &:hover {
      color: #10c78e;
    background: #fff;
    border-color: #10c78e;
    }
`;

export const SubmitBtn = styled.button`
 line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
    background: #00b27b;
    color: #fff;
    border-color: #00b27b;

    &:hover {
      background: #10c78e;
    color: #fff;
    border-color: #10c78e;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default ;
    }
  
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
  max-height: 100%;
`;

export const Footer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-right: 16px;
`;


export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NameGenreWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px;
`;

