import { AxiosInstance } from 'axios';
import { AuthService, IAuthService } from '../modules/auth/auth.service';
import { BookService, IBookService } from '../modules/book/book.service';
import { UserService, IUserService } from '../modules/user/user.service';
import { DashboardService } from '../modules/dashboard/dashboard.service';
import { ILocalStorageService } from './local-storage.service';


export interface IServices {
  user: IUserService;
  auth: IAuthService;
  book: IBookService;
  dashboard: any;
}

export const createServices = (
  api: AxiosInstance,
  localStorageService: ILocalStorageService
) => ({
  user: new UserService(api),
  auth: new AuthService(api, localStorageService),
  book: new BookService(api),
  dashboard: new DashboardService(api)
});
