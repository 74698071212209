import { createContext, useState, useContext } from 'react';

type UserActivityContextValue = {
    first_activity_time: any,
    activity_count: number;
    inactivity_count: number;
    bookstat: number;
    'royal-road': number;
    'daily-rank': number;
    increment: (key: string, value?: number) => void;
    decrement: (key: string, value?: number) => void;
    set: (key: string, value: any) => void;
    updateValues: (newValues: Partial<UserActivityContextValue>) => void;
  };

const UserActivityContext = createContext<UserActivityContextValue>({
  first_activity_time: null,
  activity_count: 0,
  inactivity_count: 0,
  bookstat: 0,
  'royal-road': 0,
  'daily-rank': 0,
  increment: (key: string, value?: number) => console.log(key, value),
  decrement: (key: string, value?: number) => console.log(key, value),
  set: (key: string, value: any) => console.log(key, value),
  updateValues: (newValues: Partial<UserActivityContextValue>) => console.log(newValues)
});

export const UserActivityProvider = ({ children }) => {
  const [values, setValues] = useState<UserActivityContextValue>({
    first_activity_time: null,
    activity_count: 0,
    inactivity_count: 0,
    bookstat: 0,
    'royal-road': 0,
    'daily-rank': 0,
    increment: (key: string, amount?: number) => {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: prevValues[key] + (amount ?? 1)
      }));
    },
    decrement: (key: string, amount?: number) => {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: prevValues[key] - (amount ?? 1)
      }));
    },
    set: (key: string, value: any) => {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value
      }));
    },
    updateValues: (newValues: Partial<UserActivityContextValue>) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...newValues
      }));
    }
  });

  return (
    <UserActivityContext.Provider value={values}>
      {children}
    </UserActivityContext.Provider>
  );
};

export const useUserActivity = () => useContext(UserActivityContext);

export default UserActivityContext;