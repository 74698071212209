/* eslint-disable max-params */
import { useQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';
import { IGetBookListParams, IGetBookListResponseDto } from '../book.dto';

export const useExportData = (
  endpoint: string,
  type,
  params: IGetBookListParams,
  onSuccess
) => {
  const {
    book: { getBooksList }
  } = useServices();

  const { source: sourcePath } = useActions();

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
    status
  } = useQuery<IGetBookListResponseDto, Error>(
    `export-${endpoint + type}`,
    () => getBooksList(Endpoints[endpoint], params, sourcePath['source']),
    {
      onSuccess,
      cacheTime: 0,
      enabled: false
    }
  );

  return {
    data: response?.data,
    isLoading,
    isFetching,
    status,
    refetch
  };
};
