const renderDailyRanksColumnData = (text) => {
  const rank = parseInt(text, 10);
  let backgroundColor = '';

  switch (true) {
  case rank >= 1 && rank <= 100:
    backgroundColor = '#00B27B';
    break;
  case rank >= 101 && rank <= 1000:
    backgroundColor = '#A9ECA2';
    break;
  case rank >= 1001 && rank <= 10000:
    backgroundColor = '#FAFAD2';
    break;
  default:
    break;
  }

  const style = backgroundColor ? { backgroundColor } : null;
  const children = text || '';

  return { props: { style }, children };
};

export default renderDailyRanksColumnData;