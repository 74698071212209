import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';

export const useAuthLogin = () => {
  const {
    auth: { loginUser }
  } = useServices();

  const { push } = useHistory();

  const login = (emailAndPassword: string) => {
    const [email, password] = emailAndPassword.split(':');

    return loginUser(email, password);
  };

  const { isLoading, isError, mutate } = useMutation(
    Endpoints.authLogIn,
    login,
    {
      onSuccess: () => {
        push('/');
      }
    }
  );

  return {
    isLoading,
    isError,
    mutate
  };
};