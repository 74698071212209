import { notification } from 'antd';
import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

export const useKeywordSearch = (values: any, params: any, options) => {
  const {
    book: { getBookByKeywordSearch }
  } = useServices();

  return useQuery('search-for-genre', () => getBookByKeywordSearch(values, params), { ...options || {},
    onSuccess: (data) => {
      if (!data?.success && data?.msg) {
        notification.info({
          description: data?.msg,
          message: ''
        });
      }
    } });
};
