import { BOOKSTAT_DATA } from '../../../core/constants';
import { COLUMNSHEADING } from '../constants';
import { TColumns } from '../types/types';
import getAllColumns from './getAllColumns';
import AlsoBought from '../../../composed-components/AlsoBought';

export const getBookstatData = (
  sorting: (title: string) => JSX.Element
): Array<TColumns> => {
  const allColumns = getAllColumns(sorting);
  const head = COLUMNSHEADING[BOOKSTAT_DATA].map(title => {
    if (title === 'Podium Hit Count') {
      return {
        ...allColumns[title],
        render(text, book) {
          const result = <AlsoBought podiumHitCount={text === null ? -1 : text} asin={book.key}/>;

          return {
            children: result
          };
        }
      };
    }
    return allColumns[title];
  });

  return head;
};
