import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

export const useCustomObject = (name, options = {}) => {
  const {
    book: { getCustomObject }
  } = useServices();

  return useQuery(['get-custom-object', name],
    () => getCustomObject(name),
    {
      ...options
    }
  );
};
