import { AxiosResponse } from 'axios';
import Endpoints, { DELETE_CRM, FILTER, RETURN, SEND_CRM, SOURCE } from '../../constants/endpoints';
import { AbstractApiService } from '../../services/abstract-service';
import {
  ICreateCustomObjectBody,
  ICustomObjectKeywordsBody,
  ICustomObjectNameBody,
  IGetBookListParams,
  IGetBookEndpoints,
  IGetBookListResponseDto,
  IGetDataRowCountResponseDto,
  IGetCustomObjectGenres,
  ICustomObjectParentNameBody
} from './book.dto';
import { buildSearchParams } from '../../../section/Common/utils/stringUtils';

type ResUpdate = {
  data: string;
  headers: object;
  request: XMLHttpRequest;
  status: number;
  statusText: string;
};

type ResGetFilters = {
  allpages: number;
  data: string[];
  nextId: number;
  previousId: null | number;
};

enum EType {
  'title',
  'author',
  'isbn13',
  'genres',
  'parent'
}

export interface IBookService {
  getBooksList: (
    endpoint: string,
    params: IGetBookListParams,
    source: string
  ) => Promise<IGetBookListResponseDto>;
  getDataRowCount: (
    endpoint: string,
    params: IGetBookListParams,
    source: string
  ) => Promise<IGetDataRowCountResponseDto>;
  updateTitle: (
    endpoints: IGetBookEndpoints,
    params: IGetBookListParams
  ) => Promise<AxiosResponse<ResUpdate>>;
  getFilter: (
    endpoint: string,
    id: string,
    params: Object | null,
    search: string,
    source: string
  ) => Promise<ResGetFilters>;
  returnTitle: (
    endpoint: string,
    params: IGetBookListParams,
    source: string
  ) => Promise<AxiosResponse<ResUpdate>>;
  deleteFromCRM: (params: IGetBookListParams) => Promise<any>;
  sendToCRMSystem: (params: any, source: string) => Promise<any>;
  createCustomObject: (values: ICreateCustomObjectBody) => Promise<any>;
  deleteGenre: (values: object) => Promise<any>;
  editCustomObject: (
    name: string,
    body: ICustomObjectNameBody | ICustomObjectKeywordsBody | ICustomObjectParentNameBody) => Promise<any>;
  getBookByKeywordSearch: (values: object, params: object) => Promise<any>;
  getCustomObject: (name: keyof typeof EType) => Promise<AxiosResponse<IGetCustomObjectGenres>>;
  manuallyAddBook:(data: object) => Promise<any>;
  getAlsoBoughtBooks: (asin: string) => Promise<IGetBookListResponseDto>;
  getCategoryRankings: (asin: string, days: number | undefined) => Promise<IGetBookListResponseDto>;
  brendaSearches: (action: string, body: object | undefined) => Promise<any>;
  getBookDetails: (asin: string, source: string, dataType: string) => Promise<any>;
  deleteParentKey: (source: string, sourceTab: string, action: string) => Promise<any>;
}

export class BookService extends AbstractApiService implements IBookService {
  public getBooksList = async (endpoint: string, params: object, source: string) => {
    const searchParams = buildSearchParams(params);
    const { data } = await this.api.get<IGetBookListResponseDto>(
      `get${source}${endpoint}/${searchParams}`
    );

    return data;
  };

  public getDataRowCount = async (endpoint: string, params: object, source: string) => {
    const searchParams = buildSearchParams(params);
    const urlSuffix = searchParams ? `/${searchParams}` : '';
    const { data } = await this.api.get<IGetDataRowCountResponseDto>(
      `get-row-count${source}${endpoint}${urlSuffix}`
    );

    return data;
  };

  public updateTitle = async (endpoints: object, params: object) => {
    let searchParams = '';

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach(el => {
          searchParams += `${key}=${el}&`;
        });
        return;
      }

      if (key) {
        searchParams += `${key}=${value}&`;
      }
    });

    searchParams = searchParams.replace(/.$/g, '');
    const response = await this.api.get(`add-to-list${endpoints['source']}${endpoints['endpoint']}/${searchParams}`);

    if (response.status === 200) {
      await this.deleteParentKey(
        endpoints['source'],
        `/${endpoints['sourceTab']}`,
        Endpoints[endpoints['action']].replace(/^\//, '')
      );
    }

    return response;
  };

  // eslint-disable-next-line max-params
  public getFilter = async (id, endpoint, params, search, source) => {
    const { data } = await this.api.get(
      `${Endpoints[FILTER]}${source}${Endpoints[endpoint]}/${id}${
        params ? `/page=${params}` : ''
      }${search ? `&search=${search}` : ''}`
    );

    return data;
  };

  public returnTitle = async (endpoint, params: object, source: string) => {
    let searchParams = '';

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach(el => {
          searchParams += `${key}=${el}&`;
        });
        return;
      }

      if (key) {
        searchParams += `${key}=${value}&`;
      }
    });

    searchParams = searchParams.replace(/.$/g, '');

    const response = await this.api.get(`${Endpoints[RETURN]}${source}${Endpoints[endpoint]}/${searchParams}`);

    if (response.status === 200) {
      await this.deleteParentKey(source, Endpoints[endpoint], RETURN);
    }

    return response;
  };

  public deleteFromCRM = async (params: object) => {
    let srtParams = '';

    Object.entries(params).forEach(([key, value]) => {
      srtParams += `${key}=${value}&`;
    });

    srtParams = srtParams.replace(/.$/g, '');
    return await this.api.get(`${Endpoints[DELETE_CRM]}/${srtParams}`);
  };

  public sendToCRMSystem = async (params: object, source: string) => {
    let srtParams = '';
    const body = {};
    Object.entries(params).forEach(([key, value]) => {
      if (key === 'Notes') {
        body['notes'] = value;
        return;
      }
      srtParams += `${key}=${value}&`;
    });

    srtParams = srtParams.replace(/.$/g, '');
    return await this.api.post(`${Endpoints[SEND_CRM]}${source}/${srtParams}`, body);
  };

  public getBookByKeywordSearch = async (body: object, params: any) => {
    let searchParams = '';

    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      // eslint-disable-next-line no-unused-expressions
      if (key) {
        searchParams += `${key}=${value}&`;
      }
    });

    searchParams = searchParams.replace(/.$/g, '');

    const data = await this.api.post<any>(
      `search/all/${searchParams}`, body
    );

    return data;
  };


  public createCustomObject = async (body: ICreateCustomObjectBody) => {
    const res = await this.api.post<any>('create-custom-object', body);

    return res;
  };

  public deleteGenre = async (body: object) => {
    const res = await this.api.post<any>('delete-custom-object', body);

    return res;
  };

  public editCustomObject = async (objectName: string, body) => {
    const res = await this.api.post<any>(`edit-custom-object/${objectName}`, body);

    return res;
  };

  public getCustomObject = async (name: keyof typeof EType) => {
    const res = await this.api.get<any>(
      `get-custom-object/${name}`);

    return res;
  };

  public manuallyAddBook = async (data) => {
    const res = await this.api.post<any>('manually-add-titles', data);

    return res;
  };

  public getAlsoBoughtBooks = async (asin: string) => {
    const { data } = await this.api.get<IGetBookListResponseDto>(
      `get/bookstat/also-bought/search=${asin}`
    );

    return data;
  };

  public getCategoryRankings = async (asin: string, days: number | undefined) => {
    const { data } = await this.api.get<IGetBookListResponseDto>(
      days ? `get/category-rankings/${window.location.pathname.split('/')[2]}/search=${asin}&date_range=${days}` :
        `get/category-rankings/${window.location.pathname.split('/')[2]}/search=${asin}`
    );

    return data;
  };

  public brendaSearches = async (action: string, body: object | undefined) => {
    const { data } = await this.api.post<any>(`brenda-searches/${action}`, body);

    return data;
  };

  public getBookDetails = async (asin: string, source: string, dataType: string) => {
    if (source === SOURCE.bookstat && asin) {
      const { data } = await this.api.get<any>(
        `get-bookdetails/${dataType}/amazonasin=${asin}`
      );

      return data;
    }
  };

  public deleteParentKey = async (source: string, sourceTab: string, action: string) => {
    const { data } = await this.api.request<any>(
      {
        url: `brenda-cache/delete-parent-key${source}${sourceTab}/action=${action}`,
        method: 'get',
        validateStatus: null
      }
    );

    return data;
  };
}