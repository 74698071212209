import { useQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';
import { IGetBookListParams, IGetBookListResponseDto } from '../book.dto';

export const useBooksList = (endpoint: string, params: IGetBookListParams
) => {
  const {
    book: { getBooksList }
  } = useServices();

  const { source: sourcePath } = useActions();

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
    status
  } = useQuery<IGetBookListResponseDto, Error>(
    `get-${endpoint}`,
    () => getBooksList(Endpoints[endpoint], params, sourcePath['source']),
    {
      cacheTime: 1000000,
      enabled: false
    }
  );

  return {
    data: response?.data,
    dataRefreshTime: response?.last_backend_job_time,
    isLoading,
    isFetching,
    status,
    refetch
  };
};
