import { notification } from 'antd';
import { Field, useFormik, FormikProvider } from 'formik';
import { Select, Option } from '../../../components/Select';
import { required } from '../../../utils/validation';
import FormItem from '../../../components/FormItem';
import Button from '../../../components/Button';
import styled from 'styled-components';
import { useEditCustomObject } from '../../../core/modules/book/hooks/useEditCustomObject';
import Conditions from './Conditions';
import { useKeywordSearch } from '../../../core/modules/book/hooks/useKeywordSearch';
import { ContentBlock } from './styles';
import { useCallback, useMemo, useEffect } from 'react';
import Table from './Table';
import { Spinner } from '../../../components/Spinner';
import { LoadingOutlined } from '@ant-design/icons';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 12px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - 200px);
`;

const ChangeKeywords = ({ formik }) => {
  const { data, isLoading, isFetching, refetch } = useKeywordSearch({
    search: formik.values.search
  }, {
    page: 1,
    per_page: 1000
  }, {
    enabled: false,
    cacheTime: 0
  });

  const handleSearch = useCallback(() => {
    refetch();
  }, []);

  const MTable = useMemo(() =>
    <Table
      formik={formik}
      data={data?.data}
      y={'calc(80vh - 265px'}
      isLoading={isLoading || isFetching}
    />,
  [data, isLoading, formik, isFetching]);

  return <Body>
    <Conditions onSearch={handleSearch} style={{ marginRight: 16, overflow: 'auto' }} disabled={!formik.values?.id} />
    <ContentBlock style={{ minWidth: 400 }}>  {MTable}
    </ContentBlock></Body>;
};

const EditKeyword = ({ onClose, data, isLoading }) => {
  const editKeywords = useEditCustomObject('keywords');

  const formik = useFormik({
    initialValues: {
      id: null,
      search: {
        key: 'AND',
        children: [{
          text: ''
        }]
      },
      amazonasin: []
    },
    enableReinitialize: true,
    onSubmit: async () => null
  });

  useEffect(() => {
    formik.setFieldValue('amazonasin', []);
  }, [formik.values?.id]);

  const handleEdit = (values) => {
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Edit Keyword Action' });
    editKeywords.mutate({ data: [values] }, {
      onSuccess: (res) => {
        onClose();

        if (res?.success) {
          notification.success({
            description: 'Update successful',
            message: ' '
          });
        }
      },
      onError: () => {
        onClose();
        notification.error({
          description: 'Failed',
          message: ' '
        });
      }
    });
  };

  return (
    <FormikProvider value={formik}>
      <FormItem label='Select genre' required error={undefined}>
        <Field name={'id'} validate={required}>
          {({ field: { value, ...rest } }) => (
            <Select
              placeholder='Select...'
              value={value === '' || value === null ? undefined : value}
              {...rest}
              onChange={(v) => {
                formik.setFieldValue('id', v);
                const key = data?.data?.data?.find(el => el?.ID === v)?.Keywords || {
                  key: 'AND',
                  children: [{
                    text: ''
                  }]
                };

                if (typeof key === 'string') {
                  const correct = JSON.parse(key.replaceAll('\'', '"'));
                  formik.setFieldValue('search', correct);
                  return;
                }

                formik.setFieldValue('search', key);
              }}
              loading={isLoading}
              style={{ width: '100%' }}
            >
              {!isLoading ? data?.data?.data?.map(({ ID, Name }) =>
                <Option key={ID} value={ID}>
                  {Name}
                </Option>) : (
                <Option key='loading' value='loading' disabled={true}>
                  Loading...
                </Option>
              )}
            </Select>
          )}
        </Field>
      </FormItem>

      <ChangeKeywords formik={formik} />

      <Footer>
        {editKeywords.isLoading && <Spinner
          size='large'
          spinning={editKeywords.isLoading}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 34
              }}
              spin
            />
          }
        />
        }
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          onClick={() => handleEdit(formik.values)}
          disabled={!formik.values?.amazonasin?.length || !formik.values?.id}
          type='primary'
        >
          Edit
        </Button>
      </Footer>
    </FormikProvider>
  );
};

export default EditKeyword;