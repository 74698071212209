import React, { useCallback, useContext, useMemo, useState } from 'react';
import CreateGenre from '../composed-components/CustomObject/Genre/CreateGenre';
import DeleteGenre from '../composed-components/CustomObject/Genre/Delete';
import EditGenre from '../composed-components/CustomObject/Genre/EditGenre';
import { ACTIONS_GENRE } from '../core/constants/global-constants';

const GenreControllerContext = React.createContext({ handleGenreAction: (action: any) => console.log(action) });

export const GenreControllerProvider = ({ children }) => {
  const [genreAction, setGenreAction] = useState(null);
  const handleGenreAction = useCallback((action) => setGenreAction(action), []);
  const handleClose = useCallback(() => setGenreAction(null), []);
  const genreContextValue = useMemo(() => ({ handleGenreAction }), []);

  return (
    <GenreControllerContext.Provider value={genreContextValue}>
      {children}
      {genreAction === ACTIONS_GENRE.create
        && <CreateGenre open={genreAction === ACTIONS_GENRE.create} onClose={handleClose} />}
      {genreAction === ACTIONS_GENRE.delete
        && <DeleteGenre open={genreAction === ACTIONS_GENRE.delete} onClose={handleClose} />}
      {genreAction === ACTIONS_GENRE.edit
        && <EditGenre open={genreAction === ACTIONS_GENRE.edit} onClose={handleClose} />}
    </GenreControllerContext.Provider>
  );
};

export const GenreControllerConsumer = GenreControllerContext.Consumer;

export const useGenreController = () => {
  const context = useContext(GenreControllerContext);

  return context;
};

export default GenreControllerContext;