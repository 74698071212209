import { Modal, Popover } from 'antd';
import { useState, useCallback } from 'react';
import RankingsGraph from './RankingsGraph';
import { useQueryClient } from 'react-query';
import { LineChartOutlined } from '@ant-design/icons';
import { useCategoryRankings } from '../../core/modules/book/hooks/useCategoryRankings';

const RankingsGraphModal = ({ asin }) => {
  const [open, setOpen] = useState(false);
  const days = 365;
  const { data, isLoading, isFetching, refetch } = useCategoryRankings(asin, days);
  const queryClient = useQueryClient();

  const date_regex = /^\d{4}-\d{2}-\d{2}$/;
  const gd: any[] = [[], []];

  data?.map(d =>
    Object.keys(data[0]).filter(
      day => day
    )
      .forEach(
        date => {
          if (date && date_regex.exec(date)) {
            if (d['Genre Category'] === 'Overall Ranking') {
              gd[0].push({
                Date: date,
                Rank: d[date],
                Category: 'Overall Rank'
              });
            } else {
              gd[1].push({
                Date: date,
                Rank: d[date],
                Category: d['Genre Category']
              });
            }
          }
        }
      )
  );

  const handleSearch = useCallback(() => {
    refetch();
  }, []);

  const showRankingsGraph = () => {
    handleSearch();
    setOpen(true);
  };

  const handleCancel = () => {
    queryClient.cancelQueries({ queryKey: ['get/category-rankings'] });
    setOpen(false);
  };

  return (
    <>
      <Popover
        content={<div className='popover-content'>Daily Category Ranks Graph</div>}
        trigger='hover'
        placement='right'
      >
        <LineChartOutlined
          onClick={showRankingsGraph}
          className='actions-icons actions-icons-chart'
        />
      </Popover>

      <Modal
        title='Daily Rank Check: Performance'
        okText='Ok'
        visible={open}
        className={'category-rankings'}
        onCancel={handleCancel}
        width={'90%'}
        footer={true}
      >
        <RankingsGraph
          isLoading={isLoading}
          isFetching={isFetching}
          data={gd}
        />
      </Modal>
    </>
  );
};

export default RankingsGraphModal;