import { notification } from 'antd';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from '../constants/auth';

const createApiServices = baseURL => {
  const api = Axios.create({ baseURL });

  api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const requestConfig = { ...config };
      const token = localStorage.getItem(Auth.ACCESS_TOKEN_KEY);

      if (token) {
        requestConfig.headers['Authorization'] = `Bearer ${token}`;
      }

      return requestConfig;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 403) {
        notification.error({
          description: error.response?.data,
          message: error.message
        });
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        if (!localStorage.getItem(Auth.REFRESH_TOKEN_KEY)) {
          document.location.replace('/login');
          return;
        }
        return api
          .post('/auth/refresh-tokens', {
            Token: localStorage.getItem(Auth.REFRESH_TOKEN_KEY)
          })
          .then(res => {
            if (res.status === 200) {
              const {
                data: { access_token, refresh_token }
              } = res;

              localStorage.setItem(Auth.ACCESS_TOKEN_KEY, access_token);
              localStorage.setItem(Auth.REFRESH_TOKEN_KEY, refresh_token);

              error.response.config.headers[
                'Authorization'
              ] = `Bearer ${access_token}`;

              return api(error.response.config);
            }
            document.location.replace('/login');
            return Promise.reject(error);
          })
          .catch(err => {
            localStorage.removeItem(Auth.ACCESS_TOKEN_KEY);
            localStorage.removeItem(Auth.REFRESH_TOKEN_KEY);

            return Promise.reject(err);
          });
      }

      if (error.response.status === 400) {
        if (error.response.config.url.includes('search/all') ||
        error.response.config.url.includes('edit-custom-object') ||
        error.response.config.url.includes('create-custom-object')) {
          return error.response.data;
        } else if (error.response.config.url.includes('manually')) {
          return error.response;
        }
      }

      if (error.response.status === 500 || error.response.status === 202) {
        if (error.response.config.url.includes('manually-add-titles')) {
          return error.response;
        }
      }

      notification.error({
        description: error.response?.data?.msg,
        message: error.message
      });

      return Promise.reject(error);
    }
  );

  return api;
};

export default createApiServices;
