import React, { useEffect, useState, useCallback } from 'react';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { BOOKSTAT_DATA } from '../../../core/constants';
import { BASEFONTSIZE, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import { EFontSize, TColumns } from '../types/types';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import ResizeTable from '../../../composed-components/Resize';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import { getBookstatData } from '../columns/getBookstatData';
import Pagination from '../../../components/Pagination';
import getDataSource from '../utils/getDataSource';
import styled from 'styled-components';
import { useActions } from '../../../context/Actions.context';

const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BookstatData: React.FC = () => {
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const { filter } = useFilter();
  const { sort } = useSort();
  const { isRefreshed } = useActions();
  const changePage = p => setPage(p);
  const changeFontSize = size => setFontSize(size);

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    BOOKSTAT_DATA, {
      page,
      ...filter[BOOKSTAT_DATA],
      ...sort[BOOKSTAT_DATA]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(BOOKSTAT_DATA, { ...filter[BOOKSTAT_DATA] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
    }, REFETCHDELAY);
  };

  const sortColumns = useCallback(
    (title: string) => (
      <Sorting id={BOOKSTAT_DATA} title={title} changePage={changePage} />
    ),
    []
  );

  const thead = getBookstatData(sortColumns);
  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data);

  useEffect(() => {
    if (!isRefreshed) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
  }, [page, filter[BOOKSTAT_DATA], sort[BOOKSTAT_DATA]]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm id={BOOKSTAT_DATA} page={page} changePage={changePage} />
        <Settings
          thead={thead}
          refetchTable={refetchTable}
          id={BOOKSTAT_DATA}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            dataSource={dataSource}
            id={BOOKSTAT_DATA}
            isFetching={isFetching}
            columns={columns}
            refetchTable={refetchTable}
          />
          <TableFooter>
            <Pagination
              total={count}
              current={page}
              onChange={setPage}
              pageSize={PAGESIZE}
            />
          </TableFooter>
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default React.memo(BookstatData);