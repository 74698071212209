import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';
import { useActions } from '../../../../context/Actions.context';
import { notification } from 'antd';

export const useGetBookDetails = (bookId) => {
  const {
    book: { getBookDetails }
  } = useServices();

  const { source: sourcePath } = useActions();

  const query = useQuery('get-book-details', () => getBookDetails(bookId, sourcePath['source'], 'predictions'), {
    enabled: true,
    cacheTime: 0,
    onError: () => {
      notification.error({
        description: 'Failed to load titles data.',
        message: ''
      });
    }
  });

  const refetchBookDetails = () => {
    query.refetch();
  };

  return { ...query, refetchBookDetails };
};