import { notification } from 'antd';

const showNotification = (
  promise,
  textSuccess: string,
  textError: string,
  title?: string
  // eslint-disable-next-line max-params
) => {
  promise
    .then(res => {
      if (res?.status === 200) {
        notification.success({
          description: title,
          message: textSuccess
        });
      }
    })
    .catch(() => {
      notification.error({
        description: title,
        message: textError
      });
    });
};

export default showNotification;
