import { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { config } from '../../config/env-config';
import { notification, Spin } from 'antd';
import styled from 'styled-components';
import { useAuth } from '../../core/modules/auth/hooks/useAuth.query';

const ErrorContainer = styled.p`
  color: red;
  margin-top: 10px;
`;

const LoginGoogleBtn = styled.div`
  button {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e2e2e2 !important;
    padding: 3px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 10px !important;
    box-shadow: none !important;

    div {
      display: flex;
      align-items: center;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.05em;

      color: #727278;
    }
  }
`;
const GoogleSingIn = () => {
  const { mutate, isLoading, isError } = useAuth();

  const [error, setError] = useState(isError);

  const handleSubmit = responce => {
    mutate(responce.tokenId);
  };

  const setErrorNotification = (message : string, description : string) => {
    notification['error']({
      message,
      description
    });
  };

  const handleError = (e : any) => {
    const errorMessage = e.error;

    switch (errorMessage) {
    case 'popup_closed_by_user':
      setErrorNotification('Login failed.', 'Try again after allowing third party cookies.');
      break;
    default:
      setErrorNotification('Something went wrong.', 'Try clearing your browser cache.');
    }
  };

  useEffect(() => {
    setError(isError);
  }, [isError]);

  return !isLoading ? (
    <LoginGoogleBtn>
      <GoogleLogin
        clientId={config.REACT_APP_GOOGLE_CLIENT_ID || ''}
        onSuccess={handleSubmit}
        onFailure={handleError}
        buttonText='Continue with Google'
        cookiePolicy='single_host_origin'
      />
      {error && <ErrorContainer>Access denied</ErrorContainer>}
    </LoginGoogleBtn>
  ) : (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Spin />
    </div>
  );
};

export default GoogleSingIn;
