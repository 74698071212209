import React from 'react';
import { ReactComponent as SortIcon } from '../../assets/sort-solid.svg';
import cn from 'classnames';

const getClassNamesFor = (name, sort) => {
  if (sort?.sortBy === name) {
    if (sort?.sortType === 'ascending') {
      return 'sort-ascending';
    } else if (sort?.sortType === 'descending') {
      return 'sort-descending';
    }
  }
};

const Sorting = ({ title, id, sorts, setSort }) => {
  const onSort = () => {
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Sort User' });
    let sortBy = '';
    let sortType = '';

    if (sorts?.sortBy === id) {
      sortBy = id;

      if (sorts?.sortType === 'ascending') {
        sortType = 'descending';
      } else {
        sortType = 'ascending';
      }
    } else {
      sortBy = id;
      sortType = 'ascending';
    }

    setSort({ sortBy, sortType });
  };

  return (
    <button className='sort' onClick={onSort}>
      {title}
      <SortIcon
        style={{ minWidth: 10, height: 13, margin: '0 5px' }}
        className={cn('sorting-icon', getClassNamesFor(id, sorts))}
      />
    </button>
  );
};

export default React.memo(Sorting);
