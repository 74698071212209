// import { TechnicalDifficultiesPage } from 'pages/technical-difficulties/technical-difficulties';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const GlobalErrorBoundary: React.FC = ({ children }) => {
  const handleError = (error: Error) => {
    // do smth else here and log error
    console.log(error);
  };

  // TODO create and substitute DefaultFallback with TechnicalDifficultiesPage
  const DefaultFallback = () => <p>Something went wrong</p>;

  return (
    <ErrorBoundary FallbackComponent={DefaultFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
