import { BrowserNames } from '../../../core/constants/global-constants';
import { UserDeviceInfoType } from '../../../core/modules/user/user.model';

const getBrowserInfo = (userAgent: string): string => {
  for (const [name, browser] of Object.entries(BrowserNames)) {
    if (userAgent.includes(name)) {
      return browser;
    }
  }

  return 'Unknown Browser';
};

const getDeviceName = (userAgent: string): string => {
  if (userAgent.includes('Macintosh')) {
    return 'Macintosh';
  } else if (userAgent.includes('Windows')) {
    return 'Windows';
  } else if (userAgent.includes('iPhone')) {
    return 'iPhone';
  } else if (userAgent.includes('Android')) {
    return 'Android';
  }

  return 'Unknown Device';
};

const getUserDeviceInfo = (): UserDeviceInfoType => {
  const userAgent = window.navigator?.userAgent;
  const os_type = window.navigator?.platform;
  const device = getDeviceName(userAgent);
  const browser = getBrowserInfo(userAgent);

  return { os_type, device, browser };
};

export default getUserDeviceInfo;