import { useCallback, useEffect, useState } from 'react';
import LocalStorageService from '../../../core/services/local-storage.service';
import { USER_ACTIVITY_EXPIRY_EXTENSION_MS, USER_ACTIVITY_CHECK_INTERVAL_MS,
  UserEventsList } from '../../../core/constants/global-constants';
import { UserActivityPayloadType } from '../../../core/modules/user/user.model';
import getUserDeviceInfo from './getUserDeviceInfo';
import { useUserActivity } from '../../../context/UserActivity.context';

const storage = new LocalStorageService(window.localStorage);

const getTime = (time): string | null => storage.get(time);
const setTime = (expireTime: number): void => storage.set('expireTime', expireTime.toString());

type UserActivityTrackingProps = {
  id: number;
};

export const useUserActivityTracking = ({ id }: UserActivityTrackingProps) : UserActivityPayloadType => {
  const [isUserActive, setIsUserActive] = useState<boolean>(true);
  const { updateValues } = useUserActivity();
  const values = useUserActivity();

  const handleUpdateActivityContext = useCallback((isExpired) => {
    const currentTab = window.location.pathname.split('/')[1];
    updateValues({
      activity_count: isExpired ? 0 : 1,
      inactivity_count: isExpired ? 1 : 0,
      bookstat: currentTab === 'bookstat' && !isExpired ? 1 : 0,
      'royal-road': currentTab === 'royal-road' && !isExpired ? 1 : 0,
      'daily-rank': currentTab === 'daily-rank' && !isExpired ? 1 : 0
    });
  }, [window.location]);

  const checkForInactivity = useCallback(() => {
    const currentTime = Date.now();
    const expireTime: string | null = getTime('expireTime');
    const isExpired = expireTime && parseInt(expireTime, 10) < currentTime;

    setIsUserActive(!isExpired);

    handleUpdateActivityContext(isExpired);
  }, [getTime('expireTime'), handleUpdateActivityContext]);

  const updateExpiryTime = useCallback(() => {
    const expireTime = Date.now() + USER_ACTIVITY_EXPIRY_EXTENSION_MS;
    setTime(expireTime);
  }, []);

  useEffect(() => {
    updateExpiryTime();

    UserEventsList.forEach((event) => {
      window.addEventListener(event, updateExpiryTime);
    });

    return () => {
      UserEventsList.forEach((event) => {
        window.removeEventListener(event, updateExpiryTime);
      });
    };
  }, [updateExpiryTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, USER_ACTIVITY_CHECK_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  const userTrackingInfo = {
    user_id: id,
    is_user_active: isUserActive,
    activity_count: values.activity_count,
    inactivity_count: values.inactivity_count,
    bookstat: values.bookstat,
    royal_road: values['royal-road'],
    daily_rank: values['daily-rank'],
    ...getUserDeviceInfo()
  };

  return userTrackingInfo;
};