import { Field, useFormik, FormikProvider } from 'formik';
import { Select, Option } from '../../../components/Select';
import { required } from '../../../utils/validation';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { useEditCustomObject } from '../../../core/modules/book/hooks/useEditCustomObject';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 12px;
`;

const EditName = ({ onClose, data, isLoading }) => {
  const editName = useEditCustomObject('name');

  const formik = useFormik({
    initialValues: {
      id: null,
      name: null
    },
    enableReinitialize: true,
    onSubmit: async () => null
  });

  const handleEdit = (values) => {
    editName.mutate({ data: [values] });
    onClose();
  };

  return (
    <FormikProvider value={formik}>
      <div style={{ display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'calc(100% - 80px)' }}>
        <div>
          <FormItem label='Select genre' required error={undefined}>
            <Field name={'id'} validate={required}>
              {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                <Select
                  placeholder='Select...'
                  value={value === '' || value === null ? undefined : value}
                  {...rest}
                  onChange={(v, opt) => {
                    setFieldValue('id', v);
                  }}
                  loading={isLoading}
                  style={{ width: '100%' }}
                >
                  {!isLoading ? data?.data?.data?.map(({ ID, Name }) =>
                    <Option key={ID} value={ID}>
                      {Name}
                    </Option>) : (
                    <Option key='loading' value='loading' disabled={true}>
                        Loading...
                    </Option>
                  )}
                </Select>
              )}
            </Field>
          </FormItem>

          <FormItem label='New name' error={formik.errors?.name} required>
            <Field name={'name'} validate={required}>
              {({ field: { value, onChange, ...rest } }) => (
                <Input
                  value={value}
                  placeholder='Enter new name'
                  disabled={!(formik.values?.id)}
                  onChange={event => {
                    onChange(event);
                  }}
                  {...rest}
                />
              )}
            </Field>
          </FormItem>
        </div>

        <Footer>
          <Button
            onClick={onClose}
          >
          Cancel
          </Button>

          <Button
            onClick={() => handleEdit(formik.values)}
            disabled={Boolean(Object.values(formik.errors)?.length) || !formik.dirty}
            type='primary'
          >
          Edit
          </Button>
        </Footer>
      </div>
    </FormikProvider>
  );
};

export default EditName;