import React from 'react';
import { Modal as ModalComponent } from 'antd';

interface IProps {
  okText?: string;
  title?: string;
  cancelText?: string;
  visible: boolean;
  onOk?: any;
  footer?: any;
  okButtonProps?: { disabled: boolean };
  onCancel: () => void;
  style?: object;
  width?: number | string;
  className?: string;
  confirmLoading?: boolean;
}

const Modal: React.FC<IProps> = ({
  okText,
  title,
  visible,
  onOk,
  style,
  cancelText,
  okButtonProps,
  className,
  confirmLoading = false,
  onCancel,
  width,
  ...props
}) => (
  visible ? <ModalComponent
    okText={okText}
    title={title}
    cancelText={cancelText ?? 'Cancel'}
    visible={visible}
    confirmLoading={confirmLoading}
    onOk={onOk}
    okButtonProps={okButtonProps}
    onCancel={onCancel}
    {...props}
    width={width ?? 520}
    className={className}
    style={style}
  /> : <></>
);

export default Modal;
