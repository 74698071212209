import styled from 'styled-components';

export const WarningInfo = styled.div`
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 12px;
  background: #ffe5e9;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

export const Title = styled.div`
  margin-bottom: 12px;
`;
