import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import UsersDashboard from '../UsersActivityDashboard/UsersDashboard';
import useUserTracking from '../../core/modules/user/hooks/useUserTracking';

const UsersDashboardWrapper = styled.div`
  width: 100%;
`;

const UsersActivityDashboardSection: React.FC = () => {
  const { refetch, isLoading, isFetching } = useUserTracking('fetch-stats-info');
  const [statsData, setStatsData] = useState([]);

  const handleRefreshStatData = useCallback(async () => {
    const { data: stats } = await refetch();
    setStatsData(stats?.data);
  }, [statsData, refetch]);

  useEffect(() => {
    handleRefreshStatData();
  }, []);

  return (
    <UsersDashboardWrapper>
      <UsersDashboard
        isLoading={isLoading}
        isFetching={isFetching}
        statsData={statsData}
        handleRefreshStatData={handleRefreshStatData}
      />
    </UsersDashboardWrapper>
  );
};

export default UsersActivityDashboardSection;