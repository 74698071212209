import React from 'react';
import { Delete, Watch, Exclude, ToCRM, Return } from '../Actions';
import ToPendingExclusion from '../Actions/ToPendingExclusion';
import ToPodiumTitle from '../Actions/ToPodiumTitle';
import { ALL_NEW_TITLE,
  MANUALLY_ADDED,
  PODIUM_TITLES,
  WATCHLIST,
  ALL_TITLES_RR,
  POPULAR,
  WATCHLIST_RR,
  TRENDING,
  RISING_STARS,
  SENT_TO_CRM_RR,
  SEND_TO_CRM,
  ALL_EXCLUDED_RR,
  ALL_EXCLUDED,
  COMPETITORS,
  PODIUM_TITLES_RR } from '../../core/constants';
import { useUserPermit } from '../../context/User.context';
import './styles.css';

interface IOverlayActionProps {
    book: any;
    TAB_ID: string;
    refetchTable: Function;
}

const showPodiumTitleAction = (tabId): boolean => {
  const tabsWithoutAction: string[] = [PODIUM_TITLES, PODIUM_TITLES_RR, POPULAR, RISING_STARS, TRENDING];

  return !tabsWithoutAction.includes(tabId);
};

const OverlayActions: React.FC<IOverlayActionProps> = ({ book, TAB_ID, refetchTable }) => {
  const { edit_titles, move_titles_hubspot } = useUserPermit();
  const isReturnTab = ![ALL_NEW_TITLE, ALL_TITLES_RR, MANUALLY_ADDED,
    POPULAR, RISING_STARS, TRENDING].includes(TAB_ID);
  const isPendingExclusionTab = [ALL_NEW_TITLE, MANUALLY_ADDED, ALL_TITLES_RR].includes(TAB_ID);
  const isExcludeTab = ![ALL_EXCLUDED_RR, ALL_EXCLUDED, ALL_NEW_TITLE, ALL_TITLES_RR,
    MANUALLY_ADDED, PODIUM_TITLES, PODIUM_TITLES_RR, COMPETITORS].includes(TAB_ID);

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      { edit_titles &&
        <div className='actions'>
          <Delete book={book} id={TAB_ID} />
          { isPendingExclusionTab &&
              <ToPendingExclusion
                book={book}
                refetchTable={refetchTable}
                id={TAB_ID}
              /> }
          { isExcludeTab && <Exclude book={book} id={TAB_ID} /> }
          { showPodiumTitleAction(TAB_ID) &&
              <ToPodiumTitle
                book={book}
                refetchTable={refetchTable}
                id={TAB_ID}
              />}
          { TAB_ID !== WATCHLIST && TAB_ID !== WATCHLIST_RR &&
            <Watch book={book} refetchTable={refetchTable} id={TAB_ID} /> }
          { move_titles_hubspot && TAB_ID !== SEND_TO_CRM && TAB_ID !== SENT_TO_CRM_RR &&
            <ToCRM book={book} id={TAB_ID} /> }
          { isReturnTab &&
            <Return book={book} id={TAB_ID} /> }
        </div>
      }
    </div>
  );
};

export default OverlayActions;