import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';

export const useAuth = () => {
  const redirectPath = localStorage.getItem('redirectPath');
  const {
    auth: { singinUser }
  } = useServices();

  const { push } = useHistory();

  const { data, isLoading, isError, mutate } = useMutation(
    Endpoints.authSingIn,
    (token: string) => singinUser(token),
    {
      onSuccess: () => {
        push(redirectPath);
      }
    }
  );

  return {
    data,
    isLoading,
    isError,
    mutate
  };
};
