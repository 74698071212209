/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext, getIn, useField } from 'formik';
import Input from '../../../components/Input';
import { CloseOutlined } from '@ant-design/icons';
import { Footer, ResetBtn, SubmitBtn } from './styles';
import get from 'lodash.get';
import set from 'lodash.set';
import { Typography } from 'antd';

const keys = {
  'AND': {
    oposit: 'OR',
    bgColor: '#FFFFFF',
    borderColor: 'linear-gradient(to right, #03CD8B, #025A3C ) 47% 1%'
  },
  'OR': {
    oposit: 'AND',
    bgColor: '#F5F6F8',
    borderColor: 'linear-gradient(to right, #025A3C, #03CD8B ) 47% 1%'
  }
};

const Wrapper = styled.div`
  overflow: auto;
  height: calc(100% - 32px);
  margin-bottom: 16px;
`;

const Content = styled.div`
  background-color:  #F5F6F8 ;
  padding: 8px;
  border-radius: 10px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Block = styled.div`
  padding: 5px 0 13px 3px;
  position: relative;
  justify-content: space-evenly;
  margin: 3px;
  display: flex;
  background-color:  ${({ keyBlock }) => keys[keyBlock]?.bgColor};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-left: 4px solid;
  width: max-content;
  min-width: -webkit-fill-available;
  border-image:  ${({ keyBlock }) => keys[keyBlock]?.borderColor};
`;

const InputWrapper = styled.div`
  display: grid;
  flex-direction: row;
`;

const InputContainer = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 2%;
`;

const AddButton = styled.button`
  background-color: inherit;
  border: 1px solid #00B279;
  border-radius: 4px;
  color:  #00B279;
  font-size: 12px;
  font-weight: bold;
  &:hover {
      cursor: pointer;
  };
`;

const AddBlockBtn = styled.button`
  border: none;
  color: #00B279;
  background: none;
  font-weight: bold;
  font-size: 10px;
  margin: 0 1px;
  &:hover {
      cursor: pointer;
  }
  &:disabled {
  color:  #d9d9d9;
  cursor: default;
  }
`;

const DeleteBtn = styled.button`
  border: none;
  color: #1b6f54;
  background: none;
  font-weight: bold;
  &:hover {
      cursor: pointer;
  }
`;

function useFormProperty(prefix: string) {
  const { values, setFieldValue, initialValues, setFieldError } = useFormikContext() as any;
  const properties = getIn(
    values,
    prefix
  );

  useEffect(() => {
    if (values?.level === undefined) {
      setFieldValue('level', 0);
    }

    if (values?.level === 0 &&
      (values.id === undefined || values.id === null) &&
      values.search.children[0].hasOwnProperty('text')
    ) {
      setFieldValue('isOrVisible', true);
    } else {
      setFieldValue('isOrVisible', false);
    }
  }, [values]);

  const reset = useCallback(async () => {
    const { search } = initialValues as any;

    await setFieldValue('search', search || {
      key: 'AND',
      children: [{
        text: ''
      }]
    });

    setFieldValue('query', '');
    setFieldValue('id', null);
    setFieldValue('level', 0);
  }, [initialValues]);

  const addNewProperty = useCallback(async (pref, key) => {
    const old = getIn(
      values,
      pref
    );
    const newProperty = {
      key: key === 'AND' ? 'OR' : 'AND',
      children: [old, {
        text: ''
      }]
    };
    await setFieldValue(pref, newProperty);
    setFieldValue('level', values?.level + 1);
  }, [values]);

  const addBlock = useCallback(async (pref) => {
    const old = getIn(
      values,
      pref
    );
    const newValue = [...old, { text: '' }];
    await setFieldValue(pref, newValue);

    if (values?.level === undefined) {
      setFieldValue('level', 1);
    } else {
      setFieldValue('level', values?.level + 1);
    }
  }, [values]);

  // need rename all val
  const removeProperty = useCallback(async (pref, idx) => {
    const val = { ...values };
    const geted = get(val, pref);
    const filtered = geted.filter((e, id) => id !== idx);
    let newSet = set(val, pref, filtered);

    const clearObj = (obj, path) => {
      if (obj?.children?.length) {
        obj.children.forEach((el, id) =>
          el?.hasOwnProperty('text')
            ? null
            : el?.children?.length
              ? clearObj(el, `${path}.children[${id}]`)
              : obj?.children?.splice(id, 1)
        );
      }

      const getVa = get(val, path);
      const filteredVal = getVa?.children.filter((e) => e.children?.length || e?.hasOwnProperty('text'));
      const a = set(newSet, path, { ...getVa, children: filteredVal });
      newSet = a;
    };

    clearObj(newSet?.search, 'search');

    if (newSet?.search?.children?.length) {
      await setFieldValue('search', newSet?.search); }
    else {
      await setFieldValue('search', {
        key: 'AND',
        children: [{
          text: ''
        }]
      });
    }

    if (values?.level > 0) {
      setFieldValue('level', values?.level - 1);
    }
  }, [values, prefix, initialValues]);

  return {
    properties,
    addNewProperty,
    addBlock,
    reset,
    setFieldError,
    removeProperty,
    setFieldValue,
    values
  };
}

const TextInput = ({ name }) => {
  const [field, meta] = useField({ name });

  const errorStyle = meta.error ? {
    borderColor: '#f44336'
  } : {};

  return <Input {...field} style={{ height: 32, ...errorStyle }} placeholder={'Enter keyword...'} />;
};

let str = '';
const query = (obj: object, len: number, key: string) => {
  for (let i = 0; i < len; i++) {
    if (obj[i]?.children !== undefined) {
      if (obj[i - 1] !== undefined && obj[i - 1].hasOwnProperty('children')) {
        str += ` ${key} (`;
      } else {
        str += '(';
      }
      query(
        obj[i]?.children,
        obj[i]?.children.length,
        obj[i]?.key
      );
      str += ')';
    }
    else if (obj[i]?.children === undefined && i > 0) {
      str += ` ${key} ${obj[i]?.text}`;
    }
    else if (obj[i]?.children === undefined && i === 0) {
      if (str.length === 0 && (obj[i + 1]?.text === undefined)) {
        str += `${obj[i].text} ${key} `;
      } else {
        str += obj[i + 1]?.hasOwnProperty('children') ?
          `${obj[i].text} ${key} ` :
          `${obj[i].text}`;
      }
    }
  }

  return str;
};

const FormProperty = ({ prefix = '', keyID }) => {
  const { properties, addNewProperty, addBlock, removeProperty, setFieldValue, values } =
  useFormProperty(prefix);

  const queryGenerator = () => {
    setFieldValue('query',
      query(values?.search.children,
        values?.search.children.length,
        values?.search.key)
    );
    str = '';
  };

  useEffect(() => {
    if (values?.id && values?.id !== null) {
      queryGenerator();
    }
  }, [values?.id]);

  const orkeyID = 'OR';

  return properties?.length ?
    <Block
      keyBlock={keyID}
      onBlur={() => queryGenerator()}
      onClick={() => queryGenerator()}
    >
      {
        <AddButton type='button' onClick={() => {
          if (values?.level === 0 && values.search.children[0].hasOwnProperty('text')) {
            setFieldValue('search', { key: 'AND' });
          }
          addBlock(prefix);
        } } >
          {keyID}
        </AddButton>
      }
      {
        values?.isOrVisible &&
        <AddButton id='orButton' type='button'
          onClick={() => {
            setFieldValue('search', { key: orkeyID });
            addBlock(prefix);
          } }
        >
          {orkeyID}
        </AddButton>
      }

      <InputWrapper>
        {
          properties.map((obj, idx) => obj.hasOwnProperty('text') ?
            <InputContainer key={prefix + idx}>
              <TextInput name={`${prefix}[${idx}].text`} />
              {
                !document.getElementById('orButton') &&
              <AddBlockBtn type='button' onClick={() => addNewProperty(`${prefix}[${idx}]`, keyID)} >
                {keyID === 'AND' ? 'OR' : 'AND'}
              </AddBlockBtn>
              }
              <DeleteBtn type='button' onClick={() => removeProperty(`${prefix}`, idx)}>
                <CloseOutlined style={{ fontSize: 10 }} />
              </DeleteBtn>
            </InputContainer>
            :
            obj?.children?.length ?
              <FormProperty
                key={prefix + idx}
                keyID={obj.key}
                prefix={`${prefix}[${idx}].children`}
              /> : <></>)
        }
      </InputWrapper>
    </Block> : <></>;
};

const Conditions = ({ onSearch, disabled = false, style = {} }) => {
  const { properties, reset, setFieldError, values } = useFormProperty('search');

  const validate = useCallback(async() => {
    let hasError = false;

    const asyncCheck = async (obj, path) => {
      await obj?.children?.forEach(async(el, i) => {
        if (el?.hasOwnProperty('text')) {
          if (!el.text?.trim()) {
            await setFieldError(`${path}.children[${i}].text`, 'This field cannot be empty');
            hasError = true;
          } else {
            await setFieldError(`${path}[${i}].text`, null);
          }
        } else {
          asyncCheck(el, `${path}.children[${i}]`);
        }
      });
    };

    await asyncCheck(properties, 'search');

    return hasError;
  }, [properties, setFieldError]);

  return <Content style={style || {}}>
    <Typography>
      <pre id='queryLabel'
        style={{ 'backgroundColor': '#f5f5f5', 'fontSize': '12px' } }>
          Query: {values?.query}
      </pre>
    </Typography>
    <Wrapper>
      <FormProperty
        keyID={values?.isOrVisible ? 'AND' : properties?.key}
        prefix={'search.children'}
      />
    </Wrapper>
    <Footer>
      <ResetBtn type='button' onClick={reset}>
        Reset
      </ResetBtn>

      <SubmitBtn type='button' onClick={async () => {
        const hasError = await validate();

        if (hasError) {
          return;
        }
        onSearch();
      }}
      disabled={disabled}
      >Search</SubmitBtn>
    </Footer>
  </Content>;
};

export default React.memo(Conditions);