export const columnsAlsoBought =
[{
  title: 'ASIN',
  dataIndex: 'ASIN',
  key: 'ASIN',
  className: 'show',
  width: 100,
  render: (asin: string) => {
    if (asin) {
      return {
        children: <div><a
          className='table-cell-asin'
          href={`https://www.amazon.com/dp/${asin}`}
          rel='noreferrer'
          target='_blank'
        >
          {asin}
        </a></div>
      };
    }
  }
},
{
  'title': 'Title',
  'dataIndex': 'Title',
  'key': 'Title',
  'className': 'show',
  'width': 220,
  render: (text, book) => {
    if (book['Podium Title']) {
      return {
        props: {
          style: { background: 'rgb(196, 170, 234, 0.6)' }
        },
        children: <div>{text || ''}</div>
      };
    }

    return text;
  }
},
{
  'title': () => 'Author',
  'dataIndex': 'Author',
  'key': 'Author',
  className: 'show',
  width: 150,
  render: (text, book) => {
    if (book['Podium Author Id']) {
      return {
        props: {
          style: { background: 'rgba(24, 144, 255, 0.40)' }
        },
        children: <div>{text || ''}</div>
      };
    }

    return text;
  }
},
{
  title: 'Genre',
  dataIndex: 'Genre',
  key: 'Genre',
  className: 'show',
  width: 220
},
{
  title: 'Date Pub',
  dataIndex: 'Date Pub',
  key: 'Date Pub',
  className: 'show wrap-cell',
  width: 100,
  render: text => text?.split(' ')[0]
}
];