import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

const useUserCRM = () => {
  const {
    user: { getUserCRM }
  } = useServices();

  return useQuery('get-crm-user', () => getUserCRM(), {
    refetchInterval: false
  });
};

export default useUserCRM;
