const formatDate = (dateString: string | undefined | null): string | null => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return null;
  }

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString()
    .padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${month}/${day}/${year}`;
};

export default formatDate;