export const ACTIONS_GENRE = {
  delete: 'delete',
  create: 'create',
  edit: 'edit'
};

export const DAILY_RANK_EXPORT_ROWS = 10000;

export const BOXED_SET_CDN_URL = 'https://cdn-icons-png.flaticon.com/512/29/29302.png';

export const feedbackOptions: string[] = [
  'Login/Logout',
  'User Management',
  'Dashboard',
  'Specific Bookstat Tab',
  'Specific Royal Road Tab',
  'Specific Daily Rank Tab',
  'Moving Titles To HubSpot'
];

export const OverlayBookstatDataList = [
  'Current Tab',
  'Date Pub',
  'Added',
  'Days Tracked',
  'ASIN',
  'Genre',
  'Podium Hit Count',
  'Num. of Pages',
  'Projected Sales',
  'Average Projected Sales',
  'Max Projected Sales',
  'Amzn Rating',
  'Amzn Reviews',
  'eBooks Sold'
];

export const OverlayRoyalRoadDataList = [
  'Current Tab',
  'First Published Date',
  'Fiction Id',
  'Added',
  'Patreon',
  'Genre',
  'Pages',
  'Followers',
  'Ratings',
  'Average Views',
  'Views',
  'Type',
  'Status',
  'Overall Score'
];

export const BrowserNames = {
  Chrome: 'Google Chrome',
  Safari: 'Safari',
  Firefox: 'Mozilla Firefox',
  Edge: 'Microsoft Edge',
  Opera: 'Opera',
  Vivaldi: 'Vivaldi',
  Brave: 'Brave'
};

export const UserEventsList = ['click', 'scroll', 'mousemove', 'keypress'];
export const USER_ACTIVITY_EXPIRY_EXTENSION_MS = 120000;
export const USER_ACTIVITY_CHECK_INTERVAL_MS = 60000;