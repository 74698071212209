const toggleColor = ASIN => {
  const element = document.querySelector(
    `[data-row-key="${ASIN}"]`
  ) as HTMLElement;

  if (element) {
    const { background } = element.style;

    element.style.background =
      background === 'rgb(255, 247, 247)' ? 'white' : 'rgb(255, 247, 247)';
  }
};

export default toggleColor;
