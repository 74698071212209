import { AppleOutlined, AndroidOutlined, WindowsOutlined, QqOutlined } from '@ant-design/icons';
import { formatDuration, getPercentDuration } from '../Common/utils/getDate';
import { USER_ACTIVITY_CHECK_INTERVAL_MS,
  USER_ACTIVITY_EXPIRY_EXTENSION_MS } from '../../core/constants/global-constants';

const osIcon = (os_type: string) => {
  const os_name = os_type.toLowerCase();

  if (os_name.includes('ios') || os_name.includes('mac')) {
    return <AppleOutlined style={{ fontSize: '13px', color: 'gray' }} />;
  } else if (os_name.includes('android')) {
    return <AndroidOutlined style={{ fontSize: '13px', color: 'rgb(0, 178, 123)' }} />;
  } else if (os_name.includes('windows')) {
    return <WindowsOutlined style={{ fontSize: '13px', color: '#1890ff' }} />;
  } else if (os_name.includes('linux')) {
    return <QqOutlined style={{ fontSize: '13px', color: '#1890ff' }} />;
  }

  return <></>;
};

const activityTableColumns = () => [
  {
    title: 'Name',
    dataIndex: 'first_name',
    key: 'first_name',
    width: 120,
    render: (first_name: string, stats) => {
      if (first_name) {
        return {
          children: (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              {`${first_name} ${stats?.last_name ?? ''}`}
            </div>
          )
        };
      }
    }
  },
  {
    title: 'Date',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 100
  },
  {
    title: 'First Activity',
    dataIndex: 'first_activity_time',
    key: 'first_activity_time',
    width: 150
  },
  {
    title: 'Last Activity',
    dataIndex: 'last_activity_time',
    key: 'last_activity_time',
    width: 150
  },
  {
    title: 'Active Time',
    dataIndex: 'activity_count',
    key: 'activity_count',
    width: 100,
    render: (time) => {
      const durationInSeconds = (USER_ACTIVITY_CHECK_INTERVAL_MS / 1000) * parseInt(time, 10);

      if (!isNaN(durationInSeconds)) {
        const formattedDuration = formatDuration(durationInSeconds);

        return {
          children: <div>
            {formattedDuration}
          </div>
        };
      }
    }
  },
  {
    title: 'Idle Time',
    dataIndex: 'inactivity_count',
    key: 'inactivity_count',
    width: 100,
    render: (time) => {
      const durationInSeconds = (USER_ACTIVITY_EXPIRY_EXTENSION_MS / 1000) * parseInt(time, 10);

      if (!isNaN(durationInSeconds)) {
        const formattedDuration = formatDuration(durationInSeconds);

        return {
          children: <div>
            {formattedDuration}
          </div>
        };
      }
    }
  },
  {
    title: 'Bookstat',
    dataIndex: 'bookstat',
    key: 'bookstat',
    width: 100,
    render: (activity, stats) => {
      const percent = getPercentDuration(activity, stats.activity_count);

      return {
        children: <div>
          {percent} %
        </div>
      };
    }
  },
  {
    title: 'Royal Road',
    dataIndex: 'royal_road',
    key: 'royal_road',
    width: 100,
    render: (activity, stats) => {
      const percent = getPercentDuration(activity, stats.activity_count);

      return {
        children: <div>
          {percent} %
        </div>
      };
    }
  },
  {
    title: 'Daily Rank',
    dataIndex: 'daily_rank',
    key: 'daily_rank',
    width: 100,
    render: (activity, stats) => {
      const percent = getPercentDuration(activity, stats.activity_count);

      return {
        children: <div>
          {percent} %
        </div>
      };
    }
  },
  {
    title: 'OS Type',
    dataIndex: 'os_type',
    key: 'os_type',
    width: 120,
    render: (os_type: string) => {
      if (os_type) {
        return {
          children: <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            {os_type}
            {osIcon(os_type)}
          </div>
        };
      }
    }
  },
  {
    title: 'Browser',
    dataIndex: 'browser',
    key: 'browser',
    width: 100,
    render: (browser: string) => {
      if (browser) {
        return {
          children: <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            {browser}
          </div>
        };
      }
    }
  },
  {
    title: 'Device',
    dataIndex: 'device',
    key: 'device',
    width: 100
  }
];

export default activityTableColumns;