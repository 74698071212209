import styled from 'styled-components';
import { Popover } from 'antd';
import { InfoCircleOutlined, FireTwoTone, StarTwoTone } from '@ant-design/icons';
import { BOXED_SET_CDN_URL } from '../core/constants/global-constants';

const Wrapper = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

const Label = styled.label`
  color: #383838;
  display: block;
`;

const ErrorDiv = styled.p`
  color: red;
  font-size: 11px;
`;

const Required = styled.span`
  color: red;
`;

const Info = styled.span`
  margin: 0 8px;
`;

export const Hint = ({ hint, color }) => (
  <Popover content={<p>{hint}</p>}>
    <Info><InfoCircleOutlined style={{ color, fontSize: '15px' }}/></Info>
  </Popover>
);

export const BoxedSetTag = ({ hint }) => (
  <Popover content={<p>{hint}</p>}>
    <Info><img src={BOXED_SET_CDN_URL} alt='Book Set' style={{ width: '15px', height: '15px' }} /></Info>
  </Popover>
);

export const LateBloomerTag = ({ hint, color }) => (
  <Popover content={<p>{hint}</p>}>
    <Info><FireTwoTone twoToneColor={color} style={{ fontSize: '15px', marginBottom: '8px' }}/></Info>
  </Popover>
);

export const RisingStar = ({ hint, color }) => (
  <Popover content={<p>{hint}</p>}>
    <Info><StarTwoTone twoToneColor={color} style={{ fontSize: '15px' }}/></Info>
  </Popover>
);

const FormItem = ({ label, error, required, children, hint = '' }) => (
  <Wrapper>
    <Label>
      {label}
      {required && <Required>*</Required>}
      {hint && <Hint hint={hint} color={'#757575'} />}
    </Label>
    {children}
    <ErrorDiv>{error}</ErrorDiv>
  </Wrapper>
);

export default FormItem;