import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { UndoOutlined, LoadingOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Spinner } from '../../components/Spinner';
import styled from 'styled-components';
import Table from '../../components/Table';
import activityTableColumns from './activityTableColumns';
import UsersCharts from './UsersCharts';
import Chrome from '../../assets/icons/Chrome.svg';
import Safari from '../../assets/icons/Safari.svg';
import Firefox from '../../assets/icons/Firefox.svg';
import MSWindows from '../../assets/icons/MSWindows.svg';
import MacOS from '../../assets/icons/MacOS.svg';
import Android from '../../assets/icons/Android.svg';
import Edge from '../../assets/icons/Edge.svg';
import iOS from '../../assets/icons/IOS.svg';
import { Popover } from 'antd';

const otherIcon = (lable: string) => (
  <Popover content={lable} placement='top'>
    <QuestionCircleTwoTone twoToneColor={'gray'} style={{ fontSize: '50px', margin: '8px' }}/>
  </Popover>);

const BrowserIcons = {
  'Google Chrome': <img src={Chrome} alt='Chrome' width={100}/>,
  Safari: <img src={Safari} alt='Safari' width={100}/>,
  'Mozilla Firefox': <img src={Firefox} alt='Firefox' width={100}/>,
  Edge: <img src={Edge} alt='Edge' width={100}/>,
  Others: otherIcon('Other Browsers')
};

const OSIcons = {
  Windows: <img src={MSWindows} alt='Windows' width={100}/>,
  MacIntel: <img src={MacOS} alt='Mac' width={100}/>,
  iOS: <img src={iOS} alt='iOS' width={100}/>,
  Android: <img src={Android} alt='Android' width={100}/>,
  Others: otherIcon('Other OS')
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 13px;
`;

const Header = styled.p`
  font-weight: 600;
  margin: 20px 10px;
`;

const StyledRefresh = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #9c9a9a;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }
  &:hover {
    color: #00b27b;
  }
`;

type deviceType = {
  [key: string]: number
};

type UserStatsType = {
  id: number;
  created_date: string;
  user_id: number;
  first_activity_time: string;
  last_activity_time: string;
  activity_count: number;
  inactivity_count: number;
  bookstat: number;
  royal_road: number;
  daily_rank: number;
  os_type: string;
  device: string;
  browser: string;
  first_name: string;
};

type UsersDashboardProps = {
  statsData: UserStatsType[];
  isLoading: boolean;
  isFetching: boolean;
  handleRefreshStatData: () => void;
};

const UsersDashboard: React.FC<UsersDashboardProps> = ({ statsData, isLoading, isFetching, handleRefreshStatData }) => {
  const [browserCounts, setBrowserCounts] = useState<deviceType>({});
  const [osCounts, setOsCounts] = useState<deviceType>({});
  const [charts, setCharts] = useState<JSX.Element>(<></>);

  const updateOSBrowserCounts = useCallback(() => {
    const browser_count: deviceType = {};
    const os_count: deviceType = {};
    statsData.forEach(user => {
      const browser = user['browser'] || 'Unknown';
      browser_count[browser] = (browser_count[browser] || 0) + 1;

      const os = user['os_type'] || 'Unknown';
      os_count[os] = (os_count[os] || 0) + 1;
    });
    setBrowserCounts(browser_count);
    setOsCounts(os_count);
    setCharts(<
      UsersCharts
      data={statsData}
    />);
  }, [statsData]);

  useEffect(() => {
    if (statsData?.length > 0) {
      updateOSBrowserCounts();
    }
  }, [statsData]);

  const columns = useMemo(() => activityTableColumns(), []);

  const renderIcons = (iconMap, counts) => Object.keys(counts).map(key => (
    <div key={key} className='user-stats-icon-wrapper'>
      <div>{iconMap[key] || iconMap['Others']}</div>
      <div>{counts[key]} {counts[key] === 1 ? 'user' : 'users'}</div>
    </div>
  ));

  return (
    <Wrapper>
      <div className='user-activity-header'>
        <Header>Brenda Users Activity Stats</Header>
        {
          isFetching ? (
            <Spinner
              size='small'
              spinning={isFetching}
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 20, color: '#00b27b' }}
                  spin
                />
              }/>
          ) : (
            <Popover content={<p>Refresh Stats</p>} placement='left'>
              <StyledRefresh onClick={handleRefreshStatData}>
                <UndoOutlined />
              </StyledRefresh>
            </Popover>
          )
        }
      </div>
      <div className='user-stats-icon-wrapper'>
        <div className='user-stats-icons'>
          {renderIcons(BrowserIcons, browserCounts)}
          {renderIcons(OSIcons, osCounts)}
        </div>
      </div>
      {charts}
      {
        <Table
          bordered
          dataSource={statsData}
          pagination={true}
          columns={columns}
          loading={isLoading || isFetching}
        />
      }
    </Wrapper>
  );
};

export default UsersDashboard;