import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header, SpinnerTabs } from '../../composed-components';
import { Routes, SOURCE } from '../../core/constants';
import { UserProvider } from '../../context/User.context';
import { useUser } from '../../core/modules/user/hooks';
import UsersSection from '../../section/Users';
import BooksSectionWrapper from '../../section/Bookstat/BooksSectionWrapper';
import DashboardSection from '../../section/Dashboard/Dashboard.section';
import RoyalRoadSectionWrapper from '../../section/Royalroad/RoyalRoadSectionWrapper';
import DailyrankSectionWrapper from '../../section/Dailyrank/DailyrankSectionWrapper';
import { useBrendaSearches } from '../../core/modules/book/hooks/useBrendaSearches';
import { useUserActivityTracking } from '../../section/Common/utils/userActivityTracking';
import { UserActivityPayloadType } from '../../core/modules/user/user.model';
import { USER_ACTIVITY_CHECK_INTERVAL_MS } from '../../core/constants/global-constants';
import useUserTracking from '../../core/modules/user/hooks/useUserTracking';
import UsersActivityDashboardSection from '../../section/UsersActivityDashboard/UsersDashboard.section';

const Content = styled.main`
  padding: 24px 32px;
`;

const LoaderDiv = styled.main`
  display: flex;
  height: 100vh;
  align-items: center;
`;

const AdminPage: React.FC = ():JSX.Element => {
  const source = SOURCE[window.location.pathname.split('/')[1]];
  const { data, isLoading } = useUser();
  const { mutate: getBrendaSearches, data: searchData } = useBrendaSearches('get');

  const userStats: UserActivityPayloadType = useUserActivityTracking({ id: data?.id });
  const { refetch } = useUserTracking('save-event-info', userStats);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (data?.id && Object.values(SOURCE).includes(source)) {
        refetch();
      }
    }, USER_ACTIVITY_CHECK_INTERVAL_MS);

    return () => {
      clearInterval(intervalId);
      localStorage.removeItem('expireTime');
    };
  }, [data?.id, source, refetch]);

  useEffect(() => {
    if (data && data.id) {
      getBrendaSearches({
        user_id: data.id
      });
    }
  }, [data]);

  const userData = {
    ...data,
    searchData
  };

  return !isLoading ? (
    <UserProvider data={userData}>
      <Header user={userData} />
      <Content>
        <Switch>
          <Route path={Routes.dashboard}>
            <DashboardSection />
          </Route>
          <Route path={Routes.usersAdminDashboard}>
            <UsersActivityDashboardSection />
          </Route>
          <Route path={Routes.bookstat}>
            <BooksSectionWrapper source={source} />
          </Route>
          <Route path={Routes.royalRoad}>
            <RoyalRoadSectionWrapper source={source} />
          </Route>
          <Route path={Routes.dailyRank}>
            <DailyrankSectionWrapper source={source} />
          </Route>
          <Route path={Routes.users}>
            <UsersSection />
          </Route>
          <Redirect
            to={{ pathname: Routes.allNewTitle }}
          />
        </Switch>
      </Content>
    </UserProvider>
  ) : (
    <LoaderDiv>
      <SpinnerTabs isLoading={isLoading} />
    </LoaderDiv>
  );
};

export default AdminPage;