import React, { useEffect, useState, useMemo } from 'react';
import { useBooksList, useDataRowCount } from '../../../core/modules/book/hooks';
import { PODIUM_SALES } from '../../../core/constants';
import { BASEFONTSIZE, COLUMNSHEADING, PAGESIZE, REFETCHDELAY, RefetchOptions } from '../constants';
import ResizeTable from '../../../composed-components/Resize/Resize';
import { EFontSize, TColumns } from '../types/types';
import { FilterForm, Settings, Sorting, SpinnerTabs } from '../../../composed-components';
import { useFilter } from '../../../context/Filter.context';
import { useSort } from '../../../context/Sort.context';
import Chart from '../../../composed-components/Actions/Chart';
import Pagination from '../../../components/Pagination';
import getAllColumns from '../columns/getAllColumns';
import { useUserPermit } from '../../../context/User.context';
import AlsoBought from '../../../composed-components/AlsoBought';
import getDataSource from '../utils/getDataSource';
import { useActions } from '../../../context/Actions.context';

const PodiumSales = () => {
  const { edit_titles } = useUserPermit();
  const [fontSize, setFontSize] = useState<keyof typeof EFontSize>(BASEFONTSIZE);
  const [page, setPage] = useState<number>(1);
  const { isRefreshed } = useActions();
  const { filter } = useFilter();
  const { sort } = useSort();
  const changeFontSize = size => setFontSize(size);
  const changePage = p => setPage(p);

  const { data = [], isLoading, isFetching, refetch } = useBooksList(
    PODIUM_SALES, {
      page,
      ...filter[PODIUM_SALES],
      ...sort[PODIUM_SALES]
    });

  const { count, refetch: refetchRowCount } = useDataRowCount(PODIUM_SALES, { ...filter[PODIUM_SALES] });

  const refetchTable = () => {
    setTimeout(() => {
      refetch();
      refetchRowCount();
    }, REFETCHDELAY);
  };

  const sortColumns = (title: string) => (
    <Sorting id={PODIUM_SALES} title={title} changePage={changePage} />
  );

  const thead = useMemo(() => {
    const allColumns = getAllColumns(sortColumns);
    const head = COLUMNSHEADING[PODIUM_SALES].map(title => {
      if (title === 'Podium Hit Count') {
        return {
          ...allColumns[title],
          render(text, book) {
            const result = <AlsoBought podiumHitCount={text === null ? -1 : text} asin={book.key}/>;

            return {
              children: result
            };
          }
        };
      }
      return allColumns[title];
    });

    if (edit_titles) {
      head.push({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        className: 'show td-actions',
        fixed: 'right',
        width: 90,
        render: (_, book) => (
          <div className='actions'>
            <Chart book={book} />
          </div>
        )
      });
    }

    return head;
  }, [edit_titles, sortColumns]);

  const [columns, setColumns] = useState<TColumns[]>(thead);
  const dataSource = getDataSource(data);

  useEffect(() => {
    if (!isRefreshed) {
      refetch(RefetchOptions);
      refetchRowCount(RefetchOptions);
    }
  }, [page, filter[PODIUM_SALES], sort[PODIUM_SALES]]);

  return (
    <>
      <div className={'wrapper-controller'}>
        <FilterForm id={PODIUM_SALES} page={page} changePage={changePage} />

        <Settings
          thead={thead}
          id={PODIUM_SALES}
          refetchTable={refetchTable}
          changeFontSize={changeFontSize}
          setColumns={setColumns}
        />
      </div>
      {!isLoading ? (
        <>
          <ResizeTable
            fontSize={fontSize}
            id={PODIUM_SALES}
            dataSource={dataSource}
            isFetching={isFetching}
            columns={columns}
            refetchTable={refetchTable}
          />
          <Pagination
            total={count}
            current={page}
            onChange={setPage}
            pageSize={PAGESIZE}
          />
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </>
  );
};

export default React.memo(PodiumSales);