import React, { useMemo } from 'react';
import Table from '../../components/Table';
import SpinnerTabs from '../SpinnerTabs';
import { WrapperTable } from '../CustomObject/Genre/styles';
import { rankingTableColumns } from './rankingTableColumns';

const RankingsTable = ({ data, isLoading, isFetching }) => {
  const dataSource = useMemo(() => data || [], [data]);
  const date_regex = /^\d{4}-\d{2}-\d{2}$/;

  const date_head = data && data.length > 0 ? Object.keys(dataSource[0])
    .filter(date => date_regex.exec(date))
    .reverse() : [];

  rankingTableColumns.splice(3);

  date_head.forEach((d) => rankingTableColumns.push(
    {
      title: `${d}`,
      dataIndex: `${d}`,
      key: `${d}`,
      className: 'show',
      width: 100,
      render: text => <span>{text ?? ''}</span>,
      fixed: ''
    }
  ));

  return (
    <WrapperTable>
      {!isFetching ? (
        <div>
          <Table
            bordered
            dataSource={dataSource}
            pagination={true}
            columns={rankingTableColumns}
          />
        </div>
      ) : (
        <SpinnerTabs isLoading={isLoading || isFetching} />
      )}
    </WrapperTable>
  );
};

export default React.memo(RankingsTable);