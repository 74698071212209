import React, { useContext, useState } from 'react';

type TFilterItem = {
  amazonasin: string[];
  author: string[];
  genres: string[];
  search: string;
  title: string[];
  tag: string[];
  not_tag: string[];
  seriestitle: string[];
  days_tracked: any;
  reputation: string[];
  status_tag: any;
  type_tag: any;
  author_tags: string[];
  author_name: string[];
  warning_tags: string[];
  not_warning_tags: string[];
  fiction_tags: string[];
  fiction_name: string[];
  genre_categories: string[];
  series: string[];
  date_range: any;
  late_bloomer: any;
};

type TFilters = {
  allnewtitles: TFilterItem;
  allexcluded: TFilterItem;
  bookstatdata: TFilterItem;
  competitors: TFilterItem;
  pendingexclusion: TFilterItem;
  podiumsales: TFilterItem;
  podiumtitles: TFilterItem;
  sendtocrm: TFilterItem;
  watchlist: TFilterItem;
  manuallyadded: TFilterItem;
  popular: TFilterItem;
  trending: TFilterItem;
  authors: TFilterItem;
  podiumAuthors: TFilterItem;
  risingStars: TFilterItem;
  alltitlesRR: TFilterItem;
  pendingExclusionRR: TFilterItem;
  excludedRR: TFilterItem;
  watchlistRR: TFilterItem;
  podiumtitlesRR: TFilterItem;
  senttocrmRR: TFilterItem;
  dailyRankEbooks: TFilterItem;
  dailyRankAudioBooks: TFilterItem;
  dailyRankCatalogue: TFilterItem;
};

type TCRMFilter = {
  createDeal?: boolean;
  priority?: string;
  tier?: number;
  assignee?: string;
};

const baseFilter: TFilterItem = {
  amazonasin: [],
  author: [],
  genres: [],
  search: '',
  title: [],
  tag: [],
  seriestitle: [],
  not_tag: [],
  days_tracked: undefined,
  status_tag: undefined,
  type_tag: undefined,
  reputation: [],
  author_tags: [],
  author_name: [],
  fiction_tags: [],
  fiction_name: [],
  warning_tags: [],
  not_warning_tags: [],
  genre_categories: [],
  date_range: undefined,
  late_bloomer: undefined,
  series: []
};

const CRMFilter: TCRMFilter = {
  createDeal: undefined,
  priority: undefined,
  tier: undefined,
  assignee: undefined
};

export const defaultFilter: TFilters = {
  allnewtitles: { ...baseFilter, ...CRMFilter },
  allexcluded: { ...baseFilter },
  manuallyadded: { ...baseFilter },
  bookstatdata: { ...baseFilter },
  competitors: { ...baseFilter },
  pendingexclusion: { ...baseFilter },
  podiumsales: { ...baseFilter },
  podiumtitles: { ...baseFilter },
  sendtocrm: { ...baseFilter },
  watchlist: { ...baseFilter, ...CRMFilter },
  popular: { ...baseFilter },
  trending: { ...baseFilter },
  risingStars: { ...baseFilter },
  authors: { ...baseFilter },
  podiumAuthors: { ...baseFilter },
  alltitlesRR: { ...baseFilter },
  pendingExclusionRR: { ...baseFilter },
  excludedRR: { ...baseFilter },
  watchlistRR: { ...baseFilter },
  podiumtitlesRR: { ...baseFilter },
  senttocrmRR: { ...baseFilter },
  dailyRankEbooks: { ...baseFilter },
  dailyRankAudioBooks: { ...baseFilter },
  dailyRankCatalogue: { ...baseFilter }
};

const FilterContext = React.createContext({
  filter: defaultFilter,
  setFilter: values => console.log(values)
});

export const FilterProvider = ({ children, filter }) => {
  const [currentfilter, setCurrentFilter] = useState(filter || defaultFilter);

  const saveFilter = values => {
    setCurrentFilter(values);
    return null;
  };

  return (
    <FilterContext.Provider
      value={{ filter: currentfilter, setFilter: saveFilter }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const FilterConsumer = FilterContext.Consumer;

export const useFilter = () => {
  const context = useContext(FilterContext);

  return context;
};

export default FilterContext;
