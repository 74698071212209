import React from 'react';
import { Spin } from 'antd';
import cn from 'classnames';
import styled from 'styled-components';

interface IProps {
  spinning: boolean;
  className?: string;
  indicator: JSX.Element;
  size: 'large' | 'small' | 'default' | undefined;
}

const CustomSpin = styled(Spin)`
  color: green;
`;

export const Spinner: React.FC<IProps> = ({ className, size, ...props }) => (
  <CustomSpin size={size} {...props} className={cn(className)} />
);
