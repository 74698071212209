import { notification } from 'antd';
import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';
import { ICustomObjectKeywordsBody, ICustomObjectNameBody, ICustomObjectParentNameBody } from '../book.dto';

export const useEditCustomObject = (objectName) => {
  const {
    book: { editCustomObject }
  } = useServices();

  return useMutation(['edit-custom-object', objectName],
    (params: ICustomObjectNameBody | ICustomObjectKeywordsBody | ICustomObjectParentNameBody) =>
      editCustomObject(objectName, params), {
      onSuccess: (res) => {
        if (res?.data?.success) {
          notification.success({
            description: 'Successfully updated',
            message: ' '
          });
          return;
        }
        notification.error({
          description: 'Failed',
          message: ' '
        });
      },
      onError: () => {
        notification.error({
          description: 'Failed',
          message: ' '
        });
      }
    });
};