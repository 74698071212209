import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IGetBook } from '../../../core/modules/book/book.model';
import { COLUMNSHEADING } from '../constants';
import { getDate } from './getDate';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getResult = (tags1, tags2) => {
  if (tags1 === '') {
    if (tags2 === '') {
      return '';
    } else {
      return tags2;
    }
  } else if (tags2 === '') {
    if (tags1 === '') {
      return '';
    } else {
      return tags1;
    }
  } else {
    return `${tags1},\n${tags2}`;
  }
};

const generatePDF = (
  data: IGetBook[],
  id: string,
  chekedColumns: CheckboxValueType[],
  type: string
  // eslint-disable-next-line max-params
) => {
  const headers = COLUMNSHEADING[id].reduce((acc, name) => {
    if (!chekedColumns.includes(name) && (name !== 'More')) {
      acc.push({ text: name, style: 'tableHeader', alignment: 'center' });
    }

    return acc;
  }, [] as { text; style; alignment }[]);

  const date = getDate();
  const date_regex = /^\d{4}-\d{2}-\d{2}$/;

  if (window.location.pathname.includes('daily-rank')) {
    headers.unshift({ text: 'Title', style: 'tableHeader', alignment: 'center' });
    const date_head = data.length > 0 ? Object.keys(data[0])
      .filter(d => date_regex.exec(d))
      .reverse()
      .slice(0, 16) : [];

    date_head.forEach((name) => headers.push(
      { text: name, style: 'tableHeader', alignment: 'center' }
    ));
  }

  const columns = data.map(book =>
    headers.map(({ text }) => {
      if (text === 'Tags') {
        const tags1 = book['Primary Tags']?.split(',').join(',\n') ?? '';
        const tags2 = book['Secondary Tags']?.split(',').join(',\n') ?? '';
        const result = getResult(tags1, tags2);

        return {
          text: result,
          fontSize: 7
        };
      }

      if (text === 'First 12 months sales (units)') {
        const splitData = book['First Year Audiobooks Sold']?.split(',') ?? '';
        const value = splitData ? splitData[splitData?.length - 1] : '-';

        return {
          text: value,
          fontSize: 7
        };
      }

      if (text === 'Trailing 12 months sales (units)') {
        const splitData = book['Trailing Year Audiobooks Sold']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';

        return {
          text: value,
          fontSize: 7
        };
      }

      if (text === 'Lifetime sales (units)') {
        const splitData = book['Lifetime Audiobooks Sold']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';

        return {
          text: value,
          fontSize: 7
        };
      }

      if (text === 'Lifetime sales (dollars)') {
        const splitData = book['Lifetime Sales']?.split(',');
        const value = splitData ? splitData[splitData?.length - 1] : '-';

        return {
          text: value,
          fontSize: 7
        };
      }

      if (text === 'Kindle Unlimited') {
        return { text: book[text] ? 'Yes' : 'No', fontSize: 7 };
      }

      if (text === 'Audible Product ID') {
        return { text: book['Podium Id'], fontSize: 7 };
      }

      if (text === 'Series') {
        return { text: book['Series'] || '', fontSize: 7 };
      }

      if (text.match(date_regex)) {
        return { text: book[text] || '-', fontSize: 7 };
      }

      return { text: book[text] || 0, fontSize: 7 };
    })
  );

  const widths: Array<string | number> = [];
  widths.length = columns[0].length;

  if (headers?.length > 21) {
    widths.fill('4%');
  } else if (headers?.length > 17) {
    widths.fill('5%');
  } else if (headers?.length > 13) {
    widths.fill('6%');
  } else {
    widths.fill('auto');
  }

  const docInfo = {
    info: {
      title: `report_${date}`,
      author: 'Brenda',
      subject: 'Titles'
    },
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [10, 10, 10, 10],

    header(currentPage, pageCount) {
      return {
        text: `${currentPage.toString()}/${pageCount}`,
        alignment: 'right',
        margin: [0, 0, 0, 0]
      };
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths,
          body: [headers, ...columns]
        }
      }
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 7,
        color: 'black'
      }
    }
  };

  pdfMake.createPdf(docInfo).download(`report_${date}.${type}`);
};

export default generatePDF;