import { AutoComplete, Form, Modal, Popover, Select } from 'antd';
import { useState, useCallback } from 'react';
import RankingsTable from './RankingsTable';
import { useQueryClient } from 'react-query';
import { MoreOutlined } from '@ant-design/icons';
import { useCategoryRankings } from '../../core/modules/book/hooks/useCategoryRankings';
import debounce from 'debounce';

const RankingsTableModal = ({ asin }) => {
  const [days, setDays] = useState<number | undefined>();
  const [open, setOpen] = useState(false);
  const { data, isLoading, isFetching, refetch } = useCategoryRankings(asin, days);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleSearch = useCallback(() => {
    refetch();
  }, []);

  const showRankingsTable = () => {
    handleSearch();
    setOpen(true);
  };

  const handleCancel = () => {
    queryClient.cancelQueries({ queryKey: ['get/category-rankings'] });
    setOpen(false);
    form.resetFields();
  };

  const onSearch = debounce((value: number | undefined) => {
    setDays(value);
    refetch();
  }, 2000);

  const onFormChange = (e) => {
    form.setFieldsValue(e.days);
    onSearch(e.days);
  };

  const FilterDateRange = () =>
    <Form
      form={form}
      className='filter'
      onKeyPress={e => {
        if (e.code === 'Enter') {
          e.preventDefault();
        }
      }}
      onValuesChange={(e) => onFormChange(e)}
    >
      <Form.Item name={'days'}>
        <AutoComplete
          className='filter-select'
          placeholder='Date Range'
          showArrow
          allowClear
        >
          {
            [...Array(11)].map((_, i) => i * 30 + 30).map((val, key) =>
              <Option value={val} key={key}>{val} days</Option>)
          }
          <Option value={365}>365 days</Option>
        </AutoComplete>
      </Form.Item>
    </Form>;

  return (
    <>
      <Popover
        content={<div className='popover-content'>Daily Category Ranks Table</div>}
        trigger='hover'
        placement='right'
      >
        <MoreOutlined
          onClick={showRankingsTable}
          className='actions-icons actions-icons-rankings'
        />
      </Popover>

      <Modal
        title='Daily Rank Check: Category Rankings'
        okText='Ok'
        visible={open}
        className={'category-rankings'}
        onCancel={handleCancel}
        width={'90%'}
        footer={true}
      >
        <div style={{ margin: '10px' }}>
          <FilterDateRange />
        </div>
        <RankingsTable
          isLoading={isLoading}
          isFetching={isFetching}
          data={data}
        />
      </Modal>
    </>
  );
};

export default RankingsTableModal;