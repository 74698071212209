import { Pie, Column } from '@ant-design/plots';
import { useCallback } from 'react';

type TransformedData = {
  first_name: string,
  type: string,
  value: number
};

const UsersCharts = ({ data }) => {
  const transformData = useCallback((): TransformedData[] => {
    const transformedData: TransformedData[] = [];

    for (const user of data) {
      transformedData.push({
        first_name: user.first_name,
        type: 'Inactivity',
        value: user.inactivity_count
      });
      transformedData.push({
        first_name: user.first_name,
        type: 'Activity',
        value: user.activity_count
      });
    }

    return transformedData;
  }, [data]);

  const transformedData = transformData();

  const chartSize = {
    width: 320,
    height: 320
  };

  const baseConfig = {
    data,
    colorField: 'first_name',
    radius: 0.6,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{percentage}',
      style: {
        textAlign: 'center',
        fontSize: 8
      }
    },
    interactions: [
      {
        type: 'element-active'
      },
      {
        type: 'pie-statistic-active'
      }
    ],
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontSize: '10px'
        },
        customHtml: () => 'Tab'
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '10px'
        }
      }
    },
    ...chartSize
  };

  const bookstatConfig = {
    ...baseConfig,
    angleField: 'bookstat',
    statistic: {
      ...baseConfig.statistic,
      content: {
        ...baseConfig.statistic.content,
        customHtml: () => 'Bookstat'
      }
    }
  };

  const royalRoadConfig = {
    ...baseConfig,
    angleField: 'royal_road',
    statistic: {
      ...baseConfig.statistic,
      content: {
        ...baseConfig.statistic.content,
        customHtml: () => 'Royal Road'
      }
    }
  };

  const dailyRankConfig = {
    ...baseConfig,
    angleField: 'daily_rank',
    statistic: {
      ...baseConfig.statistic,
      content: {
        ...baseConfig.statistic.content,
        customHtml: () => 'Daily Rank'
      }
    }
  };

  const activityConfig = {
    data: transformedData,
    isGroup: true,
    xField: 'first_name',
    yField: 'value',
    seriesField: 'type',
    color: ['#C7C7C7', '#5FB918'],
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.type}`,
        value: `${datum.value} min`
      })
    },
    label: {
      position: 'top',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    } as any
  };

  const renderPieCharts = () => (
    <div className='user-piecharts-container'>
      <div className='chart-container'>
        <Pie {...bookstatConfig} />
      </div>
      <div className='chart-container'>
        <Pie {...royalRoadConfig} />
      </div>
      <div className='chart-container'>
        <Pie {...dailyRankConfig} />
      </div>
    </div>);

  return (
    <div>
      {renderPieCharts()}
      <div className='users-column-graph-container'>
        <Column {...activityConfig} />
      </div>
    </div>
  );
};

export default UsersCharts;