import { useQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import { useServices } from '../../../services/useServices';

// eslint-disable-next-line max-params
export const useFiltersTags = (endpoint, id, enabled) => {
  const {
    book: { getFilter }
  } = useServices();

  const { source: sourcePath } = useActions();

  return useQuery([endpoint + id], () => getFilter(endpoint, id, null, '', sourcePath['source']), {
    enabled
  });
};
