import React, { useMemo } from 'react';
import LocalStorageService from '../../core/services/local-storage.service';
import LocalStorageContext from './local-storage.context';

interface ILocalStorageProviderProps {
  children: React.ReactNode;
}

const LocalStorageProvider = ({ children }: ILocalStorageProviderProps) => {
  const localStorageService = useMemo(
    () => new LocalStorageService(localStorage),
    [localStorage]
  );

  return (
    <LocalStorageContext.Provider value={localStorageService}>
      {children}
    </LocalStorageContext.Provider>
  );
};

LocalStorageProvider.displayName = 'LocalStorageProvider';

export default LocalStorageProvider;
