import React from 'react';
import { Button as ButtonComponent } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';

interface IProps extends ButtonProps {
  className?: string;
}

const Button: React.FC<IProps> = ({
  className,
  children,
  ...buttonProps
}) => (

  <ButtonComponent
    className={cn(className)}
    {...buttonProps}
  >
    {children}
  </ButtonComponent>
);

export default Button;
