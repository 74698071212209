import React from 'react';
import { Input as InputComponent } from 'antd';
import { InputProps } from 'antd/lib/input';
import cn from 'classnames';
import styled from 'styled-components';

export const { TextArea } = InputComponent;

const StyledInput = styled(InputComponent)`
  padding: 4px 11px;
  border-radius: 8px;
  height: 42px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px #00b27b10 !important;
    border-color: #00b27b !important;
  }
`;

const Input: React.FC<InputProps> = ({ className, ...props }) => (
  <StyledInput {...props} className={cn(className)} />
);

export default Input;
