
export const getAdditionInfo = (user_id = null) => {
  const curDate = new Date();

  const dateN = curDate.getDate();
  const monthN = curDate.getMonth() + 1;

  const date = dateN > 9 ? dateN : `0${dateN}`;
  const month = monthN > 9 ? monthN : `0${monthN}`;

  const year = curDate.getFullYear();

  const timeH = curDate.getHours();
  const timeM = curDate.getMinutes();
  const browser_info = window.navigator?.vendor || '';
  const os_info = window.navigator?.platform || '';
  const current_page = window.location.pathname || '';

  return {
    user_id,
    date: (`${date}.${month}.${year}, ${timeH}:${timeM}:00`),
    browser_info,
    os_info,
    current_page
  };
};
