export const columnsGenre =
  [{
    'title': 'Title',
    'dataIndex': 'Title',
    'key': 'Title',
    'className': 'show',
    'width': 220
  },
  {
    'title': () => 'Author',
    'dataIndex': 'Author',
    'key': 'Author',
    className: 'show',
    width: 160
  },
  {
    title: 'ASIN',
    dataIndex: 'ASIN',
    key: 'ASIN',
    className: 'show',
    width: 130,
    render: (asin: string) => {
      if (asin) {
        return {
          children: <div><a
            className='table-cell-asin'
            href={`https://www.amazon.com/dp/${asin}`}
            rel='noreferrer'
            target='_blank'
          >
            {asin}
          </a></div>
        };
      }
    }
  },
  {
    title: 'Projected Sales',
    dataIndex: 'Projected Sales',
    key: 'Projected Sales',
    className: 'show',
    width: 140
  },
  {
    title: 'Num. of Pages',
    dataIndex: 'Num. of Pages',
    key: 'Num. of Pages',
    className: 'show',
    width: 150
  },
  {
    title: 'Avg. Also Bought Bestseller Rank',
    dataIndex: 'Avg. Also Bought Bestseller Rank',
    key: 'Avg. Also Bought Bestseller Rank',
    className: 'show',
    width: 100,
    render: (rank: string) => ({
      children: <div>{rank || '-' }</div>
    })
  },
  {
    title: 'Publisher Type',
    dataIndex: 'Publisher Type',
    key: 'Publisher Type',
    className: 'show',
    width: 230
  },
  {
    title: 'Genre',
    dataIndex: 'Genre',
    key: 'Genre',
    className: 'show',
    width: 200
  },
  {
    title: 'Series',
    dataIndex: 'Series',
    key: 'Series',
    className: 'show',
    width: 240
  },
  {
    title: 'Date Pub',
    dataIndex: 'Date Pub',
    key: 'Date Pub',
    className: 'show wrap-cell',
    width: 120,
    render: text => text?.split(' ')[0]
  },
  {
    title: 'Days On Sale',
    dataIndex: 'Days On Sale',
    key: 'Days On Sale',
    className: 'show',
    width: 100
  },

  {
    title: 'eBooks Sold',
    dataIndex: 'eBooks Sold',
    key: 'eBooks Sold',
    className: 'show',
    width: 100
  }
  ];