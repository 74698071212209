import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { IBook } from '../../../section/Common/types/types';
import { ACTIONS, useActions } from '../../../context/Actions.context';

interface IProps {
  book: IBook;
  id: string;
}

const Delete: React.FC<IProps> = ({ book, id }) => {
  const { setAction } = useActions();

  return (
    <Popover
      content={<div className='popover-content'>Delete Title</div>}
      trigger='hover'
    >
      <DeleteOutlined
        onClick={(e) => {
          e.stopPropagation();
          window.gtag('event', 'click', { event_category: 'Interaction', event_label: ACTIONS.delete });
          setAction({ book, id, type: ACTIONS.delete });
        }}
        className='actions-icons actions-icons-trash'
      />
    </Popover>
  );
};

export default React.memo(Delete);