const getTags = tags => {
  let tagsStr = '';

  if (tags?.length) {
    for (const tag of tags) {
      if (tag) {
        tagsStr += `&tag=${tag}`;
      }
    }

    tagsStr = tagsStr.replace('&tag=', '');
  }

  return tagsStr;
};

export default getTags;
