import { useQuery } from 'react-query';
import { useActions } from '../../../../context/Actions.context';
import Endpoints from '../../../constants/endpoints';
import { useServices } from '../../../services/useServices';
import { IGetBookListParams, IGetDataRowCountResponseDto } from '../book.dto';
import { notification } from 'antd';

export const useDataRowCount = (endpoint: string, params: IGetBookListParams
) => {
  const {
    book: { getDataRowCount }
  } = useServices();

  const { source: sourcePath } = useActions();

  const {
    data: response,
    refetch,
    status
  } = useQuery<IGetDataRowCountResponseDto, Error>(
    `get-row-count${endpoint}`,
    () => getDataRowCount(Endpoints[endpoint], params, sourcePath['source']),
    { onError: () => {
      notification.error({
        description: 'Request failed while fetching pagination data.',
        message: ''
      });
    },
    cacheTime: 1000000,
    enabled: false }
  );

  return {
    count: response?.count,
    status,
    refetch
  };
};