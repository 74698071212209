import React from 'react';
import { Line, LineConfig } from '@ant-design/plots';
import { LoadingOutlined } from '@ant-design/icons';
import { Card } from 'antd';

interface IGraphProps {
  data: object[];
  isLoading: boolean;
}

const PredictionsGraph: React.FC<IGraphProps> = ({ data, isLoading }) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const dataSource = data && data
    .filter(item => item['row_identifier'] === 'predicted_value')
    .flatMap(item =>
      Object.entries(item).map(([date, sales]) => {
        if (dateRegex.exec(date)) {
          return { Date: date, Sales: sales };
        }
        return null;
      })
    )
    .filter(Boolean) as Record<string, any>[];

  const LineGraph: React.FC = () => {
    if (dataSource.length === 0) {
      return <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          color: 'rgba(0, 0, 0, 0.45)'
        }}>
          No Data Available
      </div>;
    }

    const config: LineConfig = {
      data: dataSource,
      padding: [50, 50, 70, 70],
      autoFit: true,
      height: 350,
      xField: 'Date',
      yField: 'Sales',
      xAxis: {
        title: {
          style: {
            textAlign: 'center',
            fontSize: 12
          }
        }
      },
      yAxis: {
        title: {
          style: {
            textAlign: 'center',
            fontSize: 12
          }
        }
      },
      color: '#2ca880',
      lineStyle: {
        stroke: '#2ca880'
      },
      point: {
        size: 3,
        style: {
          fill: '#2ca880'
        }
      },
      tooltip: {
        fields: ['Sales'],
        showMarkers: true,
        marker: {
          fill: '#2ca880'
        }
      },
      connectNulls: true
    };

    return <Line {...config} />;
  };

  return (
    <>
      {!isLoading ? (
        <div id='prediction-graph' className='predictions-graph'>
          <div style={{ marginBottom: '10px' }}>Projected Sales</div>
          <Card className='overlay-card'>
            <LineGraph />
          </Card>
        </div>
      ) : (
        <div className='graph-spinner'>
          <LoadingOutlined style={{ fontSize: '20px' }}/>
        </div>
      )}
    </>
  );
};

export default PredictionsGraph;