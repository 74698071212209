import { Field, Form, Formik } from 'formik';
import FormItem from '../../../components/FormItem';
import Modal from '../../../components/Modal';
import { useCustomObject } from '../../../core/modules/book/hooks/useCustomObject';
import { useServices } from '../../../core/services/useServices';
import { Select, Option } from '../../../components/Select';
import { required } from '../../../utils/validation';
import { notification } from 'antd';

const DeleteGenre = ({ onClose, open }) => {
  const {
    book: { deleteGenre }
  } = useServices();

  const handleDelete = async (values) => {
    const prepareForBE = values?.id?.map(id => String(id));
    try {
      const res = await deleteGenre({ id: prepareForBE });

      if (res?.status === 200) {
        notification?.success({
          description: 'Successfully deleted',
          message: ''
        });
      }
    } catch (e) {
      notification?.error({
        description: 'Failed',
        message: ''
      });
    }

    onClose();
  };

  const { data, isLoading } = useCustomObject(
    'genres'
  );

  const options = data?.data?.data?.map(({ ID, Name }) =>
    <Option key={ID} value={ID}>
      {Name}
    </Option>
  );


  return (
    <Formik
      initialValues={{
        id: []
      }}
      enableReinitialize
      onSubmit={handleDelete}
    >
      {({ errors, handleSubmit, dirty }) => (
        <Form>
          <Modal okText='Delete'
            title='Delete custom genre'
            visible={open}
            onOk={handleSubmit}
            okButtonProps={{ disabled: Boolean(Object.values(errors)?.length) || !dirty }}
            onCancel={onClose}
          >
            <FormItem label='Select genre(s)' required error={errors?.id}>
              <Field name={'id'} validate={required}>
                {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                  <Select
                    placeholder='Select...'
                    value={value === '' || value === null ? undefined : value}
                    {...rest}
                    mode='multiple'
                    onChange={(v) => {
                      setFieldValue('id', v);
                    }}
                    loading={isLoading}
                    style={{ width: '100%' }}
                  >
                    {options || (
                      <Option key='loading' value='loading' disabled>
                        Loading...
                      </Option>
                    )}
                  </Select>
                )}
              </Field>
            </FormItem>
          </Modal>
        </Form>)}
    </Formik>
  );
};

export default DeleteGenre;