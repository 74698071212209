import logo from '../../assets/img/logo-v2.png';
import styled from 'styled-components';
import src from '../../assets/img/bg.svg';
import CustomLogin from './CustomLogin';
import { LockTwoTone } from '@ant-design/icons';

const Wrapper = styled.div`
  background: rgb(24, 144, 255);
  background: url(${src}) no-repeat fixed;
  height: 100vh;
  padding: 15px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Header = styled.header`
  .auth-logo {
    max-width: 165px;
  }
`;

const Content = styled.main`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.div`
  width: 500px;
  background-color: white;
  padding: 48px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: 0.05em;

    color: #000000;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05px;
    margin-bottom: 30px;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
`;

const LoginPage = () => (
  <Wrapper>
    <Header>
      <img className={'auth-logo'} src={logo} alt='logo'></img>
    </Header>
    <Content>
      <LoginForm>
        <h2>LOG IN</h2>
        <p><LockTwoTone twoToneColor={'grey'} style={{ fontSize: '15px' }}/> You are not logged in.</p>
        <p>Enter your credentials and click Log in.</p>
        <div>
          <CustomLogin />
        </div>
      </LoginForm>
    </Content>
  </Wrapper>
);

export default LoginPage;