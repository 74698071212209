import React from 'react';
import { Checkbox as CheckboxComponent } from 'antd';
import { CheckboxProps, CheckboxGroupProps } from 'antd/lib/checkbox';
import cn from 'classnames';
import styled from 'styled-components';

const StyledCheckbox = styled(CheckboxComponent)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00b27b;
    border-color: #00b27b;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #00b27b;
  }
`;
type ICheckboxProps = CheckboxProps;

type ICheckBoxGroupProps = CheckboxGroupProps;

export const Checkbox: React.FC<ICheckboxProps> = ({ className, ...props }) => (
  <StyledCheckbox {...props} className={cn(className)} />
);

export const CheckBoxGroup: React.FC<ICheckBoxGroupProps> = ({
  className,
  ...props
}) => <CheckboxComponent.Group className={cn(className)} {...props} />;

export * from 'antd/lib/checkbox/Group';
