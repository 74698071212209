import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';

export const useCreateCustomObject = () => {
  const {
    book: { createCustomObject }
  } = useServices();

  return useMutation(['create-custom-object'],
    (params: any) => createCustomObject(params));
};