import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../../core/constants';
import SignIn from '../../pages/SingIn';
import LoginPage from '../../pages/CustomLogin/Login.page';

const AuthorizedRouter: React.FC = () => (
  <Switch>
    <Route path={Routes.login} component={SignIn} exact />
    <Route path={Routes.customLogin} component={LoginPage} />
    <Redirect to={Routes.login} />
  </Switch>
);

export default AuthorizedRouter;