/* eslint-disable no-lonely-if */
import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';

export const useDashboardInfoTable = () => {
  const {
    dashboard: { getDashboardInfoTable }
  } = useServices();

  return useQuery('dashboard-table', () => getDashboardInfoTable());
};
