import './index.css';
import { Form, Input, Button, notification } from 'antd';
import { useAuthLogin } from '../../core/modules/auth/hooks/useAuthLogin.query';

const CustomLogin = () => {
  const { mutate, isLoading, isError } = useAuthLogin();

  const setErrorNotification = (message : string, description : string) => {
    notification['error']({
      message,
      description
    });
  };

  const handleError = () => {
    setErrorNotification('Login failed.', 'Please check your email and password.');
  };

  const onFinish = (values: {email: string, password: string}) => {
    mutate(`${values.email}:${values.password}`);
  };

  return (
    <div id='login-form-container' className='form-container'>
      <Form
        id='login-form'
        name='login'
        onFinish={onFinish}
        layout='vertical'
        initialValues={{
          remember: true
        }}
      >
        <Form.Item
          id='login-email-item'
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Please enter your email.'
            }
          ]}
        >
          <Input id='login-email-input' className='form-item-input' />
        </Form.Item>

        <Form.Item
          id='login-password-item'
          label='Password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please enter your password.'
            }
          ]}
        >
          <Input.Password id='login-password-input' className='form-item-input' />
        </Form.Item>

        <Form.Item id='login-submit-item' className='form-item-button'>
          <Button id='login-submit-button' type='primary' htmlType='submit' loading={isLoading}>
            Log in
          </Button>
          {isError && handleError}
        </Form.Item>
      </Form>
    </div>
  );
};

export default CustomLogin;