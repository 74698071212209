import { IBook } from '../types/types';
import { IGetBook } from '../../../core/modules/book/book.model';

const getDataSource = (data: IGetBook[], selectedOption = 'All Titles', isBookstat = true): IBook[] | undefined => {
  switch (selectedOption) {
  case 'All Titles':
    return data?.map(book => ({
      ...book,
      key: isBookstat ? book['ASIN'] : book['Fiction Id']
    }));
  case 'Hot Titles':
    return data?.filter(book => book.late_bloomer).map(book => ({
      ...book,
      key: isBookstat ? book['ASIN'] : book['Fiction Id']
    }));
  case 'Rising Stars':
    return data?.filter(book => book.is_risingstar).map(book => ({
      ...book,
      key: isBookstat ? book['ASIN'] : book['Fiction Id']
    }));
  default:
    return undefined;
  }
};

export default getDataSource;