import { Input, notification } from 'antd';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import FormItem from '../../components/FormItem';
import Modal from '../../components/Modal';
import { Option, Select } from '../../components/Select';
import UploadFiles from '../../components/UploadFile';
import { useServices } from '../../core/services/useServices';
import { getAdditionInfo } from '../../section/Common/utils/getAdditionInfo';
import { required } from '../../utils/validation';
import { feedbackOptions } from '../../core/constants/global-constants';

const { TextArea } = Input;

const FeedbackBugs = ({ onClose, open, user }) => {
  const { user: { feedback } } = useServices();
  const [noteActionCount, setNoteActionCount] = React.useState(0);
  const [expectedResultCount, setExpectedResultCount] = React.useState(0);
  const [actualResultCount, setActualResultCount] = React.useState(0);
  const [detailsCount, setDetailsCount] = React.useState(0);
  const textAreaMaxLength = 1500;
  const onLeaveFeedback = async (data, { setSubmitting }) => {
    const userInfo = getAdditionInfo(user?.id);
    try {
      const res = await feedback({ ...data || {}, ...userInfo }, 'error-bug');

      if (res?.success) {
        notification?.success({
          description: 'Successfully sent',
          message: res?.data.msg
        });
      }
    } catch (e) {
      console.log(e);
    }
    setSubmitting(false);

    onClose();
  };

  return (
    <Formik
      initialValues={{
        page_or_functionality: null,
        notes_actions: null,
        expected_result: null,
        actual_result: null,
        details: null,
        files: []
      }}
      enableReinitialize
      onSubmit={onLeaveFeedback}
    >
      {({ errors, handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <Modal okText='Submit'
            title='Feedback: Error/Bug'
            visible={open}
            onOk={handleSubmit}
            okButtonProps={{ disabled: isSubmitting || Boolean(Object.values(errors)?.length) || !dirty }}
            onCancel={onClose}
          >
            <FormItem label='Page or Functionality' required error={errors?.page_or_functionality}>
              <Field name={'page_or_functionality'} validate={required}>
                {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                  <Select
                    placeholder='Select...'
                    value={value === '' || value === null ? undefined : value}
                    {...rest}
                    onChange={v => {
                      setFieldValue('page_or_functionality', v);
                    }}
                  >
                    {feedbackOptions.map((role, index) => (
                      <Option key={index} value={role}>
                        {role}
                      </Option>
                    ))}
                  </Select>
                )}
              </Field>
            </FormItem>
            <FormItem
              label='Describe what actions you were taking when the error occurred'
              required
              error={errors?.notes_actions}>
              <Field name={'notes_actions'} validate={required}>
                {({ field: { value, onChange, ...rest }, form: { setFieldValue } }: any) => (
                  <><TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    maxLength={textAreaMaxLength}
                    onChange={event => {
                      onChange(event);
                      setFieldValue('notes_actions', event.target.value.replace(/\n$/, ''));
                      setNoteActionCount(event.target.value.length);
                    }}
                    {...rest} /><span style={{ float: 'right', fontStyle: 'italic', fontSize: 'smaller' }}>
                    {textAreaMaxLength - noteActionCount} characters left</span></>
                )}
              </Field>
            </FormItem>
            <FormItem label='What did you expect to happen' required error={errors?.expected_result}>
              <Field name={'expected_result'} validate={required}>
                {({ field: { value, onChange, ...rest }, form: { setFieldValue } }: any) => (
                  <><TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    maxLength={textAreaMaxLength}
                    onChange={event => {
                      onChange(event);
                      setFieldValue('expected_result', event.target.value.replace(/\n$/, ''));
                      setExpectedResultCount(event.target.value.length);
                    } }
                    {...rest} /><span style={{ float: 'right', fontStyle: 'italic', fontSize: 'smaller' }}>
                    {textAreaMaxLength - expectedResultCount} characters left</span></>
                )}
              </Field>
            </FormItem>

            <FormItem label='What actually happened' required error={errors?.actual_result}>
              <Field name={'actual_result'} validate={required}>
                {({ field: { value, onChange, ...rest }, form: { setFieldValue } }: any) => (
                  <><TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    maxLength={textAreaMaxLength}
                    onChange={event => {
                      onChange(event);
                      setFieldValue('actual_result', event.target.value.replace(/\n$/, ''));
                      setActualResultCount(event.target.value.length);
                    }}
                    {...rest} /><span style={{ float: 'right', fontStyle: 'italic', fontSize: 'smaller' }}>
                    {textAreaMaxLength - actualResultCount} characters left</span></>
                )}
              </Field>
            </FormItem>

            <FormItem label='Additional details' required={false} error={undefined}>
              <Field name={'details'}>
                {({ field: { value, onChange, ...rest }, form: { setFieldValue } }: any) => (
                  <><TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    maxLength={textAreaMaxLength}
                    onChange={event => {
                      onChange(event);
                      setFieldValue('details', event.target.value.replace(/\n$/, ''));
                      setDetailsCount(event.target.value.length);
                    }}
                    {...rest} />
                  <span style={{ display: 'block',
                    textAlign: 'right',
                    fontStyle: 'italic',
                    fontSize: 'smaller' }}>
                    {textAreaMaxLength - detailsCount} characters left</span></>
                )}
              </Field>
            </FormItem>

            <UploadFiles
              name='files'
            />
          </Modal>
        </Form>)}
    </Formik>
  );
};

export default React.memo(FeedbackBugs);