import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import { Option, Select } from '../../components/Select';
import debounce from 'debounce';
import { useServices } from '../../core/services/useServices';
import { SOURCE, USERS } from '../../core/constants';
import { useQuery } from 'react-query';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const UsersFilters = ({ setFilters, roles, isLoadingRoles }) => {
  const onSubmit = e => e.preventDefault();
  const {
    book: { getFilter }
  } = useServices();

  const { data: lastNameData, isLoading } = useQuery(
    'options=filter-last_name',
    () => getFilter('last_name', USERS, '', '', SOURCE.bookstat)
  );

  const lastNameOptions = lastNameData?.data?.map(el => (
    <Option value={el} key={el}>
      {el}
    </Option>
  ));

  const { data: emailData, isLoading: isLoadingEmail } = useQuery(
    'options=filter-email',
    () => getFilter('email', USERS, '', '', SOURCE.bookstat)
  );

  const emailOptions = emailData?.data?.map(el => (
    <Option value={el} key={el}>
      {el}
    </Option>
  ));
  const onChange = debounce((key, filters) => {
    setFilters(state => ({ ...state, [key]: filters }));
  }, 1000);

  return (
    <Wrapper>
      <Formik
        initialValues={{
          role: [],
          active: []
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Field name={'role'}>
                  {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                    <Select
                      placeholder='Role'
                      value={value === '' || value === null ? undefined : value}
                      {...rest}
                      allowClear
                      mode='multiple'
                      showArrow
                      onChange={v => {
                        onChange('role', v);
                        setFieldValue('role', v);
                      }}
                    >
                      {isLoadingRoles ? (
                        <Option key='loading' value='loading' disabled={true}>
                          Loading...
                        </Option>
                      ) : (
                        roles.map(role => (
                          <Option key={role} value={role}>
                            {role}
                          </Option>
                        )))}
                    </Select>
                  )}
                </Field>
              </Col>

              <Col className='gutter-row' span={6}>
                <Field name={'last_name'}>
                  {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                    <Select
                      placeholder='Last Name'
                      value={value === '' || value === null ? undefined : value}
                      {...rest}
                      allowClear
                      mode='multiple'
                      showArrow
                      onChange={v => {
                        onChange('last_name', v);
                        setFieldValue('last_name', v);
                      }}
                    >
                      {isLoading ? (
                        <Option key='loading' value='loading' disabled={true}>
                          Loading...
                        </Option>
                      ) : (
                        lastNameOptions
                      )}
                    </Select>
                  )}
                </Field>
              </Col>

              <Col className='gutter-row' span={6}>
                <Field name={'email'}>
                  {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                    <Select
                      placeholder='Email'
                      value={value === '' || value === null ? undefined : value}
                      {...rest}
                      allowClear
                      mode='multiple'
                      showArrow
                      onChange={v => {
                        onChange('email', v);
                        setFieldValue('email', v);
                      }}
                    >
                      {isLoadingEmail ? (
                        <Option key='loading' value='loading' disabled={true}>
                          Loading...
                        </Option>
                      ) : (
                        emailOptions
                      )}
                    </Select>
                  )}
                </Field>
              </Col>

              <Col className='gutter-row' span={6}>
                <Field name={'active'}>
                  {({
                    field: { value, ...restProps },
                    form: { setFieldValue }
                  }) => (
                    <Select
                      placeholder={'Status'}
                      {...restProps}
                      allowClear
                      value={value === '' || value === null ? undefined : value}
                      onChange={v => {
                        onChange('active', v);
                        setFieldValue('active', v);
                      }}
                    >
                      <Option value='1'>Active</Option>
                      <Option value='0'>Not Active</Option>
                    </Select>
                  )}
                </Field>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default UsersFilters;