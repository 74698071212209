import { Field } from 'formik';
import { Select } from 'antd';
import FormItem from '../../../components/FormItem';
import { required } from '../../../utils/validation';

const { Option } = Select;

const ParentGenresList = [
  { ID: 'si_fi',
    Name: 'Sci-Fi/Fantasy' },
  { ID: 'romance_mystery_thriller',
    Name: 'Romance/Mystery/Thriller' },
  { ID: 'other',
    Name: 'Others' }
];

const ParentGenre = () => (
  <FormItem label='Select Parent Genre' required error={undefined}>
    <Field name={'parent'} validate={required} >
      {({ field: { value, ...rest }, form: { setFieldValue } }) => (
        <Select
          placeholder='Select...'
          value={value === '' ? null : value}
          {...rest}
          onChange={(v, opt) => {
            setFieldValue('parent', v);
          }}
          style={{ width: '100%' }}
        >
          {
            ParentGenresList?.map(({ ID, Name }) =>
              <Option key={ID} value={ID}>
                {Name}
              </Option>)
          }
        </Select>
      )}
    </Field>
  </FormItem>
);

export default ParentGenre;