import React, { useState } from 'react';
import { Row, Col, notification } from 'antd';
import { useQueryClient } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { DELETE } from '../../../core/constants';
import getTags from '../../../section/Common/utils/getTags';
import { useServices } from '../../../core/services/useServices';
import Endpoints, { SOURCE } from '../../../core/constants/endpoints';
import { SpinnerWrapper, Title, WarningInfo } from '../../../components/styles';
import { Spinner } from '../../../components/Spinner';
import { Field, Form, Formik } from 'formik';
import Modal from '../../../components/Modal';
import { Checkbox, CheckBoxGroup } from '../../../components/Checkbox';
import Input from '../../../components/Input';
import { useActions } from '../../../context/Actions.context';

interface IProps {
  book_ids: [];
  onClose: Function;
  onReset: Function;
  open: boolean;
  id: string;
}

const checkboxTrash = [
  'Public Domain Title',
  'Irrelevant Content',
  'Foreign Language'
];

const DeleteAllTitles: React.FC<IProps> = ({
  book_ids,
  open,
  onClose,
  onReset,
  id
}) => {
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { book: { updateTitle } } = useServices();
  const { source: sourcePath, setActionLoading } = useActions();

  const queryClient = useQueryClient();
  const sourceTab = window.location.pathname.split('/')[2];

  const onDelete = async (values, cb) => {
    setActionLoading(true);
    setLoading(true);
    let tag = getTags(values?.tags);

    if (isCheck) {
      tag += `&tag=${values?.checkboxInput}`;
    }

    try {
      const data: { [key: string]: any } = {};

      if (sourcePath['source'] === SOURCE.bookstat) {
        data.amazonasin = book_ids;
        data.tag = tag;
      } else if (sourcePath['source'] === SOURCE['royal-road']) {
        data.fiction_id = book_ids;
      }

      const response = await updateTitle({
        source: sourcePath['source'],
        endpoint: Endpoints[DELETE],
        sourceTab,
        action: DELETE
      }, data);

      setLoading(false);
      onClose();
      onReset();
      cb.resetForm();

      if (response?.status === 200) {
        notification?.success({
          description: 'The title(s) were successfully deleted.',
          message: ''
        });
        queryClient.refetchQueries(`get-row-count${id}`);
        queryClient.refetchQueries(`get-${id}`);
        return;
      }
    } catch (e) {
      setLoading(false);
      onClose();
      onReset();
      cb.resetForm();
      notification?.error({
        description: 'Failed to delete title(s).',
        message: ''
      });
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        tags: [],
        checkboxInput: '',
        amazonasin: book_ids
      }}
      onSubmit={onDelete}
    >
      {({ values, handleSubmit, resetForm }) => (
        <Form>
          <Modal
            okText='Delete All'
            title='Delete Titles'
            visible={open}
            onOk={handleSubmit}
            okButtonProps={{
              disabled: !(
                values?.tags?.length ||
                (isCheck && values?.checkboxInput)
              )
            }}
            onCancel={() => {
              setIsCheck(false);
              resetForm();
              onClose();
            }}
            confirmLoading={loading}
          >
            <WarningInfo>
            You are about to delete {book_ids?.length} title(s).
            All title(s) will be assigned the same reason(s) for deletion, which will be selected below.
            </WarningInfo>
            <Title>Select reason(s) for deleting titles:</Title>

            <Row>
              <Field name='tags'>
                {({
                  field: { value, name },
                  form: { setFieldValue, setFieldTouched }
                }) => (
                  <CheckBoxGroup
                    value={value}
                    name={name}
                    onChange={v => {
                      setFieldValue(name, v);
                      setFieldTouched(name, true, false);
                    }}
                  >
                    {checkboxTrash.map((reason, idx) => (
                      <Col span={24} key={idx} style={{ marginBottom: 8 }}>
                        <Checkbox value={reason}>{reason}</Checkbox>
                      </Col>
                    ))}
                  </CheckBoxGroup>
                )}
              </Field>
              <Col span={24} style={{ marginBottom: 8 }}>
                <Checkbox
                  checked={isCheck}
                  onChange={() => {
                    setIsCheck(state => !state);
                  }}
                >
                  <Field name={'checkboxInput'}>
                    {({ field: { value, onChange, ...rest } }: any) => (
                      <Input
                        name={'checkboxInput'}
                        value={value}
                        disabled={!isCheck}
                        onChange={event => {
                          onChange(event);
                        }}
                        {...rest}
                      />
                    )}
                  </Field>
                </Checkbox>
              </Col>
            </Row>
            {loading && (
              <SpinnerWrapper>
                <Spinner
                  size='large'
                  spinning={loading}
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 34
                      }}
                      spin
                    />
                  }
                />
              </SpinnerWrapper>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default DeleteAllTitles;