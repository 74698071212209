import React, { useContext, useState } from 'react';
import DeleteTitles from '../composed-components/Actions/Delete/DeleteTitles';
import ExcludeTitles from '../composed-components/Actions/Exclude/ExcludeTitles';
import SendToCRM from '../composed-components/Actions/ToCRM/SendToCRM';

const ActionsContext = React.createContext({
  setAction: values => console.log(values),
  source: '/bookstat',
  isActionLoading: false,
  setActionLoading: (value: boolean) => console.log(value),
  isRefreshed: false,
  setIsRefreshed: (value: boolean) => console.log(value)
});

export const ACTIONS = {
  delete: 'delete',
  return: 'return',
  watch: 'watch',
  exclude: 'exclude',
  toCRM: 'toCRM'
};

export const ActionsProvider = ({ children, source }) => {
  const [action, setAction] = useState({
    type: null,
    book: { ASIN: '', id: '', Title: '' },
    id: ''
  });

  const [isActionLoading, setActionLoading] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  return (
    <ActionsContext.Provider
      value={{
        setAction,
        source,
        isActionLoading,
        setActionLoading,
        isRefreshed,
        setIsRefreshed
      }}
    >
      <DeleteTitles
        book={action?.book}
        id={action?.id}
        modal={action?.type === ACTIONS.delete}
        closeModal={() =>
          setAction({
            type: null,
            book: { ASIN: '', id: '', Title: '' },
            id: ''
          })
        }
      />
      <ExcludeTitles
        book={action?.book}
        id={action.id}
        modal={action?.type === ACTIONS.exclude}
        toggleModal={() =>
          setAction({
            type: null,
            book: { ASIN: '', id: '', Title: '' },
            id: ''
          })
        }
      />
      <SendToCRM
        book={action?.book}
        id={action.id}
        modal={action?.type === ACTIONS.toCRM}
        toggleModal={() =>
          setAction({
            type: null,
            book: { ASIN: '', id: '', Title: '' },
            id: ''
          })
        }
      />
      {children}
    </ActionsContext.Provider>
  );
};

export const ActionsConsumer = ActionsContext.Consumer;

export const useActions = () => {
  const context = useContext(ActionsContext);

  return context;
};

export default ActionsContext;