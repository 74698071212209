import React from 'react';
import { Spinner } from '../../components/Spinner';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  isLoading: boolean;
}

const SpinnerTabs: React.FC<IProps> = ({ isLoading, ...props }) => (
  <Spinner
    {...props}
    size='large'
    spinning={isLoading}
    indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
  />
);

export default SpinnerTabs;
