import { COLUMNSHEADING } from '../constants';
import getAllColumns from './getAllColumns';
import AlsoBought from '../../../composed-components/AlsoBought';

export const getAllNewTitles = (sorting, TAB_ID) => {
  const allColumns = getAllColumns(sorting);
  const head = COLUMNSHEADING[TAB_ID].map(title => {
    if (title === 'Price') {
      return {
        ...allColumns[title],
        render(text) {
          let styles = {};
          const result = <span>${text || 0}</span>;

          if (Number(text) === 0 || !text) {
            styles = {
              background: 'rgb(255 157 157 / 36%)'
            };
          }

          return {
            props: {
              style: styles
            },
            children: result
          };
        }
      };
    }

    if (title === 'Kindle Sales Rank') {
      return {
        ...allColumns[title],
        render(text) {
          let styles = {};

          if (Number(text) > 10000) {
            styles = {
              background: 'var(--highlight-green-color)',
              color: 'var(--highlight-green-text-color)'
            };
          }

          if (Number(text) >= 10000 && Number(text) <= 50000) {
            styles = {
              background: 'var(--highlight-orange-color)',
              color: 'var(--highlight-orange-text-color)'
            };
          }

          return {
            props: {
              style: styles
            },
            children: <div>{text}</div>
          };
        }
      };
    }

    if (title === 'Podium Hit Count') {
      return {
        ...allColumns[title],
        render(text, book) {
          const result = <AlsoBought podiumHitCount={text === null ? -1 : text} asin={book.key}/>;

          return {
            children: result
          };
        }
      };
    }

    return allColumns[title];
  });

  return head;
};
