import React from 'react';
import styled from 'styled-components';
import Table from '../../components/Table';
import { SpinnerTabs } from '../../composed-components';
import { useDashboardInfoTable } from '../../core/modules/dashboard/hooks/useDashboardInfoTable';

const DashboardWrapper = styled.div`
  width: 100%;
`;

const Text = styled.p`
  font-weight: 600;
  margin: 20px 10px;
`;

const rowName = {
  'all-excluded': 'Number of Titles sent to Excluded list',
  'all-new-titles': 'Number of All New Titles added',
  deleted: 'Number of Titles Deleted',
  'podium-titles': 'Number of Podium Titles',
  'sent-to-crm': 'Number of Titles sent to CRM',
  watchlist: 'Number of Titles added to Watchlist'
};

const getDataSource = (data: Record<string, object>) =>
  data &&
  Object.entries(data).reduce(
    (acc: Array<object>, [key, value]) =>
      !rowName[key]
        ? acc
        : [
          ...acc,
          {
            key,
            name: rowName[key],
            '30': value?.['30'],
            '60': value?.['60'],
            '90': value?.['90'],
            '120': value?.['120']
          }
        ],
    []
  );

const columns = [
  {
    title: 'Data',
    dataIndex: 'name',
    key: 'name',
    width: '400px'
  },
  {
    title: '30d',
    dataIndex: '30',
    key: '30'
  },
  {
    title: '60d',
    dataIndex: '60',
    key: '60'
  },
  {
    title: '90d',
    dataIndex: '90',
    key: '90'
  },
  {
    title: '120d',
    dataIndex: '120',
    key: '120'
  }
];

const DashboardSection: React.FC = () => {
  const { data, isLoading } = useDashboardInfoTable();
  const dataSource = getDataSource(data?.data);

  return !isLoading ? (
    <DashboardWrapper>
      {Boolean(data?.last_bookstat_date) && (
        <Text>Last Bookstat data refresh: {data?.last_bookstat_date} </Text>
      )}
      {data && (
        <Table
          bordered
          dataSource={dataSource || []}
          pagination={false}
          columns={columns}
        />
      )}
    </DashboardWrapper>
  ) : (
    <SpinnerTabs isLoading={isLoading} />
  );
};

export default DashboardSection;