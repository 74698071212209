import './styles.css';

const Popup = ({ record, visible, x, y }) =>
  visible && (
    <ul className='popup' style={{ left: `${x > (4 * window.innerWidth) / 5 ? x - 100 : x}px`, top: `${y}px` }}>
      <a
        style={{ color: 'black', fontSize: '13px', padding: '12px' }}
        href={`/bookstat/podium-sales?amazonasin=${record.ASIN}`}
        target={'_blank'}
        rel='noreferrer'
      >
        Open in new tab
      </a>
    </ul>
  );

export default Popup;
