import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';

export const useAlsoBoughtSearch = options => {
  const {
    book: { getAlsoBoughtBooks }
  } = useServices();

  return useMutation('get/also-bought', (book: string) =>
    getAlsoBoughtBooks(book),
  options
  );
};