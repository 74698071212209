import React, { useEffect, useMemo, useState } from 'react';
import { Form, Select, Tag } from 'antd';
import { useFiltersTags } from '../../core/modules/book/hooks/useFiltersTags';
import { useFilter } from '../../context/Filter.context';
import { useBooksList } from '../../core/modules/book/hooks';

const { Option } = Select;

const FilterItemTag = ({ selectProps, point, placeholder, id }) => {
  const [enable, setEnable] = useState(false);

  const {
    data: response,
    isLoading,
    isFetching
  } = useFiltersTags(point, id, enable);

  const { isFetching: isRefreshingData } = useBooksList(id, { runBy: 'BrendaCaching' });

  const [values, setValues] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<any>([]);

  useEffect(() => {
    setValues(response?.data ?? []);
    setInitialValues(response?.data ?? []);
  }, [response]);

  const [includedTags, setIncludedTags] = useState<any>([]);
  const [excludedTags, setExcludedTags] = useState<any>([]);

  const { filter } = useFilter();

  const getOptions = (arr, key) => {
    const currentOptions = values?.filter(
      el => !arr.includes(el) && !filter[id][key].includes(el)
    );

    return currentOptions?.map(el => (
      <Option key={el} value={el}>
        {el}
      </Option>
    ));
  };

  const excludedValues = useMemo(
    () => getOptions(includedTags, point),
    [includedTags, values, filter]
  );

  const includedValues = useMemo(
    () => getOptions(excludedTags, `not_${point}`),
    [excludedTags, values, filter]
  );

  const onChangeIncluded = all => {
    setIncludedTags(all);
  };

  const onChangeExcluded = all => {
    setExcludedTags(all);
  };

  const tagRender = (props, color) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const resetTags = () => {
    setValues(initialValues);
    setIncludedTags([]);
    setExcludedTags([]);
  };

  useEffect(() => {
    if (!isRefreshingData) {
      resetTags();
    }
  }, [isRefreshingData, initialValues]);

  return (
    <>
      <Form.Item name={`${id}${point}`}>
        <Select
          tagRender={props => tagRender(props, 'blue')}
          onChange={onChangeIncluded}
          onClick={() => setEnable(true)}
          placeholder={placeholder}
          {...selectProps}
          style={{ width: '100%' }}
        >
          {(isLoading || isFetching) && (
            <Option key='loading' value='loading' disabled={true}>
              Loading...
            </Option>
          )}
          {includedValues}x
        </Select>
      </Form.Item>

      <Form.Item name={`${id}not_${point}`}>
        <Select
          tagRender={props => tagRender(props, 'red')}
          onChange={onChangeExcluded}
          onClick={() => setEnable(true)}
          placeholder={`Exclude ${placeholder}`}
          {...selectProps}
          style={{ width: '100%' }}
        >
          {(isLoading || isFetching) && (
            <Option key='loading' value='loading' disabled={true}>
              Loading...
            </Option>
          )}
          {excludedValues}
        </Select>
      </Form.Item>
    </>
  );
};

export default React.memo(FilterItemTag);
