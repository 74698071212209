import { notification } from 'antd';
import { useQueryClient, useMutation } from 'react-query';
import Modal from '../../components/Modal';
import { useServices } from '../../core/services/useServices';

const ModalDelete = ({ user, open, setOpen }) => {
  const { user: { deleteUser } } = useServices();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteUser, {
    onSuccess: (response) => {
      setOpen(false);

      if (response?.data?.length) {
        notification?.success({
          message: 'Successfully deleted user.',
          description: response?.msg
        });

        return queryClient.refetchQueries('get-all-users');
      } else {
        notification?.error({
          message: 'Failed to delete user.',
          description: response?.msg
        });
      }
    },
    onError: () => {
      setOpen(false);
      notification?.error({
        description: 'An error occurred while deleting the user. Please try again.',
        message: ''
      });
      window.gtag('event', 'exception', { event_category: 'Error', event_label: 'Error Deleting User' });
    }
  });

  const handleDelete = async () => {
    const id = user?.id || '';
    mutate({ id });
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Delete User' });
  };

  return (
    <Modal
      okText='Delete'
      title={'Delete user'}
      visible={open}
      confirmLoading={isLoading}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={handleDelete}
    >
      Do you want to delete user {user?.first_name} {user?.last_name}?
    </Modal>
  );
};

export default ModalDelete;