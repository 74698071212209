import { Input, notification } from 'antd';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import FormItem from '../../components/FormItem';
import Modal from '../../components/Modal';
import { Option, Select } from '../../components/Select';
import { useUser } from '../../core/modules/user/hooks';
import { useServices } from '../../core/services/useServices';
import { getAdditionInfo } from '../../section/Common/utils/getAdditionInfo';
import { required } from '../../utils/validation';

const { TextArea } = Input;


const FeedbackScoring = ({ onClose, open }) => {
  const {
    user: { feedback }
  } = useServices();
  const { data: user } = useUser();
  const [charCount, setCharCount] = React.useState(0);
  const textAreaMaxLength = 1500;

  const onLeaveFeedback = async (data, { setSubmitting }) => {
    const userInfo = getAdditionInfo(user?.id);
    try {
      const res = await feedback({ ...data || {}, ...userInfo }, 'scoring');

      if (res?.success) {
        notification?.success({
          description: 'Successfully sent',
          message: ''
        });
      }
    } catch (e) {
      console.log(e);
    }
    setSubmitting(false);
    onClose();
  };

  return (
    <Formik
      initialValues={{
        scoring: null,
        amazonasin: null,
        details: null
      }}
      enableReinitialize
      onSubmit={onLeaveFeedback}
    >
      {({ errors, handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <Modal okText='Submit'
            title='Feedback: Scoring'
            visible={open}
            onOk={handleSubmit}
            okButtonProps={{ disabled: isSubmitting || Boolean(Object.values(errors)?.length) || !dirty }}
            onCancel={onClose}
          >
            <FormItem label='Issue with scoring of this title' required error={errors?.scoring}>
              <Field name={'scoring'} validate={required}>
                {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                  <Select
                    placeholder='Select...'
                    value={value === '' || value === null ? undefined : value}
                    {...rest}
                    onChange={v => {
                      setFieldValue('scoring', v);
                    }}
                  >
                    <Option value='This title should have scored higher'>
                    This title should have scored higher
                    </Option>
                    <Option value='This title should have scored lower'>
                    This title should have scored lower
                    </Option>
                    <Option value='General scoring feedback on this title'>
                    General scoring feedback on this title
                    </Option>
                  </Select>
                )}
              </Field>
            </FormItem>
            <FormItem
              label='ASIN of this title'
              required
              error={errors?.amazonasin}>
              <Field name={'amazonasin'}>
                {({ field: { value, onChange, ...rest } }: any) => (
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    onChange={event => {
                      onChange(event);
                    }}
                    {...rest}
                    maxLength={10}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem
              label='Please provide additional details that will be helpful to the team'
              required={false}
              error={errors?.details}>
              <Field name={'details'}>
                {({ field: { value, onChange, ...rest }, form: { setFieldValue } }: any) => (
                  <><TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={value}
                    placeholder=''
                    maxLength={textAreaMaxLength}
                    onChange={event => {
                      onChange(event);
                      setFieldValue('details', event.target.value.replace(/\n$/, ''));
                      setCharCount(event.target.value.length);
                    }}
                    {...rest} /><span style={{ float: 'right', fontStyle: 'italic', fontSize: 'smaller' }}>
                    {textAreaMaxLength - charCount} characters left</span></>
                )}
              </Field>
            </FormItem>
          </Modal>
        </Form>)}
    </Formik>
  );
};


export default FeedbackScoring;
