import { useQuery } from 'react-query';
import { useServices } from '../../../services/useServices';
import { UserActivityPayloadType } from '../user.model';

const useUserTracking = (action: string, payload?: UserActivityPayloadType) => {
  const {
    user: { userActivity }
  } = useServices();

  const {
    data,
    isLoading,
    isFetching,
    refetch
  } = useQuery<{ data: any }, Error>('user-tracking', () => userActivity(action, payload), {
    refetchInterval: false,
    enabled: false
  });

  return { data, refetch, isLoading, isFetching };
};

export default useUserTracking;