import { useMutation } from 'react-query';
import { useServices } from '../../../services/useServices';
import { useActions } from '../../../../context/Actions.context';

export const useSendToCRMSystem = () => {
  const {
    book: { sendToCRMSystem }
  } = useServices();

  const { source: sourcePath } = useActions();

  return useMutation(params => sendToCRMSystem(params, sourcePath['source']));
};
