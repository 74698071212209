import React from 'react';
import { Avatar as AvatarAntd } from 'antd';

interface IProps {
  className?: string;
  src?: string;
  user?: string;
}

export const Avatar: React.FC<IProps> = ({ src, user = 'U', ...props }) =>
  src ? (
    <AvatarAntd src={src} size={34} {...props} />
  ) : (
    <AvatarAntd size={34} {...props}>
      {user}
    </AvatarAntd>
  );
