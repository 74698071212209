export interface ILocalStorageService {
  get: (key: string) => string | null;
  set: (key: string, value: string) => void;
  remove: (key: string) => void;
}

export default class LocalStorageService implements ILocalStorageService {
  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public get(key: string): string | null {
    return this.storage.getItem(key);
  }

  public set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public remove(key: string): void {
    return this.storage.removeItem(key);
  }
}
