/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

const UserContext = React.createContext({
  id: null,
  edit_titles: false,
  role: 'Other',
  move_titles_hubspot: false,
  view_tables: false,
  searchData: [],
  // eslint-disable-next-line no-empty-function
  updateSearchData: (newSearchData) => {}
});

export const UserProvider = ({ children, data }) => {
  const [user, setUser] = useState({
    ...data,
    searchData: data?.searchData || []
  });

  const updateSearchData = (newSearchData) => {
    setUser((prevData) => ({
      ...prevData,
      searchData: newSearchData
    }));
  };

  useEffect(() => {
    setUser((prevData) => ({
      ...prevData,
      searchData: data?.searchData || []
    }));
  }, [data?.searchData]);

  return (
    <UserContext.Provider
      value={{
        id: user?.id,
        edit_titles: user?.edit_titles,
        role: user?.role,
        move_titles_hubspot: user?.move_titles_hubspot,
        view_tables: user?.view_tables,
        searchData: user?.searchData,
        updateSearchData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export const useUserPermit = () => {
  const context = useContext(UserContext);

  return context;
};

export default UserContext;