import { defaultFilter } from '../../../context/Filter.context';
import { defaultSort } from '../../../context/Sort.context';
import getParsedData from './getParsedData';

const getFilter = (getTabId: (string) => (string)) => {
  const search_query = window.location.search;
  const tab = window.location.pathname.split('/')[2];
  const tabId = getTabId(tab);
  const parsedData = getParsedData(search_query, tabId);

  const modifiedFilter = { ...defaultFilter };
  const modifiedSort = { ...defaultSort };

  if (parsedData?.filters) {
    modifiedFilter[tabId] = {
      ...defaultFilter[tabId],
      ...parsedData.filters
    };
  }

  if (parsedData?.sort) {
    modifiedSort[tabId] = {
      ...defaultSort[tabId],
      ...parsedData.sort
    };
  }

  return {
    filter: Object.keys(parsedData?.filters).length === 0 ? modifiedFilter : { [tabId]: modifiedFilter[tabId] },
    sort: Object.keys(parsedData?.sort).length === 0 ? modifiedSort : { [tabId]: modifiedSort[tabId] }
  };
};

export default getFilter;