import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './router/root.routing';
import { createServices } from './core/services';
import { GlobalErrorBoundary } from './components/Error/GlobalErrorBoundary';
import { LocalStorageProvider } from './providers/local-storage-provider';
import { UserActivityProvider } from './context/UserActivity.context';
import { ServicesProvider } from './core/services/useServices';
import { config } from './config/env-config';
import 'antd/dist/antd.css';
import './App.css';
import dotenv from 'dotenv';

import createApiServices from './core/services/createApiServices';
import LocalStorageService from './core/services/local-storage.service';

dotenv.config();

const api = createApiServices(config.REACT_APP_API_BASE_URL);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});
const localStorageService = new LocalStorageService(window.localStorage);
const services = createServices(api, localStorageService);

function App() {
  return (
    <ServicesProvider services={services}>
      <GlobalErrorBoundary>
        <LocalStorageProvider>
          <UserActivityProvider>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </UserActivityProvider>
        </LocalStorageProvider>
      </GlobalErrorBoundary>
    </ServicesProvider>
  );
}

export default App;