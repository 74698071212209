import React, { useMemo } from 'react';
import Table from '../../components/Table';
import SpinnerTabs from '../SpinnerTabs';
import { WrapperTable } from '../CustomObject/Genre/styles';
import { columnsAlsoBought } from './columnsAlsoBought';
import Legend from './Legend';

const AlsoBoughtContent = ({ data, isLoading }) => {
  const dataSource = useMemo(() => data || [], [data]);

  return (
    <WrapperTable>
      {!isLoading ? (
        <>
          <Table
            bordered
            dataSource={dataSource}
            pagination={true}
            columns={columnsAlsoBought}
          />
          <Legend map={['title', 'podium']} />
        </>
      ) : (
        <SpinnerTabs isLoading={isLoading} />
      )}
    </WrapperTable>
  );
};

export default React.memo(AlsoBoughtContent);