import { AxiosInstance } from 'axios';
import { ILoginResponse } from './auth.model';
import { AbstractApiService } from '../../services/abstract-service';
import { Auth } from '../../constants/auth';
import Endpoints from '../../constants/endpoints';
import { ILocalStorageService } from '../../services/local-storage.service';

export interface IAuthService {
  singinUser: (token: string) => Promise<ILoginResponse>;
  refreshAccessToken: () => Promise<ILoginResponse>;
  loginUser: (email: string, password: string) => Promise<any>;
}

export class AuthService extends AbstractApiService implements IAuthService {
  private readonly localStorageService: ILocalStorageService;

  constructor(axios: AxiosInstance, localStorageService: ILocalStorageService) {
    super(axios);
    this.localStorageService = localStorageService;
  }

  public singinUser = async (token: string): Promise<ILoginResponse> => {
    const { data } = await this.api.post<ILoginResponse>(Endpoints.authSingIn, {
      Token: token
    });

    this.localStorageService.set(Auth.ACCESS_TOKEN_KEY, data.access_token);
    this.localStorageService.set(Auth.REFRESH_TOKEN_KEY, data.refresh_token);
    return data;
  };

  public refreshAccessToken = async (): Promise<ILoginResponse> => {
    const { data } = await this.api.post<ILoginResponse>(
      Endpoints.refreshToken,
      { Token: this.localStorageService.get(Auth.REFRESH_TOKEN_KEY) }
    );
    this.localStorageService.set(Auth.ACCESS_TOKEN_KEY, data.access_token);
    this.localStorageService.set(Auth.REFRESH_TOKEN_KEY, data.refresh_token);

    return data;
  };

  public loginUser = async (email: string, password: string): Promise<any> => {
    const token = Buffer.from(`${email}:${password}`, 'utf8').toString('base64');
    const { data } = await this.api.post<any>(Endpoints.authLogIn, {}, {
      headers: { Authorization: `Basic ${token}` }
    });
    this.localStorageService.set(Auth.ACCESS_TOKEN_KEY, data.access_token);

    return data;
  };
}
