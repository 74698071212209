export const rankingTableColumns =
[{
  title: 'Series Title - Title',
  dataIndex: 'Series',
  key: 'Series',
  className: 'show',
  fixed: 'left',
  width: 180,
  render: (series, _book) => {
    if (series && _book.Title) {
      return (<span>{series} - {_book.Title}</span>);
    } else {
      return (<span>{_book.Title}</span>);
    }
  }
}, {
  title: 'ASIN',
  dataIndex: 'ASIN',
  key: 'ASIN',
  className: 'show',
  fixed: 'left',
  width: 100,
  render: (asin: string) => {
    if (asin) {
      return {
        children: <div><a
          className='table-cell-asin'
          href={`https://www.amazon.com/dp/${asin}`}
          rel='noreferrer'
          target='_blank'
        >
          {asin}
        </a></div>
      };
    }
  }
},
{
  title: 'Genre Category',
  dataIndex: 'Genre Category',
  key: 'Genre Category',
  className: 'show',
  width: 90,
  fixed: 'left',
  render: text => <span>{text ?? ''}</span>
}
];