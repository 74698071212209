import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import { notification, Popover } from 'antd';
import { IBook } from '../../section/Common/types/types';
import { SEND_TO_CRM,
  WATCHLIST,
  AUTHORS,
  PODIUMAUTHORS,
  AUTHOR_TAGS,
  SOURCE,
  SENT_TO_CRM_RR } from '../../core/constants';
import { useUpdateTitle } from '../../core/modules/book/hooks/useUpdateTitle.query';
import { useActions } from '../../context/Actions.context';
import showNotification from '../../section/Common/utils/showNotification';

interface IProps {
  book: IBook;
  refetchTable: Function;
  id: string;
  label?: string;
}

const watchlistObject = (source, id, book) => {
  if (source === SOURCE.bookstat) {
    return { obj: { amazonasin: book.ASIN }, obj_type: 'Title' };
  } else if (source === SOURCE['royal-road']) {
    return (id === AUTHORS || id === PODIUMAUTHORS) ?
      { obj: { author_id: book['Author Id'] }, obj_type: 'Author' } :
      { obj: { fiction_id: book['Fiction Id'] }, obj_type: 'Title' };
  }
};

const Watch: React.FC<IProps> = ({ book, id, refetchTable, label = 'Title' }) => {
  const { source, setActionLoading } = useActions();
  const watchlist = watchlistObject(source['source'], id, book);

  const { mutateAsync: mutateWatchlist } = useUpdateTitle(WATCHLIST);
  const { mutateAsync: mutateAuthorWatchlist } = useUpdateTitle(AUTHOR_TAGS);

  const onSend = (e) => {
    setActionLoading(true);
    e.stopPropagation();
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'watch' });
    const promisePT = (id === AUTHORS || id === PODIUMAUTHORS) ?
      mutateAuthorWatchlist({ ...watchlist?.obj, tag: 'watchlist' }) :
      mutateWatchlist({ ...watchlist?.obj });

    showNotification(
      promisePT,
      `The ${watchlist?.obj_type} was successfully added to watchlist.`,
      `The ${watchlist?.obj_type} has not been sent to watchlist.`
    );

    promisePT.then(() => {
      if (id === SEND_TO_CRM || id === SENT_TO_CRM_RR) {
        notification.info({
          description: 'Please delete the data from Hubspot.',
          message: ''
        });
      }
      refetchTable();
    });
  };

  const content = <div className='popover-content'>{`Send ${label} to Watchlist`}</div>;

  return (
    <Popover content={content} trigger='hover'>
      <EyeOutlined
        onClick={onSend}
        className='actions-icons actions-icons-eye'
      />
    </Popover>
  );
};

export default React.memo(Watch);