import toggleColor from '../utils/toggleColor';

const onRowClick = (record, deleteItems) => {
  const { ASIN } = record;
  const selectedItems = [...deleteItems];

  const index = selectedItems.indexOf(ASIN);

  if (index > -1) {
    selectedItems.splice(index, 1);
  } else {
    selectedItems.push(ASIN);
  }

  toggleColor(ASIN);

  return selectedItems;
};

export default onRowClick;
