import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';

const StyledPopover = ({ className, ...props }) => (
  <Popover {...props} overlayClassName={className} />
);

const CustomPopover = styled(StyledPopover)`
  .ant-popover-inner-content {
    min-width: 200px;
  }
  .ant-popover-inner {
    border-radius: 8px !important;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        border-radius: 8px !important;
        padding: 6px;
        span {
          margin-right: 8px;
        }
        &:hover {
          background: #f6f6f6;
          cursor: pointer;
        }
      }
    }
  }
`;

export default CustomPopover;
