import React from 'react';
import { FilterProvider } from '../../context/Filter.context';
import { SortProvider } from '../../context/Sort.context';
import { ActionsProvider } from '../../context/Actions.context';
import DailyrankSection from './Dailyrank.section';
import getFilter from '../Common/utils/getFilter';
import { DailyRankEndpoints } from '../../core/constants';
import '../Common/styles.css';
import { ISource } from '../Common/types/types';

const getTabId = (endpoint: string): string => {
  const tabId = Object.keys(DailyRankEndpoints).find(
    (key) => DailyRankEndpoints[key] === `/${endpoint}`
  ) as string;

  return tabId;
};

const DailyrankSectionWrapper: React.FC<ISource> = React.memo((source) => {
  const search = getFilter(getTabId);

  return (
    <FilterProvider filter={search.filter}>
      <SortProvider sort={search.sort}>
        <ActionsProvider source={source}>
          <DailyrankSection />
        </ActionsProvider>
      </SortProvider>
    </FilterProvider>
  );
});

export default DailyrankSectionWrapper;