import styled from 'styled-components';

const Dot = styled.span`
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid white;
    background-color: ${({ color }) => color};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 13px;
    margin: 10px;
    flex-wrap: wrap;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
`;

const maps = {
  title: {
    text: 'Podium Title',
    color: 'rgb(196, 170, 234, 0.6)'
  },
  podium: {
    text: 'Podium Author',
    color: 'rgba(24, 144, 255, 0.40)'
  }
};

enum EMap {
  'title',
  'podium'
}

type TProps = {
  map?: (keyof typeof EMap)[]
};

const Legend = ({ map }: TProps) => (
  <Wrapper>
    {Boolean(map?.length) && map?.map(el => (<Item key={el}>
      <Dot color={maps[el].color}/>
      {maps[el].text}
    </Item>))}
  </Wrapper>
);

export default Legend;