import { useMemo } from 'react';
import Table from './Table';
import {
  NameGenreWrapper,
  SubmitBtn,
  ContentBlock,
  WarningInfo
} from './styles';
import Input from '../../../components/Input';
import { FormikProvider, useField } from 'formik';

const TextInput = ({ name, disabled }) => {
  const [field] = useField({ name });

  return <Input {...field}
    disabled={disabled}
    placeholder='Genre name'
    style={{ height: 32, marginRight: 16 }}
  />;
};


const Content = ({ data, isLoading, formik, isFetching, handleCreate }) => {
  const MTable = useMemo(() =>
    <Table
      formik={formik}
      data={data}
      isLoading={isLoading || isFetching}
    />,
  [data, isLoading, formik, isFetching]);

  const MGenreInput = useMemo(() =>
    <NameGenreWrapper>
      <TextInput
        disabled={!(formik.values.amazonasin?.length)}
        name={'genres'}
      />
      <SubmitBtn type='button' onClick={handleCreate}
        disabled={!(formik.values.amazonasin?.length && formik?.values?.genres?.trim())}
      >Create</SubmitBtn>
    </NameGenreWrapper>,
  [formik]);

  return (<ContentBlock>
    <FormikProvider value={formik}>
      {MGenreInput}
      {data?.count > 1000 &&
        <WarningInfo title={`${data?.count} titles were found. Only the first 1000 are available for previews.
        All others will automatically be assigned to the genre.`}>{data?.count}{' '}
          titles were found. Only the first 1000 are available {' '}
          for previews. All others will automatically be assigned to the genre.
        </WarningInfo>}
      {MTable}
    </FormikProvider>
  </ContentBlock>
  );
};

export default Content;