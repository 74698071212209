import React, { useMemo } from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { notification, Popover } from 'antd';
import { IBook } from '../../section/Common/types/types';
import { useUpdateTitle } from '../../core/modules/book/hooks/useUpdateTitle.query';
import {
  ALL_EXCLUDED, ALL_EXCLUDED_RR, PODIUM_TITLES, PODIUM_TITLES_RR,
  SEND_TO_CRM, SENT_TO_CRM_RR, SOURCE
} from '../../core/constants';
import { useActions } from '../../context/Actions.context';
import showNotification from '../../section/Common/utils/showNotification';

interface IProps {
  book: IBook;
  refetchTable: Function;
  id: string;
}

const getPodiumData = (source: string, book: IBook) => {
  const podiumData = {
    [SOURCE.bookstat]: {
      podiumTitleObject: { amazonasin: book.ASIN },
      podiumTitleType: PODIUM_TITLES,
      excludeType: ALL_EXCLUDED
    },
    [SOURCE['royal-road']]: {
      podiumTitleObject: { fiction_id: book['Fiction Id'] },
      podiumTitleType: PODIUM_TITLES_RR,
      excludeType: ALL_EXCLUDED_RR
    }
  };

  return podiumData[source];
};

const ToPodiumTitle: React.FC<IProps> = ({ book, id, refetchTable }) => {
  const { source: sourcePath, setActionLoading } = useActions();
  const podiumData = useMemo(() => getPodiumData(sourcePath['source'], book), [sourcePath, book]);
  const { mutateAsync } = useUpdateTitle(podiumData.podiumTitleType);
  const { mutateAsync: mutate } = useUpdateTitle(podiumData.excludeType);

  const onSend = (e) => {
    setActionLoading(true);
    e.stopPropagation();
    window.gtag('event', 'click', { event_category: 'Interaction', event_label: 'Send To Podium Titles' });
    const promise = mutateAsync(podiumData.podiumTitleObject);

    showNotification(
      promise,
      'The Title was successfully sent to the Podium Titles tab.',
      'The Title has not been sent.',
      book.Title
    );

    promise.then(() => {
      if (id === SEND_TO_CRM || id === SENT_TO_CRM_RR) {
        notification.info({
          description: 'Please delete the data from Hubspot.',
          message: ''
        });
      }
    });

    if (id === ALL_EXCLUDED || id === ALL_EXCLUDED_RR) {
      refetchTable();

      return;
    }

    mutate(podiumData.podiumTitleObject)
      .then(res => {
        if (res?.status === 200) {
          notification.success({
            description: `Success: Sent ${book.Title}`,
            message: 'The Title was successfully sent to the All Excluded tab.'
          });
        }
        refetchTable();
      })
      .catch(() => {
        notification.error({
          description: `Error while sending ${book.Title}`,
          message: 'The Title has not been sent to the Podium Titles tab.'
        });
        setActionLoading(false);
      });
  };

  const content = (
    <div className='popover-content'>Send Title to Podium Titles</div>
  );

  return (
    <>
      <Popover placement='topLeft' content={content} trigger='hover'>
        <SolutionOutlined
          onClick={onSend}
          className='actions-icons actions-icons-podium-title'
        />
      </Popover>
    </>
  );
};

export default React.memo(ToPodiumTitle);