import React from 'react';
import '../Common/styles.css';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useUserPermit } from '../../context/User.context';
import { Routes, TAB_NAME } from '../../core/constants';
import Tabs from '../../components/Tabs';
import {
  AllExcluded,
  AllNewTitle,
  Watchlist,
  SendToCRM,
  Authors,
  PodiumAuthors,
  Trending,
  RisingStars,
  Popular,
  PendingExclusion,
  PodiumTitles
} from '../Common/tabs';
import SavedSearchSection from '../SavedSearches/SavedSearch.section';
import { defaultFilter, useFilter } from '../../context/Filter.context';
import { defaultSort, useSort } from '../../context/Sort.context';

const tabs = [
  {
    key: Routes.allTitlesRoyalRoad,
    label: TAB_NAME.alltitlesRR
  },
  {
    key: Routes.watchlistRoyalRoad,
    label: TAB_NAME.watchlistRR
  },
  {
    key: Routes.popular,
    label: TAB_NAME.popular
  },
  {
    key: Routes.risingStars,
    label: TAB_NAME.risingStars
  },
  {
    key: Routes.trending,
    label: TAB_NAME.trending
  },
  {
    key: Routes.authors,
    label: TAB_NAME.authors
  },
  {
    key: Routes.podiumAuthors,
    label: TAB_NAME.podiumAuthors
  },
  {
    key: Routes.pendingExclusionRR,
    label: TAB_NAME.pendingExclusionRR
  },
  {
    key: Routes.excludedRoyalRoad,
    label: TAB_NAME.excludedRR
  },
  {
    key: Routes.podiumTitlesRoyalRoad,
    label: TAB_NAME.podiumtitlesRR
  },
  {
    key: Routes.sentToCrmRoyalRoad,
    label: TAB_NAME.sendtocrm
  },
  {
    key: Routes.savedSearchesRoyalRoad,
    label: TAB_NAME.savedSearches
  }
];

const RoyalRoadSection: React.FC = (): JSX.Element => {
  const { view_tables } = useUserPermit();
  const { setFilter } = useFilter();
  const { setSort } = useSort();
  const history = useHistory();

  return view_tables ? (
    <>
      <Tabs
        defaultActiveKey={'/all-new-titles'}
        onChange={key => {
          history.push(key);

          if (!window.location.search) {
            setSort(defaultSort);
            setFilter(defaultFilter);
          }
        }}
        tabs={tabs}
        activeKey={window.location?.pathname}
      />
      <Switch>
        <Route path={Routes.allTitlesRoyalRoad} render={() => <AllNewTitle />} />
        <Route path={Routes.watchlistRoyalRoad} render={() => <Watchlist />} />
        <Route path={Routes.pendingExclusionRR} render={() => <PendingExclusion />} />
        <Route path={Routes.excludedRoyalRoad} render={() => <AllExcluded />} />
        <Route path={Routes.podiumTitlesRoyalRoad} render={() => <PodiumTitles />} />
        <Route path={Routes.sentToCrmRoyalRoad} render={() => <SendToCRM />} />
        <Route path={Routes.popular} render={() => <Popular />} />
        <Route path={Routes.trending} render={() => <Trending />} />
        <Route path={Routes.authors} render={() => <Authors />} />
        <Route path={Routes.podiumAuthors} render={() => <PodiumAuthors />} />
        <Route path={Routes.risingStars} render={() => <RisingStars />} />
        <Route path={Routes.savedSearchesRoyalRoad} render={() => <SavedSearchSection />} />
        <Redirect to='/royal-road/all-new-titles' />
      </Switch>
    </>
  ) : (
    <></>
  );
};

export default RoyalRoadSection;