import { Field, useFormik, FormikProvider } from 'formik';
import { Select, Option } from '../../../components/Select';
import { required } from '../../../utils/validation';
import FormItem from '../../../components/FormItem';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { useEditCustomObject } from '../../../core/modules/book/hooks/useEditCustomObject';
import ParentGenre from '../Genre/ParentGenre';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 12px;
`;

const EditParentGenre = ({ onClose, data, isLoading }) => {
  const editParentGenre = useEditCustomObject('parent');

  const formik = useFormik({
    initialValues: {
      id: null,
      parent: null
    },
    enableReinitialize: true,
    onSubmit: async () => null
  });

  const handleEdit = (value) => {
    editParentGenre.mutate({ data: [value] });
    onClose();
  };

  return (
    <FormikProvider value={formik}>
      <div style={{ display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'calc(100% - 80px)' }}>
        <div>
          <FormItem label='Select Genre' required error={undefined}>
            <Field name={'id'} validate={required}>
              {({ field: { value, ...rest }, form: { setFieldValue } }) => (
                <Select
                  placeholder='Select...'
                  value={value === '' || value === null ? undefined : value}
                  {...rest}
                  onChange={(v) => {
                    setFieldValue('id', v);
                    setFieldValue('parent', data?.data?.data?.find(d => d.ID === v).Parent);
                  }}
                  loading={isLoading}
                  style={{ width: '100%' }}
                >
                  {!isLoading ? data?.data?.data?.map(({ ID, Name }) =>
                    <Option key={ID} value={ID}>
                      {Name}
                    </Option>) : (
                    <Option key='loading' value='loading' disabled={true}>
                        Loading...
                    </Option>
                  )}
                </Select>
              )}
            </Field>
          </FormItem>

          <FormikProvider value={formik}>
            <ParentGenre />
          </FormikProvider>
        </div>

        <Footer>
          <Button
            onClick={onClose}
          >
          Cancel
          </Button>

          <Button
            onClick={() => handleEdit(formik.values)}
            disabled={Boolean(Object.values(formik.errors)?.length) || !formik.values.parent}
            type='primary'
          >
          Save
          </Button>
        </Footer>
      </div>
    </FormikProvider>
  );
};

export default EditParentGenre;