import getPosition from '../../section/Common/utils/getPosition';

const onContextMenu = (
  record,
  e,
  { visibleContextMenu, setPopup, setVisibleContextMenu }
) => {
  e.preventDefault();

  if (!visibleContextMenu) {
    document.addEventListener('click', function onClickOutside() {
      setPopup({ record: {}, visible: false, x: 0, y: 0 });
      document.removeEventListener('click', onClickOutside);
    });
  }

  const { x, y } = getPosition(e);

  const popupSettings = {
    record,
    visible: true,
    x,
    y
  };

  setPopup({ ...popupSettings });
  setVisibleContextMenu(false);
};

export default onContextMenu;
