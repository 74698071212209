import React, { useContext } from 'react';
import { ILocalStorageService } from '../../core/services/local-storage.service';

export const LocalStorageContext = React.createContext<ILocalStorageService>(
  {} as ILocalStorageService
);

export const useLocalStorage = (): ILocalStorageService => useContext<ILocalStorageService>(
  LocalStorageContext
);

export default LocalStorageContext;
