import React from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Checkbox } from '../components/Checkbox';
import Modal from '../components/Modal';
import { notification, Modal as Message, Space } from 'antd';
import Input from '../components/Input';
import FormItem from '../components/FormItem';
import { useManuallyAddBook } from '../core/modules/book/hooks/useManuallyAddBook';

const onlyNumbersAndLetters = (value: string): boolean | undefined => {
  if (value)
  { return Boolean(value.replace(/[a-zA-Z0-9]/g, '')); }
};

const info = (): JSX.Element => (
  <Space wrap>{
    Message.info({
      title: 'Addition in progress...',
      content: (
        <div>
          <p>Title is being added to the site, we will notify you once done,
           meanwhile you can continue to use the website.
          </p>
        </div>
      )
    })}
  </Space>);

const AddTitle = ({ onClose, open }): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      amazonasin: '',
      predictions: false
    },
    onSubmit: async () => null,
    enableReinitialize: true
  });

  const viewTitleButton = (tab: string): JSX.Element => (
    <a href={`/bookstat/${tab}`}>View Title</a>
  );

  const validateInput = (asin: string): string | undefined => {
    if (onlyNumbersAndLetters(asin.trim())) {
      return 'ASIN can only contain numbers and letters.';
    } else if (!(asin.trim().length === 10)) {
      return 'ASIN is more than 10 characters long.';
    }
  };

  const manuallyAddTitle = useManuallyAddBook({
    onSuccess: (data) => {
      onClose();

      switch (data?.status) {
      case 400:
      case 500:
        return notification.error({
          description: data?.data?.msg,
          message: ''
        });

      case 200: return notification.success({
        description: viewTitleButton(data?.data?.tab),
        message: data?.data?.msg,
        duration: 0
      });

      default: notification.info({
        description: data?.data?.msg,
        message: ''
      });
      }
    }
  });

  const onSend = async () => {
    manuallyAddTitle.mutate(formik.values);
    onClose();
    info();
  };

  return (
    <Modal
      okText='Add'
      title='Manually add Title'
      cancelText='Cancel'
      visible={open}
      onOk={onSend}
      okButtonProps={{ disabled: !formik.values.amazonasin?.length || !formik.isValid }}
      confirmLoading={manuallyAddTitle?.isLoading}
      onCancel={onClose}
    >
      <FormikProvider value={formik}>
        <FormItem label='Enter Amazon ID' required error={formik.errors?.amazonasin}
          hint='Amazon ID must be 10 characters, must contain only numbers and alphabets'>
          <Field name={'amazonasin'} validate={(asin: string) => validateInput(asin.trim())}>
            {({ field: { value, onChange, props }, form: { setFieldValue, validate } }) => (
              <Input
                value={value}
                placeholder='Amazon ID here...'
                onChange={event => {
                  onChange(event);
                  setFieldValue('amazonasin', event.target.value.trim());
                }}
                onBlur={validate}
                {...props}
              />
            )}
          </Field>
        </FormItem>

        <Field name={'predictions'}>
          {({
            field: { value, ...restProps }
          }) => (
            <Checkbox
              name={'predictions'}
              checked={value}
              defaultChecked
              {...restProps}
            >
            Include projected sales data
            </Checkbox>
          )}
        </Field>
      </FormikProvider>
    </Modal>
  );
};

export default AddTitle;
